import {
    CALL_API
  } from '../middlewares/apiMiddleware'

export const FETCH_ORGANIZATION_DATA = 'FETCH_ORGANIZATION_DATA';
export const FETCH_ORGANIZATION_DATA_SUCCESS = 'FETCH_ORGANIZATION_DATA_SUCCESS';
export const FETCH_ORGANIZATION_DATA_FAILURE = 'FETCH_ORGANIZATION_DATA_FAILURE';

export const fetchOrganizationAnalytics = (params) => {
    return {
      [CALL_API]: {
        types: [FETCH_ORGANIZATION_DATA, FETCH_ORGANIZATION_DATA_SUCCESS, FETCH_ORGANIZATION_DATA_FAILURE],
        endpoint: `/api/auth/admin/organization-analytics?${params.userId ?`userId=${params.userId}`: params.organization_id ?`organization_id=${params.organization_id}`:``} ${params.from ?`&from=${params.from}`:``}${params.to ?`&to=${params.to}`:``}`,
        options: {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
                'Accept': 'application/json'
          }
        }
      }
    }
  }
  