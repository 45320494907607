import React, { useState, useEffect } from 'react'
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Label, Col, Row, Spinner, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { addAdmin } from '../../actions/addUser'
import { fetchOrganizations } from '../../actions/addOrganization';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import AutoSuggest from '../../components/AutoSuggest';
import { getOrganizationUsers } from '../../actions/addOrganization'
import EmailAuto from './EmailAuto'
import usePrevious from '../../customHooks/usePrevious'
import Loader from '../../components/Loader'
// import './style.css'
import { manualApiCall } from '../../utils'
// import OrganizationsAuto from './OrganizationsAuto';

const AddUser = (props) => {
    const {
        userId,
        startDate,
        endDate,
        sortby,
        isAdding,
        adding,
        addUserFormPopUp,
        dispatch,
        is_org_admin,
        organization_id,
        organization_name,
        setAddUserFormPopUp,
        users
    } = props
    const isAddingPrev = usePrevious(isAdding)
    const [toggle, setToggle] = useState(addUserFormPopUp)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isAdmin, setAdmin] = useState(false)
    const [passwordMatch, setPasswordMatch] = useState(false)
    const [onsubmit, setOnSubmit] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [key, setKey] = useState(0)
    const [selectedId, setSelectedId] = useState(null)
    toast.configure()
   
    useEffect(() => {
        // console.log('TOGGLING TOGGLING YEYEYE')
        if (!toggle) {
            resetForm()
        }
    }, [toggle])

    useEffect(() => {
        if (isAddingPrev && !isAdding) {

            setToggle(!toggle)
            setAddUserFormPopUp(!addUserFormPopUp)
            toast.info('User has been added successfully!', { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 5000 })
            dispatch(getOrganizationUsers(startDate === '' ? null : startDate, endDate === '' ? null : endDate, organization_id, sortby,userId));
            resetForm()
        }

    }, [isAdding])


    const resetForm = () => {
        setPassword('')
        setConfirmPassword('')
        setPasswordMatch(false)
        setEmail('')
        setAdmin(false)
    }

    const handleValidSubmit = (event, values) => {
        const p = values.password

        const admin = values.isAdmin

        setKey(key + 1)
        setPassword(p)
        setConfirmPassword(values.confirmPassword)
        setAdmin(admin)

        if (values.password === values.confirmPassword) {
            setPasswordMatch(true)
            setOnSubmit(true)
            dispatch(addAdmin(p, isAdmin, selectedId, organization_id))

        }
        else {
            setErrorMessage('Passwords didn\'t match');
            setPasswordMatch(false)
        }

    }
    const handleInvalidSubmit = (event, values) => {

        setPassword(values.password)
        setConfirmPassword(values.confirmPassword)
    }
    const emailChange = e => {
        setEmail(e);
        setErrorMessage('')
    }
    const passwordChange = e => {
        setPassword(e.target.value);
        setErrorMessage('')
    }
    const adminChange = e => {
        setAdmin(!isAdmin);
        setErrorMessage('')
    }
    const confirmPasswordChange = e => {
        setConfirmPassword(e.target.value);
        setErrorMessage('')
    }

    return (
        <>
            <div style={{ marginTop: '20px' }}>
                <h2> {organization_name}</h2>
            </div>
            <Button
                className="add-user-button"
                outline
                color="primary"
                onClick={() => {
                    setToggle(!toggle)
                    setAddUserFormPopUp(!addUserFormPopUp)
                }}>
                Add User
      </Button>
            <Modal
                placement='bottom'
                isOpen={toggle}
                toggle={() => {
                    // console.log('TOGGELASJDFLKJASDLFKJASLDKFJ')
                    // setToggle(!toggle)
                    // setAddUserFormPopUp(!addUserFormPopUp)
                    // if(toggle){
                    //     resetForm()
                    // }
                }}
                className='moreDetailContainer'>
                <ModalHeader
                    className='moreDetailHead'
                    toggle={() => {
                        setToggle(!toggle)
                        setAddUserFormPopUp(!addUserFormPopUp)
                    }}>
                    <p className='moreDetailHeading'>Add User</p>
                </ModalHeader>
                <ModalBody className='moreDetailBody'>
                    <AvForm onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit} key={String(key)}>
                        <Row>
                            <Col xs="12" sm="12">
                                <EmailAuto
                                    EmailTextBox={email}
                                    setEmailTextBox={emailChange}
                                    getUserActivities={(args) => { setSelectedId(args) }}
                                />
                            </Col>
                        </Row>


                        <br />
                        <AvGroup check>
                            <Label check>
                                <AvInput
                                    type="checkbox"
                                    name="isAdmin"
                                    value={isAdmin}
                                    onChange={adminChange} /> Is An Admin?
                            </Label>
                        </AvGroup>
                        <br />
                        {isAdmin &&
                            <>
                                <AvGroup >
                                    <Label for="password">Password</Label>
                                    <AvField
                                        name="password"
                                        placeholder="Enter Your Password"
                                        value={password}
                                        type="password"
                                        onChange={passwordChange}
                                        required validate={{
                                            required: { value: true, errorMessage: 'Please enter your password' }
                                        }} />
                                </AvGroup>
                                <br />
                                <AvGroup >
                                    <Label for="confirmPassword"> Confirm Password</Label>
                                    <AvField
                                        name="confirmPassword"
                                        placeholder="Please Confirm Your Password"
                                        value={confirmPassword}
                                        type="password"
                                        onChange={confirmPasswordChange}
                                        required validate={{
                                            required: { value: true, errorMessage: 'Please enter your password' }
                                        }} />
                                </AvGroup>
                            </>
                        }
                        <br />
                        {

                            // message === 'Validation error'
                            //     ?
                            //     <p className="sales-form-error">{errorMessage}</p>
                            //     :
                            passwordMatch && onsubmit ? <span /> : email && <p className="sales-form-error">{errorMessage}</p>
                        }
                        {email !== '' &&
                            <Button
                                color="primary"
                                style={{ width: '75px' }}

                            >
                                {isAdding && adding.includes(selectedId) ? <Loader /> : 'Add'}
                            </Button>
                        }
                    </AvForm>
                </ModalBody>
            </Modal>
        </>
    )
}

export default connect(state => ({
    organizations: state.organizations.organizations,
    message: state.addAdmin.message,
    isLoading: state.addAdmin.isLoading,
    users: state.users.users,
    isAdding: state.orgUsers.isAdding,
    adding: state.orgUsers.adding
}))(AddUser)