import * as ActionTypes from '../actions/addOrganization'

const initialState = {
   isLoading: false,
    isDataAvailable: false,
    message:'',
    organizations: [],
    isLoadingOrganizations: false,
 
    
}

export default function addOrganization(state = initialState, action) {

    switch (action.type) {
        case  ActionTypes.ADD_ORGANIZATION:
            return {
                ...state,
                isLoading:true,
                isDataAvailable: false,
                message:''
              
                
            }
        case  ActionTypes.ADD_ORGANIZATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            }
        case   ActionTypes.ADD_ORGANIZATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message,
                isDataAvailable: false,
                
            }

        case ActionTypes.FETCH_ORGANIZATIONS:
            return {...state, message:'',isLoadingOrganizations: true}

        case ActionTypes.FETCH_ORGANIZATIONS_SUCCESS:
            return {...state,  message:'',isLoadingOrganizations: false, organizations: action.payload.data}

        case ActionTypes.FETCH_ORGANIZATIONS_FAILURE:
            return {...state, isLoadingOrganizations: false, organizations: []}


        default:
            return state;
    }
}


