import React, { useState,useEffect } from "react";
import { CSVLink } from 'react-csv'
import qs from 'query-string'
import {
  Button,
  Card,
  CardBody,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Tooltip
} from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { manualApiCall, simulateTimeout, transformCsvData } from '../../utils'

import { connect } from 'react-redux'

import CsvExport from './csvExport'

import AdvanceFilterTags from './AdvanceFilterTags'

import {
  clearAdvancedFilters,
  clearScoringFilter,
  setDownloaded,
  archiveProspects,
  unarchiveProspects,
  selectAllProspects,
  selectTopProspects,
  unselectAllProspects,
  fetchAllProspects,
  setSkillsFilters,
  toggleStrictSkills,
  setStrictSkills,
  setSchoolFilters,
  setCurrentTitleFilters,
  toggleStrictCurrentTitle,
  setPastTitleFilters,
  toggleStrictPastTitle,
  setCurrentCompanyFilters,
  setPastCompanyFilters,
  setCurrentIndustryFilters,
  setPastIndustryFilters,
  setLocationFilters,
  setVisaFilters,
  setTotalExperienceFilter,
  setRelevantExperienceFilter,
  toggleTotalExperienceFilter,
  toggleRelevantExperienceFilter,
  setCompanySizeFilter,
  toggleCompanySizeFilter,
  setTitleScoringFilter,
  toggleTitleScoringFilter,
  setSkillScoringFilter,
  toggleSkillScoringFilter,
  setExperienceScoringFilter,
  toggleExperienceScoringFilter,
  setEducationScoringFilter,
  toggleEducationScoringFilter,
  setIndustryScoringFilter,
  toggleIndustryScoringFilter

} from '../../actions/score'


import VectorIcon from '../../../../img/vector.svg'
import BlueVectorIcon from '../../../../img/BlueVector-down.svg'
import BluePlusIcon from '../../../../img/blue-plus-small.svg'
import BlueMinusIcon from '../../../../img/minus-small.svg'
import TrashIcon from '../../../../img/archiveIcon.svg'
import MinusIcon from '../../../../img/not-all.svg'

import UnsaveBatchProspects from '../../../../img/UnsaveBatchProspects.svg'
import RequestMeetingSequence from '../../../../img/RequestMeetingSequence.svg'
import DownloadCSV from '../../../../img/DownloadCSV.svg'
import ComposeMessageSequence from '../../../../img/ComposeMessageSequence.svg'
import ArchiveProspectBlue from '../../../../img/ArchiveProspectBlue.svg'

import './style.css'
// import Loader from "react-loader-spinner";
import Loader from '../../components/Loader'

const sortItems = [{
  name: 'Title',
  value: 'title_score',
  id: 1
}, {
  name: 'Skills',
  value: 'skill_score',
  id: 2
}, {
  name: 'Experience',
  value: 'experience_score',
  id: 3
}, {
  name: 'Education',
  value: 'education_score',
  id: 4
}, {
  name: 'Industry',
  value: 'industry_score',
  id: 5
}, {
  name: 'Gender (Male)',
  value: 'male',
  id: 6
}, {
  name: 'Gender (Female)',
  value: 'female',
  id: 7
}, {
  name: 'Age (Ascending)',
  value: 'age',
  sort: 'asc',
  id: 8
}, {
  name: 'Age (Descending)',
  value: 'age',
  sort: 'desc',
  id: 9
}, {
  name: 'Ethnicity (Ascending)',
      value: 'ethnicity',
  sort: 'asc',
  id: 10
}, {
  name: 'Ethnicity (Descending)',
      value: 'ethnicity',
  sort: 'desc',
  id: 11
}]

// const actionItems = [{
//   name: 'No Action',
//   value: '',
//   id: 1
// }, {
//   name: 'Messaged',
//   value: 'sent',
//   id: 2
// }, {
//   name: 'Archived',
//   value: 'archived',
//   id: 3
// }]

const connectionItems = [{
  name: '1st Degree',
  label: 'Connections',
  value: '1',
  id: 1
}, {
  name: '2nd & 3rd Degree',
  label: 'Is 2nd or 3rd connection',
  value: '2',
  id: 2
},{
  name: 'All Connections',
  label: 'All Connections',
  value: "all",
  id: 3
}]




let BlueIcon = BluePlusIcon

const FilterSection = (props) => {
  const [Drop1, setDrop1] = useState(false)
  const [Drop2, setDrop2] = useState(false)
  const [Drop3, setDrop3] = useState(false)
  const [Drop4, setDrop4] = useState(false)
  const [Icon, setIcon] = useState(true)
  const [SelectionPop, setSelectionPop] = useState(false)
  const [csvDownloadData, setCsvDownloadData] = useState([])

  const [fileName, setFileName] = useState('Job Title')
  const [tooltipOpenC, setTooltipOpenC] = useState(false)
  const [tooltipOpenR, setTooltipOpenR] = useState(false)
  const [archiveToolTipOpen, setArchiveToolTipOpen] = useState(false)
  const [ComposeMsgToolTipOpen, setComposeMsgToolTipOpen] = useState(false)
  const [RequestMeetingToolTipOpen, setRequestMeetingToolTipOpen] = useState(false)
  const [UnsaveToolTipOpen, setUnsaveToolTipOpen] = useState(false)
  const [DownloadCSVToolTipOpen, setDownloadCSVToolTipOpen] = useState(false)



  const {
    Show,
    setShow,
    ScoringFilterShow, 
    setScoringFilterShow,
    setView,
    selected,
    dispatch,
    totalProspects,
    csvData,
    jobTitle,
    jobId,
    SelectAllProspectsFlag,
    setSelectAllProspectsFlag,
    allProspects,
    totalRecord,

    FilterCount,
    setFilterCount,
    ScoringFilterCount,
    setScoringFilterCount,

    newJob,
    activeTab,
    isDownloading,

    setSchoolsFilterList,
    schoolsFilterList,
    setVisaStatus,
    VisaStatus,
    setCompanyFilterList,
    companyFilterList,
    setSkillsFilterList,
    skillsFilterList,
    setIndustryFilterList,
    industryFilterList,
    setTitleFilterList,
    titleFilterList,
    setVisa,
    Visa,
    isLimitExceeded,
    AdvFilters,
    ScoringFilters,

    ...rest
  } = props


  console.log('FILTER SECTION REST PROPS.....: ', rest)

  const [topProspects, setTopProspects] = useState(0)

  const qsParams = qs.parse(rest.location.search)




  const handleDownloadCSV = async () => {
    let r = []

    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "DOWNLOAD_CSV" })
    //   })

    // const data = SelectAllProspectsFlag ? allProspectsData : csvDownloadData

    if (SelectAllProspectsFlag) {

      const params = Object.keys(qsParams).reduce((obj, item) => {
        return { ...obj, [item]: encodeURIComponent(qsParams[item]) }
      }, {})


      const data = await manualApiCall(`/api/auth/job/profile/list/advancedFilter?jId=${params.jId}${
        params.fS ? `&fS=${params.fS}&fSO=${params.fSO}` : ''}${
        params.secS ? `&secS=${params.secS}&secSO=${params.secSO}` : ''}${
        params.tF ? `&tF=${params.tF}` : ''}${params.cTF ? `&cTF=${params.cTF}` : ''}${
        params.sS ? `&sS=${params.sS}` : ""}${
        params.eS ? `&eS=${params.eS}` : ""}${params.hS ? `&hS=${params.hS}` : ""}${
        params.eSS ? `&eSS=${params.eSS}` : ""}${params.hSS ? `&hSS=${params.hSS}` : ""}${
        params.eCT ? `&eCT=${params.eCT}` : ""}${params.hCT ? `&hCT=${params.hCT}` : ""}${
        params.ePT ? `&ePT=${params.ePT}` : ""}${params.hPT ? `&hPT=${params.hPT}` : ""}${
        params.cTS ? `&cTS=${params.cTS}` : ""}${params.pTS ? `&pTS=${params.pTS}` : ""}${
        params.eCC ? `&eCC=${params.eCC}` : ""}${params.hCC ? `&hCC=${params.hCC}` : ""}${
        params.ePC ? `&ePC=${params.ePC}` : ""}${params.hPC ? `&hPC=${params.hPC}` : ""}${
        params.cSR ? `&cSR=${params.cSR}` : ""}${params.eCI ? `&eCI=${params.eCI}` : ""}${
        params.hCI ? `&hCI=${params.hCI}` : ""}${params.ePI ? `&ePI=${params.ePI}` : ""}${
        params.hPI ? `&hPI=${params.hPI}` : ""}${params.eL ? `&eL=${params.eL}` : ""}${
        params.hL ? `&hL=${params.hL}` : ""}${params.eV ? `&eV=${params.eV}` : ""}${
        params.hV ? `&hV=${params.hV}` : ""}${params.tSF ? `&tSF=${params.tSF}` : ""}${
        params.sSF ? `&sSF=${params.sSF}` : ""}${params.expSF ? `&expSF=${params.expSF}` : ""}${
        params.edSF ? `&edSF=${params.edSF}` : ""}${params.iSF ? `&iSF=${params.iSF}` : ""}${
        params.tE ? `&tE=${params.tE}` : ""}${params.rE ? `&rE=${params.rE}` : ""}&pFlag=false`)

      
      dispatch(setDownloaded(jobId, data.prospectsArray.map(itm => itm.id), newJob, activeTab))
      r = transformCsvData(data.prospectsArray)
      setFileName(`${jobTitle}.csv`)

    } else {



      dispatch(setDownloaded(jobId, selected, newJob, activeTab))
      const data = csvData.filter(item => selected.includes(item.id))
      r = transformCsvData(data)
    }

    return {
      data: r,
      filename: `${jobTitle}.csv`,
    }


  }


  const handleClickItemFirstSort = (item) => {
    const {
      location: { search },
      history: { push }
    } = rest

    const params = {
      ...qs.parse(search),
      fS: item.value,
      fSO: item.sort || 'desc'
    }

    const qsStr = qs.stringify(params)


    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "FIRST_SORT_" + item.value.toUpperCase() })
    //   })
    dispatch(unselectAllProspects())
    push(`/html/score.html?${qsStr}`)


  }

  const handleClickArchive = async () => {

    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "ARCHIVE_PROSPECT" })
    //   })

    if (SelectAllProspectsFlag) {

      const params = Object.keys(qsParams).reduce((obj, item) => {
        return { ...obj, [item]: encodeURIComponent(qsParams[item]) }
      }, {})


      const data = await manualApiCall(`/api/auth/job/profile/list/advancedFilter?jId=${params.jId}${
        params.fS ? `&fS=${params.fS}&fSO=${params.fSO}` : ''}${
        params.secS ? `&secS=${params.secS}&secSO=${params.secSO}` : ''}${
        params.tF ? `&tF=${params.tF}` : ''}${params.cTF ? `&cTF=${params.cTF}` : ''}${
        params.sS ? `&sS=${params.sS}` : ""}${
        params.eS ? `&eS=${params.eS}` : ""}${params.hS ? `&hS=${params.hS}` : ""}${
        params.eSS ? `&eSS=${params.eSS}` : ""}${params.hSS ? `&hSS=${params.hSS}` : ""}${
        params.eCT ? `&eCT=${params.eCT}` : ""}${params.hCT ? `&hCT=${params.hCT}` : ""}${
        params.ePT ? `&ePT=${params.ePT}` : ""}${params.hPT ? `&hPT=${params.hPT}` : ""}${
        params.cTS ? `&cTS=${params.cTS}` : ""}${params.pTS ? `&pTS=${params.pTS}` : ""}${
        params.eCC ? `&eCC=${params.eCC}` : ""}${params.hCC ? `&hCC=${params.hCC}` : ""}${
        params.ePC ? `&ePC=${params.ePC}` : ""}${params.hPC ? `&hPC=${params.hPC}` : ""}${
        params.cSR ? `&cSR=${params.cSR}` : ""}${params.eCI ? `&eCI=${params.eCI}` : ""}${
        params.hCI ? `&hCI=${params.hCI}` : ""}${params.ePI ? `&ePI=${params.ePI}` : ""}${
        params.hPI ? `&hPI=${params.hPI}` : ""}${params.eL ? `&eL=${params.eL}` : ""}${
        params.hL ? `&hL=${params.hL}` : ""}${params.eV ? `&eV=${params.eV}` : ""}${
        params.hV ? `&hV=${params.hV}` : ""}${params.tSF ? `&tSF=${params.tSF}` : ""}${
        params.sSF ? `&sSF=${params.sSF}` : ""}${params.expSF ? `&expSF=${params.expSF}` : ""}${
        params.edSF ? `&edSF=${params.edSF}` : ""}${params.iSF ? `&iSF=${params.iSF}` : ""}${
        params.tE ? `&tE=${params.tE}` : ""}${params.rE ? `&rE=${params.rE}` : ""}&pFlag=false`)
        .then((data) => data.prospectsArray.map(itm => itm.id))
        .then((res) => dispatch(archiveProspects({ jobId: params.jId, prospects: res, newJob: newJob })))
    }
    else {
      const params = qs.parse(rest.location.search)
      dispatch(archiveProspects({ jobId: params.jId, prospects: selected, newJob: newJob  }))
    }

    // dispatch(unselectAllProspects())
  }
  const handleClickUnarchive = async () => {

    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "UNARCHIVE_PROSPECT" })
    //   })

    const params = qs.parse(rest.location.search)
    if (SelectAllProspectsFlag) {

      const params = Object.keys(qsParams).reduce((obj, item) => {
        return { ...obj, [item]: encodeURIComponent(qsParams[item]) }
      }, {})


      const data = await manualApiCall(`/api/auth/job/profile/list/advancedFilter?jId=${params.jId}${
        params.fS ? `&fS=${params.fS}&fSO=${params.fSO}` : ''}${
        params.secS ? `&secS=${params.secS}&secSO=${params.secSO}` : ''}${
        params.tF ? `&tF=${params.tF}` : ''}${params.cTF ? `&cTF=${params.cTF}` : ''}${
        params.sS ? `&sS=${params.sS}` : ""}${
        params.eS ? `&eS=${params.eS}` : ""}${params.hS ? `&hS=${params.hS}` : ""}${
        params.eSS ? `&eSS=${params.eSS}` : ""}${params.hSS ? `&hSS=${params.hSS}` : ""}${
        params.eCT ? `&eCT=${params.eCT}` : ""}${params.hCT ? `&hCT=${params.hCT}` : ""}${
        params.ePT ? `&ePT=${params.ePT}` : ""}${params.hPT ? `&hPT=${params.hPT}` : ""}${
        params.cTS ? `&cTS=${params.cTS}` : ""}${params.pTS ? `&pTS=${params.pTS}` : ""}${
        params.eCC ? `&eCC=${params.eCC}` : ""}${params.hCC ? `&hCC=${params.hCC}` : ""}${
        params.ePC ? `&ePC=${params.ePC}` : ""}${params.hPC ? `&hPC=${params.hPC}` : ""}${
        params.cSR ? `&cSR=${params.cSR}` : ""}${params.eCI ? `&eCI=${params.eCI}` : ""}${
        params.hCI ? `&hCI=${params.hCI}` : ""}${params.ePI ? `&ePI=${params.ePI}` : ""}${
        params.hPI ? `&hPI=${params.hPI}` : ""}${params.eL ? `&eL=${params.eL}` : ""}${
        params.hL ? `&hL=${params.hL}` : ""}${params.eV ? `&eV=${params.eV}` : ""}${
        params.hV ? `&hV=${params.hV}` : ""}${params.tSF ? `&tSF=${params.tSF}` : ""}${
        params.sSF ? `&sSF=${params.sSF}` : ""}${params.expSF ? `&expSF=${params.expSF}` : ""}${
        params.edSF ? `&edSF=${params.edSF}` : ""}${params.iSF ? `&iSF=${params.iSF}` : ""}${
        params.tE ? `&tE=${params.tE}` : ""}${params.rE ? `&rE=${params.rE}` : ""}&pFlag=false`)
        .then((data) => data.prospectsArray.map(itm => itm.id))
        .then((res) => dispatch(unarchiveProspects({ jobId: params.jId, prospects: res, newJob:newJob })))
    }
    else {
      const params = qs.parse(rest.location.search)
      dispatch(unarchiveProspects({ jobId: params.jId, prospects: selected, newJob:newJob }))
    }
    // const params = qs.parse(rest.location.search)
    // dispatch(unarchiveProspects({ jobId: params.extensionJobId, prospects: selected }))
      dispatch(unselectAllProspects())

  }

  const handleClickItemSecondSort = (item) => {
    const {
      location: { search },
      history: { push }
    } = rest

    const params = {
      ...qs.parse(search),
      secS: item.value,
      secSO: item.sort || 'desc'
    }

    const qsStr = qs.stringify(params)

    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "SECOND_SORT_" + item.value.toUpperCase() })
    //   })


      dispatch(unselectAllProspects())   
     push(`/html/score.html?${qsStr}`)


  }

  
  const handleClickConnectionItem = (item) => {
    const {
      location: { search },
      history: { push }
    } = rest

    const params = {
      ...qs.parse(search),
      cTF: item.value,
    }

    if(item.value === "all")
      delete params.cTF

    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "CONNECTION_FILTER_" + item.value.toUpperCase() })
    //   })

    const qsStr = qs.stringify(params)

    dispatch(unselectAllProspects())
    push(`/html/score.html?${qsStr}`)


  }
  const handleClickSelectAll = () => {

    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "TOGGLE_SELECT_ALL" })
    //   })

    if (SelectAllProspectsFlag) {
      setSelectAllProspectsFlag(false)
      dispatch(unselectAllProspects())
    } else {
      setSelectAllProspectsFlag(true)
      dispatch(selectAllProspects())
    }
    // selected
  }

  const handleClickSelectTop = () => {
    if (topProspects === totalRecord) {
      setSelectAllProspectsFlag(true)
    }
    else {
      setSelectAllProspectsFlag(false)
    }

    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "SELECT_TOP_PROSPECTS" })
    //   })

    dispatch(selectTopProspects(topProspects))
  }

  const handleClearFilters = () => {
    setFilterCount(0)
    setScoringFilterCount(0)
    dispatch(clearAdvancedFilters())
    dispatch(clearScoringFilter())
    
    
    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "CLEAR_FILTER" })
    //   })

    const searchParams = qs.parse(rest.location.search)

    const newParams = {
      jId: searchParams.jId,
      isConnectPage: 1,
      fS: searchParams.fS,
      fSO: searchParams.fSO,
      secS: searchParams.secS,
      secSO: searchParams.secSO}
    
    if(searchParams.tF)
      newParams['tF'] = searchParams.tF 


    dispatch(unselectAllProspects())
    rest.history.push(`/html/score.html?${qs.stringify(newParams)}`)
  }

  const handleScoringFilterOpen = (e) => {
    const tCount = qsParams.tSF ? 1 : 0
    const sCount = qsParams.sSF ? 1 : 0
    const expCount = qsParams.expSF ? 1 : 0
    const edCount = qsParams.edSF ? 1 : 0
    const iCount = qsParams.iSF ? 1 : 0

    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "OPEN_SCORING_FILTER" })
    //   })

    const sum = (tCount + 
      sCount + expCount + 
      edCount + iCount ) 


    if (tCount !== 0){
      if(!ScoringFilters.titleScore.active)
        dispatch(toggleTitleScoringFilter())
      dispatch(setTitleScoringFilter(parseInt(qsParams.tSF)))
    }
    

    if (sCount !== 0){
      if(!ScoringFilters.skillScore.active)
        dispatch(toggleSkillScoringFilter())
      dispatch(setSkillScoringFilter(parseInt(qsParams.sSF)))
    }
    

    if (expCount !== 0){
      if(!ScoringFilters.experienceScore.active)
        dispatch(toggleExperienceScoringFilter())
      dispatch(setExperienceScoringFilter(parseInt(qsParams.expSF)))
    }
    

    if (edCount !== 0){
      if(!ScoringFilters.educationScore.active)
        dispatch(toggleEducationScoringFilter())
      dispatch(setEducationScoringFilter(parseInt(qsParams.edSF)))
    }
    

    if (iCount !== 0){
      if(!ScoringFilters.industryScore.active)
        dispatch(toggleIndustryScoringFilter())
      dispatch(setIndustryScoringFilter(parseInt(qsParams.iSF)))
    }
    
    setScoringFilterCount(sum)

    setScoringFilterShow(true)
  }



  const handleAdvancedFilterOpen = (e) => {

    const eVCount = qsParams.eV ? qsParams.eV.split('||').length : 0
    const hVCount = qsParams.hV ? qsParams.hV.split('||').length : 0
    const eLCount = qsParams.eL ? qsParams.eL.split('||').length : 0
    const hLCount = qsParams.hL ? qsParams.hL.split('||').length : 0
    const eSCount = qsParams.eS ? qsParams.eS.split('||').length : 0
    const hSCount = qsParams.hS ? qsParams.hS.split('||').length : 0
    const eSSCount = qsParams.eSS ? qsParams.eSS.split('||').length : 0
    const hSSCount = qsParams.hSS ? qsParams.hSS.split('||').length : 0
    const eCICount = qsParams.eCI ? qsParams.eCI.split('||').length : 0
    const ePICount = qsParams.ePI ? qsParams.ePI.split('||').length : 0
    const hCICount = qsParams.hCI ? qsParams.hCI.split('||').length : 0
    const hPICount = qsParams.hPI ? qsParams.hPI.split('||').length : 0
    const eCTCount = qsParams.eCT ? qsParams.eCT.split('||').length : 0
    const ePTCount = qsParams.ePT ? qsParams.ePT.split('||').length : 0
    const hCTCount = qsParams.hCT ? qsParams.hCT.split('||').length : 0
    const hPTCount = qsParams.hPT ? qsParams.hPT.split('||').length : 0
    const eCCCount = qsParams.eCC ? qsParams.eCC.split('||').length : 0
    const ePCCount = qsParams.ePC ? qsParams.ePC.split('||').length : 0
    const hCCCount = qsParams.hCC ? qsParams.hCC.split('||').length : 0
    const hPCCount = qsParams.hPC ? qsParams.hPC.split('||').length : 0
    const cSRCount = qsParams.cSR ? 1 : 0
    const tECount = qsParams.tE ? 1 : 0
    const rECount = qsParams.rE ? 1 : 0

    
    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "OPEN_ADVANCED_FILTER" })
    //   })

    const sum = (eVCount + 
      hVCount + eLCount + 
      hLCount + eSCount +
      hSCount + eSSCount +
      hSSCount + eCICount +
      hCICount + hPICount +
      eCTCount + ePTCount +
      hCTCount + hPTCount +
      eCCCount + ePCCount +
      hCCCount + hPCCount +
      cSRCount + tECount +
      rECount) 
    
    dispatch(setStrictSkills(qsParams.sS ? qsParams.sS : false))
  
    if(qsParams.cTS && !AdvFilters.currentTitleStrict)
      dispatch(toggleStrictCurrentTitle())
  
    if(qsParams.pTS && !AdvFilters.pastTitleStrict)
      dispatch(toggleStrictPastTitle())
  
      
    
    if (eVCount+hVCount > 0)
      dispatch(setVisaFilters(AdvFilters.visa.map(itm =>
        eVCount > 0  && qsParams.eV.split('||').includes(itm.name) ?
          { name: itm.name, value: 2 } :
        hVCount > 0  && qsParams.hV.split('||').includes(itm.name) ? 
          { name: itm.name, value: 1 } : itm)))
      
    if (eLCount+hLCount > 0)
      dispatch(setLocationFilters(AdvFilters.location.map(itm =>
        eLCount > 0 && qsParams.eL.split('||').includes(itm.name) ?
          { name: itm.name, value: 2 } :
        hLCount > 0 && qsParams.hL.split('||').includes(itm.name)  ? 
          { name: itm.name, value: 1 } : itm)))
    
    if (eSCount+hSCount > 0)
      dispatch(setSkillsFilters(AdvFilters.skills.map(itm =>
        eSCount > 0 && qsParams.eS.split('||').includes(itm.name) ?
          { name: itm.name, value: 2 } :
        hSCount > 0 &&  qsParams.hS.split('||').includes(itm.name) ? 
          { name: itm.name, value: 1 } : itm)))

    if (eSSCount+hSSCount > 0)
      dispatch(setSchoolFilters(AdvFilters.school.map(itm =>
        eSSCount > 0 && qsParams.eSS.split('||').includes(itm.name) ?
          { name: itm.name, value: 2 } :
        hSSCount > 0 && qsParams.hSS.split('||').includes(itm.name) ? 
          { name: itm.name, value: 1 } : itm)))
    
    if (eCTCount+hCTCount > 0)
      dispatch(setCurrentTitleFilters(AdvFilters.currentTitle.map(itm =>
        eCTCount > 0 && qsParams.eCT.split('||').includes(itm.name) ?
          { name: itm.name, value: 2 } :
        hCTCount > 0 && qsParams.hCT.split('||').includes(itm.name) ? 
          { name: itm.name, value: 1 } : itm)))
        
    if (eCCCount+hCCCount > 0)
      dispatch(setCurrentCompanyFilters(AdvFilters.currentCompany.map(itm =>
        eCCCount > 0 && qsParams.eCC.split('||').includes(itm.name) ?
          { name: itm.name, value: 2 } :
        hCCCount > 0 && qsParams.hCC.split('||').includes(itm.name) ? 
          { name: itm.name, value: 1 } : itm)))

    if (eCICount+hCICount > 0)
      dispatch(setCurrentIndustryFilters(AdvFilters.currentIndustry.map(itm =>
        eCICount > 0 &&  qsParams.eCI.split('||').includes(itm.name) ?
          { name: itm.name, value: 2 } :
        hCICount > 0 && qsParams.hCI.split('||').includes(itm.name) ? 
          { name: itm.name, value: 1 } : itm)))
          
    if (ePTCount+hPTCount > 0)
      dispatch(setPastTitleFilters(AdvFilters.pastTitle.map(itm =>
        ePTCount > 0 && qsParams.ePT.split('||').includes(itm.name)?
          { name: itm.name, value: 2 } :
        hPTCount > 0  && qsParams.hPT.split('||').includes(itm.name)? 
          { name: itm.name, value: 1 } : itm)))
        
    if (ePCCount+hPCCount > 0)
      dispatch(setPastCompanyFilters(AdvFilters.pastCompany.map(itm =>
        ePCCount > 0 && qsParams.ePC.split('||').includes(itm.name) ?
          { name: itm.name, value: 2 } :
          hPCCount > 0 &&qsParams.hPC.split('||').includes(itm.name) ? 
          { name: itm.name, value: 1 } : itm)))

    if (cSRCount !== 0){
      if(!AdvFilters.companySize.active)
        dispatch(toggleCompanySizeFilter())
      dispatch(setCompanySizeFilter({min:parseInt(qsParams.cSR.split('||'[0])),max:parseInt(qsParams.cSR.split('||')[1])}))
    }
  
    if (ePICount+hPICount > 0)
      dispatch(setPastIndustryFilters(AdvFilters.pastIndustry.map(itm =>
        ePICount > 0 && qsParams.ePI.split('||').includes(itm.name) ?
          { name: itm.name, value: 2 } :
        hPICount > 0  && qsParams.hPI.split('||').includes(itm.name)? 
          { name: itm.name, value: 1 } : itm)))
    
    
    if (cSRCount === 1){
      if(!AdvFilters.companySize.active)
        dispatch(toggleCompanySizeFilter())
      dispatch(setCompanySizeFilter({min:parseInt(qsParams.cSR.split('||')[0]),max:parseInt(qsParams.cSR.split('||')[1])}))
    }
    
    if (tECount === 1){
      if(!AdvFilters.totalExperience.active)
        dispatch(toggleTotalExperienceFilter())
      dispatch(setTotalExperienceFilter({min:parseInt(qsParams.tE.split('||')[0]),max:parseInt(qsParams.tE.split('||')[1])}))
    }
    
    if (rECount === 1){
      if(!AdvFilters.relevantExperience.active)
        dispatch(toggleRelevantExperienceFilter())
      dispatch(setRelevantExperienceFilter({min:parseInt(qsParams.rE.split('||')[0]),max:parseInt(qsParams.rE.split('||')[1])}))
    }              

    setFilterCount(sum)

    setShow(true)
  }


  const firstSort = qsParams.fS ? sortItems.filter(it => {
    if (qsParams.fS === 'age' || qsParams.fS === 'ethnicity')
      return qsParams.fSO === it.sort && qsParams.fS == it.value
    return it.value === qsParams.fS
  })[0].name : ''

  const secondSort = qsParams.secS ? sortItems.filter(it => {
    if (qsParams.secS === 'age' || qsParams.secS === 'ethnicity')
      return qsParams.secS == it.value && qsParams.secSO === it.sort 
    return it.value === qsParams.secS
  })[0].name : ''


  let filterCount = Object.keys(qsParams).length - 2
  if (rest.location.search.includes('fS')) {
    filterCount -= 2
  }
  if (rest.location.search.includes('secS')) {
    filterCount -= 2
  }
  if (rest.location.search.includes('cTF')) {
    filterCount -= 1
  }
  if (rest.location.search.includes('tF')) {
    filterCount -= 1
  }
  if (rest.location.search.includes('sS')) {
    filterCount -= 1
  }
  if (rest.location.search.includes('cTS')) {
    filterCount -= 1
  }
  if (rest.location.search.includes('pTS')) {
    filterCount -= 1
  }

  return (
    <React.Fragment>
      {<div className="collapsible" style={{ display: Show ? 'none' : 'block' }} >
        <Collapse isOpen={Icon}>
          <Card className="dropcard">
            <CardBody className="dropcardbody">
              <div className="dropdownContainer">
                <div className="leftContainer">
                {/* {(qsParams?.tF !== 'Other' && qsParams?.tF!=='Replied') &&
                  <Dropdown isOpen={Drop1} toggle={() => setDrop1(!Drop1)}>
                    <DropdownToggle className="dropDownButtons" outline color="primary" onClick={() => setDrop1(!Drop1)}>
                      <p className="dropdowntext">{
                        qsParams.cTF ?
                          connectionItems.filter(item => item.id == qsParams.cTF)[0].name
                          : 'All Connections'
                      }</p><img className="ddcaret" src={BlueVectorIcon} style={{ transform: Drop1 ? "rotate(180deg)" : "rotate(0deg)" }} />
                    </DropdownToggle>
                    <DropdownMenu className='ddmenu' >
                      {connectionItems.map((fsItem, i) =>
                        <React.Fragment key={fsItem.id}>
                          <DropdownItem className='dditem' onClick={() => handleClickConnectionItem(fsItem)}>
                            {fsItem.name}
                          </DropdownItem>
                          <DropdownItem disabled className="DropDownDeviderTag" devider="true" />
                        </React.Fragment>)}
                    </DropdownMenu>
                  </Dropdown>} */}
                  {/* <Dropdown isOpen={Drop2} toggle={() => setDrop2(!Drop2)}>
                    <DropdownToggle className="dropDownButtons" outline color="secondary" onClick={() => setDrop2(!Drop2)}>
                      <p className="dropdowntext">
                        {
                          qsParams.type ?
                            actionItems.filter(item => item.value == qsParams.type)[0].name :
                            'No Action'
                        }
                      </p><img className="ddcaret" src={BlueVectorIcon} style={{ transform: Drop2 ? "rotate(180deg)" : "rotate(0deg)" }} />
                    </DropdownToggle>
                    <DropdownMenu className='ddmenu' >
                      {actionItems.map((fsItem, i) =>
                        <React.Fragment key={fsItem.id}>
                          <DropdownItem
                            className='dditem'
                            onClick={() => {

                              handleClickActionItem(fsItem)
                            }}>
                            {fsItem.name}
                          </DropdownItem>
                          <DropdownItem devider="true" />
                        </React.Fragment>)}
                    </DropdownMenu>
                  </Dropdown> */}

                  <AdvanceFilterTags
                    Visa={Visa}
                    setVisa={setVisa}
                    setVisaStatus={setVisaStatus}
                    setFilterCount={setFilterCount}
                    setSkillsFilterList={setSkillsFilterList}
                    setTitleFilterList={setTitleFilterList}
                    setCompanyFilterList={setCompanyFilterList}
                    setSchoolsFilterList={setSchoolsFilterList}
                    setIndustryFilterList={setIndustryFilterList}
                    skillsFilterList={skillsFilterList}
                    titleFilterList={titleFilterList}
                    schoolsFilterList={schoolsFilterList}
                    companyFilterList={companyFilterList}
                    industryFilterList={industryFilterList}


                    push={rest.history.push}
                    qsParams={qsParams}
                    skillFilterCount={qsParams.skillFilter ? true : false}
                    titleFilterCount={qsParams.titleFilter ? true : false}
                    companyFilterCount={qsParams.companyFilter ? true : false}
                    schoolFilterCount={qsParams.schoolFilter ? true : false}
                    industryFilterCount={qsParams.industryFilter ? true : false}
                    visaFilterCount={qsParams.visa ? true : false}
                    advanceFilterCount={qsParams.advanceFilterElemenate ? true : false} />

                  <Button
                    color="primary"
                    className="advancedFilterButton"
                    onClick={handleAdvancedFilterOpen}>
                    Advanced Filters
                  </ Button>

                <Button
                    color="primary"
                    className="advancedFilterButton"
                    onClick={handleScoringFilterOpen}>
                    Scoring Filters
                  </ Button>

                  {filterCount > 0 &&
                    <>
                      <span className='verBar' />
                      <a
                        className="clearFilters"
                        onClick={handleClearFilters}>
                        Clear ({filterCount})
                      </ a>
                    </>
                  }
                </div>
                <div className="rightContainer">
                  <Dropdown isOpen={Drop3} toggle={() => setDrop3(!Drop3)}>
                    <DropdownToggle className="dropDownButtons" outline color="primary" onClick={() => setDrop3(!Drop3)}>
                      <p className="dropdowntext">{`Sort By ${firstSort}`}</p><img className="ddcaret" src={BlueVectorIcon} style={{ transform: Drop3 ? "rotate(180deg)" : "rotate(0deg)" }} />
                    </DropdownToggle>
                    <DropdownMenu className='ddmenu' >
                      {sortItems.map((fsItem, i) =>
                        <React.Fragment key={fsItem.id}>
                          <DropdownItem className='dditem' onClick={() => handleClickItemFirstSort(fsItem)}>
                            {fsItem.name}{fsItem.name === 'Title' &&
                              <p className='sortingDropDownDefaultTag'>(Default)</p>}
                          </DropdownItem>
                          <DropdownItem disabled className="DropDownDeviderTag" devider="true" />
                        </React.Fragment>

                      )}
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown isOpen={Drop4} toggle={() => setDrop4(!Drop4)}>
                    <DropdownToggle className="dropDownButtons" outline color="primary" onClick={() => setDrop4(!Drop4)}>
                      <p className="dropdowntext">{`Sort again By ${secondSort}`}</p><img className="ddcaret" src={BlueVectorIcon} style={{ transform: Drop4 ? "rotate(180deg)" : "rotate(0deg)" }} />
                    </DropdownToggle>
                    <DropdownMenu className='ddmenu'>
                      {sortItems.map((scndItem, i) =>
                        <React.Fragment key={scndItem.id}>
                          <DropdownItem className='dditem' onClick={() => handleClickItemSecondSort(scndItem)}>
                            {scndItem.name}{scndItem.name === 'Skills' &&
                              <p className='sortingDropDownDefaultTag'>(Default)</p>}
                          </DropdownItem>
                          <DropdownItem disabled className="DropDownDeviderTag" devider="true" />
                        </React.Fragment>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </ div>
            </CardBody>
          </Card>

          <hr className="filterbar" />
        </Collapse>
      </ div>}
      < >
        <span className='minus-icon-container'>
          <Input
            type="checkbox"
            name="prospect"
            className="selectAllCheckBox"
            onChange={() => { handleClickSelectAll() }}
            // onClick={handleClickSelectAll}
            checked={SelectAllProspectsFlag} />
          {selected.length > 0 && !SelectAllProspectsFlag &&
            <div
              className='triStateCheckDiv'
              onClick={handleClickSelectAll}
            >
              <span
                onClick={handleClickSelectAll}
                className='triStateCheckSpan' />
            </div>
          }

          <Dropdown
            className='selectionPopContainer'
            isOpen={SelectionPop}
            toggle={() => setSelectionPop(!SelectionPop)
            }>
            <DropdownToggle className='selectionPop'>
              <img src={VectorIcon} alt='vector' />
            </DropdownToggle>
            <DropdownMenu className='selectionPopMenu'>
              <DropdownItem
                className='selectionPopItem'
                onClick={() => {
                  setSelectionPop(false)
                  handleClickSelectAll()
                }}
              >
                {SelectAllProspectsFlag ? 'Unselect All' : 'Select All'}
              </DropdownItem>
              <DropdownItem className='selectionPop' devider="true" />
              <DropdownItem className='selectionPopItem' text >
                <div className='topSelectionContainer'>
                  Select Top <Input
                    className="topSelection"
                    type="number"
                    name="number"
                    placeholder="100"
                    min={1}
                    max={totalProspects > 100 ? 100 : totalProspects}
                    value={topProspects}
                    onChange={(e) => { setTopProspects(e.target.value) }}
                  /> Prospects
                  </div>
                <div className='applyContainer'>
                  <Button
                    outline
                    color='primary'
                    className='selectionApply'
                    onClick={() => {
                      setSelectionPop(false)
                      handleClickSelectTop()}}>
                    Apply
                    </Button>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>


        </span>
        { selected.length < 1 ?
          <React.Fragment >
            <p className='notAvailable'>Please select at least one prospect</p>
          </ React.Fragment > :
          <React.Fragment >
            {["Home", "Added1st", "SavedAND1stAdded", "SavedAND2ndAdded"].includes(activeTab) &&
              <React.Fragment>
                {/* <Button
                  id='compMsgBt'
                  className="filterIconButtons"
                  outline color="primary"
                  style={isLimitExceeded ? { opacity: 0.5 } : {}}
                  onClick={() => {
                    if (isLimitExceeded) {
                      return
                    } else {

                      manualApiCall('/api/auth/user/activity/store',
                        {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' },
                          body: JSON.stringify({ "actionName": "OPEN_COMPOSE_MESSAGE" })
                        })

                      setView('ComposeMessage')
                    }
                  }}
                // disabled={isLimitExceeded}
                >
                  <img src={ComposeMessageSequence} className="filterIconSVG" />
                </Button> */}
                {/* {isLimitExceeded ?
                  <Tooltip
                    target='compMsgBt'
                    placement='bottom'
                    toggle={() => setTooltipOpenC(!tooltipOpenC)}
                    isOpen={tooltipOpenC}
                    // fade={true}
                    style={{ zIndex: 9 }}
                    className='tooltip-root'>
                    You have reached your daily Connection limit. Sending more Connection requests may result in LinkedIn blocking your account.
                  </Tooltip> : 
                  <Tooltip
                    target='compMsgBt'
                    placement='bottom'
                    toggle={() => setComposeMsgToolTipOpen(!ComposeMsgToolTipOpen)}
                    isOpen={ComposeMsgToolTipOpen}
                    // fade={true}
                    style={{ zIndex: 9 }}
                    className='tooltip-root'>
                    Compose Message Sequence
                  </Tooltip> } */}
                  {/* <Button
                    id={'calendarMeetingRequestBtn'}
                    className="filterIconButtons"
                    outline color="primary"
                    style={isLimitExceeded ? { opacity: 0.5 } : {}}
                    onClick={() => {
                      if (isLimitExceeded) {
                        return
                      } else {


                      manualApiCall('/api/auth/user/activity/store',
                      {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ "actionName": "OPEN_REQUEST_MEETING" })
                      })

                        setView('RequestMeeting')
                      }
                    }}
                  // disabled={isLimitExceeded}
                  >
                    <img src={RequestMeetingSequence} className="filterIconSVG" />
                </Button> */}
                {/* {isLimitExceeded ?
                  <Tooltip
                    target='calendarMeetingRequestBtn'
                    placement='bottom'
                    toggle={() => setTooltipOpenR(!tooltipOpenR)}
                    isOpen={tooltipOpenR}
                    // fade={true}
                    style={{ zIndex: 9 }}
                    className='tooltip-root'>
                    You have reached your daily Connection limit. Sending more Connection requests may result in LinkedIn blocking your account.
                </Tooltip>:
                  <Tooltip
                    target='calendarMeetingRequestBtn'
                    placement='bottom'
                    toggle={() => setRequestMeetingToolTipOpen(!RequestMeetingToolTipOpen)}
                    isOpen={RequestMeetingToolTipOpen}
                    // fade={true}
                    style={{ zIndex: 9 }}
                    className='tooltip-root'>
                  Request Meeting
                </Tooltip> } */}
              </React.Fragment>}
            {/* <Button className="filterbuttons" outline color="primary"
              onClick={() => setView('RequestMessage')}>
                Request Meeting
            </Button> */}
            {/* <CSVLink
              asyncOnClick={true}
              onClick={ (event, done) => {
                 handleDownloadCSV()
                 .then(() => {
                   return simulateTimeout(300)
                 })
                 .then(r => {
                   done()
                 })
                 .catch(e => {
                   console.log('error MESSAGE: ', e.message)
                 })
                
              }}
              // data={csvDownloadData}
              data={ csvDownloadData }
              filename={fileName}>
              <Button
                className="downloadButtonCSV filterbuttons"
                outline
                color="primary">
                Download CSV
              </Button>
            </CSVLink> */}
            <CsvExport asyncExportMethod={handleDownloadCSV}>
              <Button
                id="DownloadCSVButton"
                className="filterIconButtons"
                outline
                color="primary">
                {isDownloading ? 
                  <Loader color='blue' width = '16px' height = '16px' /> : 
                  <img src={DownloadCSV} className="filterIconSVG" />}
              </Button>
            </CsvExport>
            <Tooltip
              target='DownloadCSVButton'
              placement='bottom'
              toggle={() => setDownloadCSVToolTipOpen(!DownloadCSVToolTipOpen)}
              isOpen={DownloadCSVToolTipOpen}
              // fade={true}
              style={{ zIndex: 9 }}
              className='tooltip-root'>
              DownLoad CSV
            </Tooltip> 
            {/* <Button
              id="UsaveButton"
              outline color='secondary'
              className='trashIconContainer'
              onClick={handleClickArchive}>
              <img className='trashIcon' src={TrashIcon} alt='trash' />
            </Button>
            <Tooltip
              target='UsaveButton'
              placement='bottom'
              toggle={() => setUnsaveToolTipOpen(!UnsaveToolTipOpen)}
              isOpen={UnsaveToolTipOpen}
              style={{ zIndex: 9 }}
              className='tooltip-root'>
              Unsave Selected Prospects
            </Tooltip> */}

            {["Home", "Added1st", "SavedAND1stAdded", "SavedAND2ndAdded"].includes(activeTab) &&
              <React.Fragment>
                {/* <Button
                  id="ArchiveButton"
                  outline color='secondary'
                  className='trashIconContainer'
                  onClick={handleClickArchive}>
                    {rest.isArchiving ? <Loader color='blue' width = '16px' height = '16px' /> : <img className='trashIcon' src={TrashIcon} alt='trash' />}
                  
                </Button>
                <Tooltip
                  target='ArchiveButton'
                  placement='bottom'
                  toggle={() => setArchiveToolTipOpen(!archiveToolTipOpen)}
                  isOpen={archiveToolTipOpen}
                  style={{ zIndex: 9 }}
                  className='tooltip-root'>
                  Archive
              </Tooltip> */}
              </React.Fragment>
            }

            {/* {activeTab === 'Archived' &&
              <Button
                outline
                color='primary'
                className='filterIconButtons'
                onClick={handleClickUnarchive}>
                Unarchive
              </Button>} */}

          </React.Fragment>}
        {!Show && <Button
          className="filterbuttons"
          outline
          color="primary"
          onClick={() => setIcon(!Icon)}>
          {`Filter/Sort ${Icon ? '-' : '+'}`}
        </Button>}
        {selected.length > 0 && <div className='selectionCounterTagBold'>
          {`${SelectAllProspectsFlag ? 
            totalRecord : 
            selected.length}`}
          <p className='selectionCounterTag'>
            {` of ${totalRecord} prospects selected`}
          </p>
        </div>}
      </>
    </ React.Fragment>
  )
}

export default withRouter(
  connect(state => {
    return {AdvFilters: state.score.advancedFilters,
      ScoringFilters: state.score.scoringFilters,  
      allProspects: state.score.allProspects
    }
  })(FilterSection))
