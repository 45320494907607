import {
    ADD_ADMIN,
    ADD_ADMIN_SUCCESS,
    ADD_ADMIN_FAILURE
} from '../actions/addUser';

const initialState = {
   isLoading: false,
    isDataAvailable: false,
    message:''
}

export default function addAdmin(state = initialState, action) {

    switch (action.type) {
        case  ADD_ADMIN:
            return {
                ...state,
                isLoading:true,
                isDataAvailable: false,
                message:''
            }
        case   ADD_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            }
        case   ADD_ADMIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message,
                isDataAvailable: false
            }

        default:
            return state;
    }
}


