import React, {useState, useEffect} from 'react'
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'

import qs from 'query-string'
import { withRouter } from 'react-router-dom'

import { manualApiCall } from '../../utils'
import {
  unselectAllProspects
} from '../../actions/score'


const TabsSection = ({ 
  setSelectAllProspectsFlag,
  totalFirstMsgd,
  totalSecondMsgd,
  totalThirdMsgd,
  totalMsgd,
  search,
  push,
  dispatch
  }) => {
    const [ActiveTabFilter, setActiveTabFilter] = useState(1)

    useEffect(() => {
      const TabState = qs.parse(search)?.tF
      switch (TabState) {
        case "ConnectMessaged":
        case "SavedANDNotMessaged":
          setActiveTabFilter(1)
          break;
        case "FollowUp1st":
        case "SavedANDMessaged":
          setActiveTabFilter(2)
          break;
        case "FollowUp2nd":
          setActiveTabFilter(3)
          break;
        default:
          setActiveTabFilter(1)
          break;
      }

    }, [])


    const handleFirstMessageFilter = (e) => {

      if ('ConnectMessaged' !== qs.parse(search).tF) {

        setActiveTabFilter(1)
        setSelectAllProspectsFlag(false)
        
        const parameters = qs.parse(search)
        parameters['tF']= 'ConnectMessaged'
        
  
        
        // manualApiCall('/api/auth/user/activity/store', 
        // {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({"actionName":"SAVED_FILTER"})
        // })
  
        const qsStr = qs.stringify(parameters)
        dispatch(unselectAllProspects())
        push(`/html/score.html?${qsStr}`)
      }

    }
    const handleSecondMessageFilter = (e) => {

      if ('FollowUp1st' !== qs.parse(search).tF) {
        setActiveTabFilter(2)
        setSelectAllProspectsFlag(false)
  
        const parameters = qs.parse(search)
        parameters['tF']= 'FollowUp1st'
        if(parameters.cTF)
          delete parameters.cTF
      
  
        
        // manualApiCall('/api/auth/user/activity/store', 
        // {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({"actionName":"SAVED_FILTER"})
        // })
  
        const qsStr = qs.stringify(parameters)
        dispatch(unselectAllProspects())
        push(`/html/score.html?${qsStr}`)
      }
      
    }
    const handleThirdMessageFilter = (e) => {

      if ('FollowUp2nd' !== qs.parse(search).tF) {
        setActiveTabFilter(3)
        setSelectAllProspectsFlag(false)
  
        const parameters = qs.parse(search)
        parameters['tF']= 'FollowUp2nd'
        if(parameters.cTF)
            delete parameters.cTF

        
        // manualApiCall('/api/auth/user/activity/store', 
        // {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({"actionName":"SAVED_FILTER"})
        // })

        const qsStr = qs.stringify(parameters)
        dispatch(unselectAllProspects())
        push(`/html/score.html?${qsStr}`)
      }
      
    }
    // const handleDefaultMessageFilter = (e) => {
    //   console.log("===============", ActiveTabFilter)
    //   setActiveTabFilter(0)
    //   setSelectAllProspectsFlag(false)

    //   if ('sent' !== qs.parse(search).type) {
    //     const parameters = {
    //       ...qs.parse(search),
    //       type: 'sent'
    //     }

        
    //     // manualApiCall('/api/auth/user/activity/store', 
    //     // {
    //     //   method: 'POST',
    //     //   headers: { 'Content-Type': 'application/json' },
    //     //   body: JSON.stringify({"actionName":"SAVED_FILTER"})
    //     // })

    //     const qsStr = qs.stringify(parameters)
    //     push(`/html/score.html?${qsStr}`)
    // }
      
    // }
    const handleSavedNotMessageFilter = (e) => {
      
      if ('SavedANDNotMessaged' !== qs.parse(search).tF) {

        setActiveTabFilter(1)
        setSelectAllProspectsFlag(false)
        
        const parameters = qs.parse(search)
        parameters['tF']= 'SavedANDNotMessaged'
        
  
        
        // manualApiCall('/api/auth/user/activity/store', 
        // {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({"actionName":"SAVED_FILTER"})
        // })
  
        const qsStr = qs.stringify(parameters)
        dispatch(unselectAllProspects())
        push(`/html/score.html?${qsStr}`)
      }
    } 
    const handleSavedMessageFilter = (e) => {
      
      if ('SavedANDMessaged' !== qs.parse(search).tF) {

        setActiveTabFilter(2)
        setSelectAllProspectsFlag(false)
        
        const parameters = qs.parse(search)
        parameters['tF']= 'SavedANDMessaged'
        
  
        
        // manualApiCall('/api/auth/user/activity/store', 
        // {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json' },
        //   body: JSON.stringify({"actionName":"SAVED_FILTER"})
        // })
  
        const qsStr = qs.stringify(parameters)
        dispatch(unselectAllProspects())
        push(`/html/score.html?${qsStr}`)
      }
    } 

  return (
    <React.Fragment> 
    {['ConnectMessaged', 'FollowUp1st', 'FollowUp2nd'].includes(qs.parse(search).tF) ?
      <Nav className='tabsContainer' tabs>
          <NavItem 
              className='tabFilterPrimary' >
              <NavLink 
                // disabled = {viewState !== 'ScoreAndConnect'}
                className={`${ActiveTabFilter===1 ? 
                  "active ": ""}tabPrimaryLink firstTab`} 
                onClick={handleFirstMessageFilter}>
                  1st Message {totalFirstMsgd ?? 0}
              </NavLink>
              <span className='archBorderUpper'/>
              <span className='archBorderLower'/>
            </NavItem>
            <NavItem className='tabFilterPrimary'>
              <NavLink 
                className={`${ActiveTabFilter===2 ? 
                  "active ": ""}tabPrimaryLink secondTab`}
                onClick={handleSecondMessageFilter}>
                  2nd Message {totalSecondMsgd ?? 0}
              </NavLink>
              <span className='archBorderUpper2nd'/>
              <span className='archBorderLower2nd'/>
            </NavItem>
            <NavItem className='tabFilterPrimary'>
              <NavLink 
                className={`${ActiveTabFilter===3 ? 
                "active ": ""}tabPrimaryLink thirdTab`}
                onClick={handleThirdMessageFilter}>
                  3rd Message {totalThirdMsgd ?? 0}
              </NavLink>
              <span className='archBorderUpper3rd'/>
              <span className='archBorderLower3rd'/>
            </NavItem>
          {/* <NavItem className='tabFilterSecondary'>
            <NavLink 
              className={`${ActiveTabFilter===0 ? 
                "secondaryActive ": ""}tabSecondaryLink`} 
              onClick={handleDefaultMessageFilter}>
                All Messages {totalMsgd}
            </NavLink>
          </NavItem> */}
      </Nav>: 
      <Nav className='tabsContainer' tabs>
          <NavItem 
              className='tabFilterPrimary' >
              <NavLink 
                // disabled = {viewState !== 'ScoreAndConnect'}
                className={`${ActiveTabFilter===1 ? 
                  "active ": ""}tabPrimaryLink firstTab`} 
                onClick={handleSavedNotMessageFilter}>
                  Unmessaged {totalFirstMsgd ?? 0}
              </NavLink>
              <span className='archBorderUpper'/>
              <span className='archBorderLower'/>
            </NavItem>
            <NavItem className='tabFilterPrimary'>
              <NavLink 
                className={`${ActiveTabFilter===2 ? 
                  "active ": ""}tabPrimaryLink secondTab`}
                onClick={handleSavedMessageFilter}>
                  Messaged {totalSecondMsgd ?? 0}
              </NavLink>
              <span className='archBorderUpper2nd'/>
              <span className='archBorderLower2nd'/>
            </NavItem>
            
      </Nav>
      
      }
    </React.Fragment>
  )
}

export default withRouter(TabsSection)
