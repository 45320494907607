import Autosuggest from 'react-autosuggest'
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';
import '../detailedAnalytics.css'
const EmailAuto = ({  users,  getUserActivities, EmailTextBox,setEmailTextBox }) => {
    const [dropdownOpen, setOpen] = useState(false);
     const [userSuggestions, setUserSuggestions] = useState([])

    const [suggestions, setSuggestions] = useState([])
    const onUserSuggestionsClearRequested = () => setUserSuggestions([])
 
    const renderSuggestion = suggestion => (
        <div className="renderedSuggestionContainer"> {suggestion.email} </div>
    )
    const getUserSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : users.filter(user =>
            user.email.toLowerCase().slice(0, inputLength) === inputValue)
    }
    const onUserSuggestionsFetchRequested = ({ value }) => setSuggestions(getUserSuggestions(value))
  
    const getSuggestionValue = suggestion => suggestion.email

    const inputProps = {
        placeholder: `Search for Email Address`,
        value: EmailTextBox,
        onChange: (e, { newValue }) => {
            if(newValue==='') 
            getUserActivities('');
            setEmailTextBox(newValue)}
    }
    const toggle = () => setOpen(!dropdownOpen);

    const showValue=(e,props)=>{
        // getUserActivities(props.suggestionValue)
        const index = users.findIndex(user => user.email ===props.suggestionValue);
        getUserActivities(users[index].id)
     }
    useEffect(() => {
        // dispatch(fetchUsers())

    }, []);
    return (
        <div style={{ marginLeft: '50px' }}>
            <FormGroup>
                <Autosuggest
                    theme={{
                        input: 'adv-filter-ip-detailed-analytics-email',
                        suggestion: 'suggestion-item-detailed-analytics',
                        suggestionsContainer: 'autosuggest-suggestions-container-detailed-analytics'
                    }}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onUserSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onUserSuggestionsClearRequested}
                    onSuggestionSelected={showValue}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
            </FormGroup>
        </div>
    )
}
export default connect(state => ({
    users: state.users.users,
    usersPending: state.users.usersPending,
    isDataAvailable: state.users.isDataAvailable
}))(EmailAuto)