import {FETCH_JOBS, 
    FETCH_JOBS_SUCCESS, 
    FETCH_JOBS_FAILURE
} from '../actions/allJobs';

const initialState = {
    jobsPending: false,
    jobs: [],
    jobsError: null
}

export default function jobs(state = initialState, action) {
      switch(action.type) {
        case FETCH_JOBS: 
            return {
                ...state,
                jobsPending: true
            }
            
        case FETCH_JOBS_SUCCESS:
            return {
                ...state,
                jobsPending: false,
               jobs:action.payload.job
            }
        case FETCH_JOBS_FAILURE:
            return {
                ...state,
                jobsPending: false,
                jobsError: action.error
            }
           
        default: 
            return state;
    }
}
