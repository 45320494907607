import {
    FETCH_ANALYTICS,
    FETCH_ANALYTICS_SUCCESS,
    FETCH_ANALYTICS_FAILURE,
    BLOCK_USER,
    BLOCK_USER_SUCCESS,
    BLOCK_USER_FAILURE,
    FETCH_TEAM_RANK_ANALYTICS,
    FETCH_TEAM_RANK_ANALYTICS_SUCCESS,
    FETCH_TEAM_RANK_ANALYTICS_FAILURE
} from '../actions/detailedAnalytics';
import { omit } from 'lodash'
const initialState = {
    analyticsPending: false,
    analytics: [],
    analyticsError: null,
    isBlocking: false,
    isBlocked: false,
    isDataAvailable: false,
    currentPageNo: 1,
    totalDataLeft: 0,
    name:'',
    email:''
}

export default function detailedAnalytics(state = initialState, action) {
    switch (action.type) {
        case FETCH_ANALYTICS:
        case FETCH_TEAM_RANK_ANALYTICS:
        //     if(state.currentPageNo==1)
        // state.analytics=[]
            return {
                ...state,
                analyticsPending: true,
                isDataAvailable: false,
                totalDataLeft: 0,
                isBlocked: false
            }

        case FETCH_ANALYTICS_SUCCESS:
        case FETCH_TEAM_RANK_ANALYTICS_SUCCESS:
            if (action.payload.data.pageNo == 1) {
                state.totalDataLeft = 0
                state.analytics = []
            }

            return {
                ...state,
                name: action.payload.data.list.length > 0? action.payload.data.list[0].name:state.name,
                email:action.payload.data.list.length > 0? action.payload.data.list[0].email:state.email,
                analyticsPending: false,
                analytics: (parseInt(action.payload.data.pageNo, 10) === 1) || (action.payload.data.list.length > 100) ?
                    action.payload.data.list :
                    [...state.analytics, ...action.payload.data.list],
                ...omit(action.payload.data, 'list'),
                totalCount: action.payload.data.totalCount,
                currentpageNo:action.payload.data.pageNo,
                totalDataLeft: action.payload.data.totalCount - (state.analytics.length+action.payload.data.list.length),
                isDataAvailable: action.payload.data.list.length > 0,
                currentPageNo: action.payload.data.pageNo,
                isBlocked: false
            }
        case FETCH_ANALYTICS_FAILURE:
        case FETCH_TEAM_RANK_ANALYTICS_FAILURE:
            return {
                ...state,
                analyticsPending: false,
                analyticsError: action.error,
                isBlocked: false
            }
        case BLOCK_USER:
            return {
                ...state,
                analyticsPending: false,
                isBlocking: true,
                isBlocked: false
            }
        case BLOCK_USER_SUCCESS:
            return {
                ...state,
                analyticsPending: false,
                isBlocking: false,
                isBlocked: true
            }
        case BLOCK_USER_FAILURE:
            return {
                ...state,
                analyticsError: action.Error,
                analyticsPending: false,
                isBlocking: false,
                isBlocked: false
            }

            case BLOCK_USER:
                return {
                    ...state, 
                    isBlocking: true,
                    blocking: [...state.blocking, action.payload]
                }

            case BLOCK_USER_SUCCESS:
            case BLOCK_USER_FAILURE:
                    return {
                        ...state, 
                        isBlocking: false,
                        blocking: state.blocking.filter(item => item != action.payload)
                    }
        default:
            return state;
    }
}
