import Autosuggest from 'react-autosuggest'
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { fetchUsers } from '../../../actions/activityLogs';
import '../style.css'
const EmailAuto = ({  users,  getUserActivities, dispatch,EmailTextBox,setEmailTextBox }) => {
    const [dropdownOpen, setOpen] = useState(false);
    const [userSuggestions, setUserSuggestions] = useState([])

    const [suggestions, setSuggestions] = useState([])
    const onUserSuggestionsClearRequested = () => setUserSuggestions([])
    // const onActivitySuggestionSelected = (event, {
    //     suggestion,
    //     suggestionValue,
    //     suggestionIndex,
    //     sectionIndex,
    //     method
    // }) => {
    //     setFilterCount(FilterCount + 1)
    //     dispatch(setLocationFilters([{
    //         name: suggestionValue,
    //         value: 1
    //     },
    //     ...AdvFilters.location.filter(itm => itm.name !== suggestionValue)]))
    //     setLocationTextBox('')
    // }

    // const getSuggestionValue = suggestion => suggestion
    const renderSuggestion = suggestion => (
        <div className="renderedSuggestionContainer"> {suggestion.email} </div>
    )
    const getUserSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : users.filter(user =>
            user.email.toLowerCase().slice(0, inputLength) === inputValue)
    }
    const onUserSuggestionsFetchRequested = ({ value }) => setSuggestions(getUserSuggestions(value))
    // dispatch(setLocationFilters(locations.slice(0, 4).map(itm => ({ name: itm, value: 0 }))))
    const getSuggestionValue = suggestion => suggestion.email
    // const renderSuggestion = suggestion => (
    //     <div>
    //         {suggestion}
    //     </div>
    // )
    const inputProps = {
        placeholder: `Search for Email Address`,
        value: EmailTextBox,
        onChange: (e, { newValue }) => {
            if(newValue==='') 
            getUserActivities('');
            setEmailTextBox(newValue)}
    }
    const toggle = () => setOpen(!dropdownOpen);

    const showValue=(e,props)=>{
        // getUserActivities(props.suggestionValue)
        const index = users.findIndex(user => user.email ===props.suggestionValue);
        getUserActivities(users[index].id)
     }
    useEffect(() => {
        dispatch(fetchUsers())

    }, []);
    return (
        <div >
            <FormGroup>
                <Autosuggest
                    theme={{
                        input: 'ip-add-user-email',
                        suggestion: 'suggestion-item-add-user',
                        suggestionsContainer: 'autosuggest-suggestions-container-add-user'
                    }}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onUserSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onUserSuggestionsClearRequested}
                    onSuggestionSelected={showValue}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
            </FormGroup>
        </div>
    )
}
export default connect(state => ({
    users: state.users.users,
    usersPending: state.users.usersPending,
    isDataAvailable: state.users.isDataAvailable
}))(EmailAuto)