import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { Button, Label, Col, Row, Spinner } from 'reactstrap';
// import JobCard from './JobCard';
import { Helmet } from 'react-helmet';
// import { set } from 'lodash';
import { connect } from 'react-redux';
import { addUser, fetchUserTypes } from '../../actions/addUser';
import {  toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './style.css'

const AddSalesPerson = ({
    dispatch,
    userTypes,
    message,
    isLoading,
    // location: {
    //     pathname
    // },
    history:
    { push }
}) => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordMatch, setPasswordMatch] = useState(false)
    const [onsubmit, setOnSubmit] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [type, setType] = useState('8')
    const [key, setKey] = useState(0)

    toast.configure()
    const emailChange = e => {
        setEmail(e.target.value);
        setErrorMessage('')
    }
    const passwordChange = e => {
        setPassword(e.target.value);
        setErrorMessage('')
    }
    const userTypeChange = e => {
        setType(e.target.value);
    }
    const confirmPasswordChange = e => {
        setConfirmPassword(e.target.value);
        setErrorMessage('')
    }
    const firstNameChange = e => {
        setFirstName(e.target.value);
    }
    const lastNameChange = e => {
        setLastName(e.target.value);
    }

    const handleValidSubmit = (event, values) => {
        setKey(key + 1)
        setEmail(values.email);
        setFirstName(values.firstName);
        setLastName(values.lastName)
        setPassword(values.password)
        setConfirmPassword(values.confirmPassword)
        if (values.password === values.confirmPassword) {
            setPasswordMatch(true)
            setOnSubmit(true)
            setType(values.userType)
            // console.log(values)
            dispatch(addUser(values))
        }
        else {
            setErrorMessage('Passwords didn\'t match');
            setPasswordMatch(false)
        }

    }
    const handleInvalidSubmit = (event, values) => {
        setEmail(values.email);
        setFirstName(values.firstName)
        setLastName(values.LastName)
        setPassword(values.password)
        setConfirmPassword(values.confirmPassword)

    }

    useEffect(() => {
        if (localStorage.getItem('userType') === 'SALES-ADMIN'||localStorage.getItem('userType') === 'CUSTOMER-ADMIN') {
            push('/html/logs.html')
        }
        setKey(0)
        setErrorMessage('')
        dispatch(fetchUserTypes());
    }, []);

    useEffect(() => {
        setErrorMessage('')

        if (message === "Validation error") {
            setErrorMessage('Already exists!')
            setPasswordMatch(false)
            setOnSubmit(true)
        }

        else if (message === "User Created Successfully") {
          
            setEmail('')
            setFirstName('')
            setLastName('')
            setPassword('')
            setConfirmPassword('')
            setErrorMessage('')
            if (errorMessage == '' && key != 0) {
                toast.info('User has been added successfully!', { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 5000 })
                push('/html/logs.html')
            }
        }
    }, [message])

    return (
        <div >
            <Helmet>
                <title>DNNae Admin - Add Admin </title>
            </Helmet>

            <div className="add-sales-container">
                <Header />
                <div className="add-sales-container-form">

                    <h1>Add Admin</h1>

                    <AvForm onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit} key={String(key)}>
                        <Row>
                            <Col xs="12" sm="6">
                                <AvGroup className="form-fields">
                                    <Label for="firstName">First Name</Label>
                                    <AvField name="firstName"
                                        placeholder="Enter First Name"
                                        value={firstName}
                                        type="text"
                                        onChange={firstNameChange}
                                        required
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter first name' }
                                        }} />
                                </AvGroup>
                            </Col>
                            <Col xs="12" sm="6">
                                <AvGroup className="form-fields">
                                    <Label for="lastName" >Last Name</Label>
                                    <AvField name="lastName"
                                        placeholder="Enter Last Name"
                                        value={lastName}
                                        type="text"
                                        onChange={lastNameChange}
                                        required
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter last name' }
                                        }} />
                                </AvGroup>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs="12" sm="7">
                                <AvGroup >
                                    <Label for="email">Email</Label>
                                    <AvField name="email"
                                        placeholder="Enter Email Address"
                                        value={email}
                                        type="email"
                                        onChange={emailChange}
                                        required
                                        validate={{
                                            required: { value: true, errorMessage: 'Please enter email address' },
                                            email: { value: true, errorMessage: 'Invalid Email Format' }
                                        }} />
                                </AvGroup>
                            </Col>
                            <Col xs="12" sm="5">
                                <AvGroup >
                                    <Label for="userType">User Type</Label>
                                    <AvField
                                        type="select"
                                        name="userType"
                                        onChange={userTypeChange}
                                        required
                                        value={type}
                                       >
                                        {
                                            userTypes.map((type, index) =>
                                                <option key={index} value={type.id}> {type.name} </option>
                                            )
                                        }
                                    </AvField>
                                </AvGroup>
                            </Col>
                        </Row>
                        <br />
                        <AvGroup >
                            <Label for="password">Password</Label>
                            <AvField
                                name="password"
                                placeholder="Enter Your Password"
                                value={password}
                                type="password"
                                onChange={passwordChange}
                                required validate={{
                                    required: { value: true, errorMessage: 'Please enter your password' }
                                }} />
                        </AvGroup>
                        <br />
                        <AvGroup >
                            <Label for="confirmPassword"> Confirm Password</Label>
                            <AvField
                                name="confirmPassword"
                                placeholder="Please Confirm Your Password"
                                value={confirmPassword}
                                type="password"
                                onChange={confirmPasswordChange}
                                required validate={{
                                    required: { value: true, errorMessage: 'Please enter your password' }
                                }} />
                        </AvGroup>
                        <br />
                        {
                            // message === 'Validation error'
                            //     ?
                            //     <p className="sales-form-error">{errorMessage}</p>
                            //     :
                            passwordMatch && onsubmit ? <span /> : email&&<p className="sales-form-error">{errorMessage}</p>
                        }
                        <Button color="primary">Add</Button>
                    </AvForm>
                </div>

            </div>
            {isLoading
                &&
                <div className="spinner">
                    <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
                </div>
            }
            
        </div>
    );
};

export default connect(state => ({
    userTypes: state.userTypes.userTypes,
    message: state.addUser.message,
    isLoading: state.addUser.isLoading
}))(AddSalesPerson)