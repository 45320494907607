import * as ActionTypes from '../actions/auth'
const initialState = {
    isAuthenticated: localStorage.getItem('token') ? true : false,
    isLoading: false
}

const auth = (state=initialState, action) => {
    switch(action.type){
        case ActionTypes.LOGOUT:{
            localStorage.removeItem('token')
            localStorage.removeItem('message')
            localStorage.removeItem('userName')
            localStorage.removeItem('userType')
            return {...state, isAuthenticated: false}
        }

        case ActionTypes.REQUEST_LOGIN: {
            localStorage.removeItem('message')
            return {...state, isLoading: true}
        }

        case ActionTypes.REQUEST_LOGIN_SUCCESS:{
            console.log(action.payload.data)
            localStorage.setItem('token', action.payload.data.token)
            localStorage.setItem('userName', action.payload.data.name)
            localStorage.setItem('userType', action.payload.data.user_role)
            localStorage.setItem('userId', action.payload.data.id)
            localStorage.removeItem('message')
            
            return {
                ...state, 
                isLoading: false,
                isAuthenticated: true
            }        
        }

        case ActionTypes.REQUEST_LOGIN_FAILURE: {
            localStorage.setItem('message', action.payload.message)
           
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false
            }
        }

        default:
            return state
    }
}

export default auth