import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const ProtectedRoute = ({ auth, ...rest }) => {
    return (
      <>      
      {auth.isAuthenticated ? 
        <Route {...rest} /> :
        <Redirect to="/admin/login" />
      }
      </>
    )
  }
  export default connect(state => ({
    auth: state.auth
  }))(ProtectedRoute)