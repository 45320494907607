import {
    CALL_API
  } from '../middlewares/apiMiddleware'

export const FETCH_LOGS = 'FETCH_LOGS';
export const FETCH_LOGS_SUCCESS = 'FETCH_LOGS_SUCCESS';
export const FETCH_LOGS_FAILURE = 'FETCH_LOGS_FAILURE';

export const FETCH_ACTIVITY_TYPES = 'FETCH_ACTIVITY_TYPES';
export const FETCH_ACTIVITY_TYPES_SUCCESS = 'FETCH_ACTIVITY_TYPES_SUCCESS';
export const FETCH_ACTIVITY_TYPES_FAILURE = 'FETCH_ACTIVITY_TYPES_FAILURE';


export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const fetchActivityLogs = (pageNo,type,userId) => {  
    return {
      [CALL_API]: {
        types: [FETCH_LOGS, FETCH_LOGS_SUCCESS, FETCH_LOGS_FAILURE],
        endpoint: '/api/auth/admin/activity',
        options: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
                'Accept': 'application/json'
          },
          body: JSON.stringify({
            pageNo,
            type,
            userId
        }),
        }
      }
    }
  }

  export const fetchActivityTypes = () => {
    return {
      [CALL_API]: {
        types: [FETCH_ACTIVITY_TYPES, FETCH_ACTIVITY_TYPES_SUCCESS, FETCH_ACTIVITY_TYPES_FAILURE],
        endpoint: '/api/auth/admin/actionTypes',
        options: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
                'Accept': 'application/json'
          }
        }
      }
    }
  }
  export const fetchUsers = () => {
    return {
      [CALL_API]: {
        types: [FETCH_USERS, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE],
        endpoint: '/api/auth/admin/usersList',
        options: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
                'Accept': 'application/json'
          }
        }
      }
    }
  }