import {
    CALL_API
} from '../middlewares/apiMiddleware'
import { manualApiCall } from '../utils';

export const ADD_LEAD = 'ADD_LEAD';
export const ADD_LEAD_SUCCESS = 'ADD_LEAD_SUCCESS';
export const ADD_LEAD_FAILURE = 'ADD_LEAD_FAILURE';

export const FETCH_LEADS = 'FETCH_LEADS'
export const FETCH_LEADS_SUCCESS = 'FETCH_LEADS_SUCCESS'
export const FETCH_LEADS_FAILURE = 'FETCH_LEADS_FAILURE'

export const DELETE_LEAD = 'DELETE_LEAD'
export const DELETE_LEAD_SUCCESS = 'DELETE_LEAD_SUCCESS'
export const DELETE_LEAD_FAILURE = 'DELETE_LEAD_FAILURE'


export const deleteLead = (leadid) => async (dispatch) => {
try {
    dispatch({
        type: DELETE_LEAD,
        payload: leadid
    })

    await manualApiCall(`/api/auth/sales/leads/${leadid}`, {
        method: 'DELETE'
    })

    dispatch({type: DELETE_LEAD_SUCCESS, payload: leadid})

} catch (e){
    dispatch({type: DELETE_LEAD_FAILURE, payload: leadid})
}
}


// export const deleteLead = (leadId) => ({
//     [CALL_API]: {
//         types: [DELETE_LEAD, DELETE_LEAD_SUCCESS, DELETE_LEAD_FAILURE],
//         endpoint: `/api/auth/sales/leads/${leadId}`,
//         options: {
//             method: 'DELETE'
//         }
//     }
// })

export const fetchLeads = () => ({
    [CALL_API]: {
        types: [FETCH_LEADS, FETCH_LEADS_SUCCESS, FETCH_LEADS_FAILURE],
        endpoint: '/api/auth/sales/leads',
        options: {
            method: 'GET',
        }
    }
})

export const addLead = (email) => {
    return {
        [CALL_API]: {
            types: [ADD_LEAD, ADD_LEAD_SUCCESS, ADD_LEAD_FAILURE],
            endpoint: '/api/auth/sales/lead/add',
            options: {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email
                })
            }
        }
    }
}


    
    // export const addLead = (email) => async (dispatch) => {
    //     try {
    //         dispatch({
    //             type: ADD_LEAD,
    //             payload:email
               
    //         })
        
    //         await manualApiCall('/api/auth/sales/lead/add', {
    //           method: 'POST',
    //           headers: {
    //             'Content-Type': 'application/json',
    //                 'Accept': 'application/json'
    //           },
    //           body: JSON.stringify({
    //            email
                
    //         })
    //         })
        
    //         dispatch({type: ADD_LEAD_SUCCESS,payload:email})
        
    //     } catch (e){
    //         dispatch({type: ADD_LEAD_FAILURE,payload:email})
    //     }
    //     }
        