import {
    FETCH_GRAPH_DATA,
    FETCH_GRAPH_DATA_SUCCESS,
    FETCH_GRAPH_DATA_FAILURE
} from '../actions/graphData';

const initialState = {
    isLoading: false,
    data: [],
    error: null,
    isDataAvailable: false
}

export default function users(state = initialState, action) {

    switch (action.type) {
        case FETCH_GRAPH_DATA:
            return {
                ...state,
                isLoading: true,
                isDataAvailable: false,
            }
        case FETCH_GRAPH_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.data,
                isDataAvailable: action.payload.data.length > 0
            }
        case FETCH_GRAPH_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                isDataAvailable: false
            }

        default:
            return state;
    }
}


