import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
const Sort = ({ sortBy }) => {
    const [dropdownOpen, setOpen] = useState(false);
    const [dropDownValue, setDropDownValue] = useState("");
    const toggle = () => setOpen(!dropdownOpen);

    const changeSortValue = e => {
        setDropDownValue(e.currentTarget.textContent);
        sortBy(e.currentTarget.value);
    }
    return (
        <div className="for-analytics-sorting">
            Sort data by
            <span className="analytics-sorting-dropdown" />
            <ButtonDropdown size="sm" isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle outline caret style={{ fontSize: '12px' }}>
                    {dropDownValue === "" ? "Select" : dropDownValue}
                </DropdownToggle>
                <DropdownMenu className="for-sort-item">
                    <DropdownItem onClick={changeSortValue} value="totalScored">Scored</DropdownItem>
                    <DropdownItem onClick={changeSortValue} value="totalSent">Messaged</DropdownItem>
                    <DropdownItem onClick={changeSortValue} value="totalAccepted">Accepted</DropdownItem>
                    <DropdownItem onClick={changeSortValue} value="totalReplied">Replied</DropdownItem>
                    <DropdownItem onClick={changeSortValue} value="activityLog">Activity</DropdownItem>
                    <DropdownItem onClick={changeSortValue} value="totalDownload">Downloads</DropdownItem>
                    {localStorage.getItem('userType') != 'CUSTOMER-ADMIN' && <DropdownItem onClick={changeSortValue} value="createdAt">Sign Up At</DropdownItem>}
                </DropdownMenu>
            </ButtonDropdown>
        </div>
    )
}
export default Sort;