import React, { useState, useRef, memo } from "react";
import { onlyUpdateForKeys } from 'recompose'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'
import {
  selectProspect,
  unselectProspect,
  enhanceProfile
} from '../../actions/score'
import Loader from '../../components/Loader'
import { getToken } from '../../utils/index'
import { Container, Button, Card, CardBody, CardHeader, Row, Col, Progress, Input, Collapse, Tooltip } from 'reactstrap'
import moment from 'moment'

import './style.css'

import DropDownIcon from '../../../../img/dropdown.svg'

import DisplayPlaceholder from '../../../../img/displayPlaceholder.svg'
import SaveProspectGrey from '../../../../img/SaveProspectGrey.svg'
import SaveIconHover from '../../../../img/SaveIconHover.svg'
import UnsaveProspectBlue from '../../../../img/UnsaveProspectBlue.svg'

import DropDownSkills from "./DropDownSkills"

import LinkedIcon from '../../../../img/LinkedinIcon.svg'
import MoreDetails from "./MoreDetails";
import OldJobsSkillsComponent from "./OldJobsSkillsComponent";


import {
  saveProspects,
  unsaveProspects
} from '../../actions/score'

import { manualApiCall } from '../../utils'

const progressItems = [{
  id: 1,
  name: 'Current Title',
  tag: 'title'
}, {
  id: 2,
  name: 'Experience',
  tag: 'experience'
}, {
  id: 3,
  name: 'Education',
  tag: 'education'
}, {
  id: 4,
  name: 'Skills',
  tag: 'skill'
}, {
  id: 5,
  name: 'Industry',
  tag: 'industry'

},]

// const months = [{
//   id: 1,
//   name: 'Jan'
// },{
//   id: 2,
//   name: 'Feb'
// },{
//   id: 3,
//   name: 'Mar'
// },{
//   id: 4,
//   name: 'Apr'
// },{
//   id: 5,
//   name: 'May'
// },{
//   id: 6,
//   name: 'Jun'
// },{
//   id: 7,
//   name: 'Jul'
// },{
//   id: 8,
//   name: 'Aug'
// },{
//   id: 9,
//   name: 'Sep'
// },{
//   id: 10,
//   name: 'Oct'
// },{
//   id: 11,
//   name: 'Nov'
// },{
//   id: 12,
//   name: 'Dec'
// }
// ]



const ListItem = memo(({
  index,
  // SelectAllProspectsFlag,
  setSelectAllProspectsFlag,
  // moreDetailsPopUp,
  // setMoreDetailsPopUp,
  item,
  // selected,
  isSelected,
  selectedCount,
  isEnhancing,
  dispatch,
  totalRecord,
  setRefState,
  // refState,
  location: {
    search
  }
}) => {
  // console.log('RENDERING AGAIN: ', {
  //   id: item.prospectId,
  //   name: item.full_name
  // })
  // if (qs.parse(location.search).selectedProspects){
  //
  //   selected.push(...qs.parse(location.search).selectedProspects.split('-').map(str => Number(str)))
  // }

  const [seeMoreExp, setSeeMoreExp] = useState(false)
  const [DisplayPictureURL, setDisplayPictureURL] = useState(item.imageUrl)
  const [TooltipSaveOpen, setTooltipSaveOpen] = useState(false)
  const [TooltipUnsaveOpen, setTooltipUnsaveOpen] = useState(false)

  const [moreDetailsPopUp, setMoreDetailsPopUp] = useState(false)
  const SaveIconRef = useRef(null)
  const UnsaveIconRef = useRef(null)

  const NA = <a className="notAvailable">Not Available</a>

  let educationArr = []

  const totalExperiences = item.experience ?
    item.experience.map((itm, idx) => {
      const job = itm.title ? itm.title : ''


      const company = itm.company_name ? itm.company_name : ''


      // const start = itm.start ?  
      // months.filter(m => 
      //   m.id===parseInt(itm.start.slice(0,-5))).length == 1 ? 
      //     months.filter(m => 
      //       m.id===parseInt(itm.start.slice(0,-5)))[0]
      //         .name+' '+itm.start.slice(-2) : 
      //           itm.start.slice(-2)  : ''


      // const end = itm.end && itm.start ?
      //   months.filter(m => 
      //     m.id===parseInt(itm.end.slice(0,-5))).length == 1 ? 
      //       months.filter(m => 
      //         m.id===parseInt(itm.end.slice(0,-5)))[0]
      //           .name+' '+itm.end.slice(-2) : 
      //             itm.end.slice(-2)  :
      //               itm.start && idx===0 ? 'Now' : ''

      // const timeSpan = start && end && end != 'Now' ?
      //   moment(end, 'MMM YY').from(start, 'MMM YY') :
      //     start ? moment(start, 'MMM YY').fromNow().slice(0, -4) :''

      const start = itm.start ?
        moment(itm.start, 'MM-YYYY').format('MMM YY') == 'Invalid date' ?
          moment(itm.start, 'YYYY').format('YY') == 'Invalid date' ? itm.start :
            moment(itm.start, 'YYYY').format('YY') :
          moment(itm.start, 'MM-YYYY').format('MMM YY') : ''

      const end = itm.end ?
        moment(itm.end, 'MM-YYYY').format('MMM YY') == 'Invalid date' ?
          moment(itm.end, 'YYYY').format('YY') == 'Invalid date' ? itm.end :
            moment(itm.end, 'YYYY').format('YY') :
          moment(itm.end, 'MM-YYYY').format('MMM YY') : itm.start ? 'Now' : ''

      const diff = end === '' && start === '' ? '' : end === 'Now' ?
        moment(itm.start, 'MM-YYYY').fromNow(true) == 'Invalid date' ?
          moment(itm.start.slice(-4), 'YYYY').fromNow(true) == 'Invalid date' ? '' :
            moment(itm.start.slice(-4), 'YYYY').fromNow(true) :
          moment(itm.start, 'MM-YYYY').fromNow(true) : start === end ? '1 month' :
          moment(itm.start, 'MM-YYYY').from(moment(itm.end, 'MM-YYYY'), true) == 'Invalid date' ?
            moment(itm.start.slice(-4), 'YYYY').from(moment(itm.end.slice(-4), 'YYYY'), true) == 'Invalid date' ? '' :
              moment(itm.start.slice(-4), 'YYYY').from(moment(itm.end.slice(-4), 'YYYY'), true) :
            moment(itm.start, 'MM-YYYY').from(moment(itm.end, 'MM-YYYY'), true)

      let diffMonths = false
      // if(itm.start.includes('-') && diff.includes('year')){
      //   if(end==='Now'){
      //     const pastMonth= new Date(start).getMonth() + 1
      //     // diffMonths = Math.abs(nowMonth-pastMonth) + 1
      //     diffMonths = moment(pastMonth, 'MM').fromNow(true).includes('day') ? 
      //       '1 month' : moment(pastMonth, 'MM').fromNow(true).includes('year') ? 
      //       false : moment(pastMonth, 'MM').fromNow(true).includes('a month') ? 
      //       '1 month' : moment(pastMonth, 'MM').fromNow(true)==='Invalid date' ? 
      //       false :  moment(pastMonth, 'MM').fromNow(true)
      //   }
      //   else if(itm.end.includes('-')){
      //     const nowMonth = new Date(end).getMonth() + 1
      //     const pastMonth= new Date(start).getMonth() + 1
      //     // diffMonths = Math.abs(nowMonth-pastMonth) + 1
      //     diffMonths = moment(pastMonth, 'MM').from(moment(nowMonth, 'MM'),true).includes('day') ? 
      //     '1 month' : moment(pastMonth, 'MM').from(moment(nowMonth, 'MM'),true).includes('year') ? 
      //     false : moment(pastMonth, 'MM').from(moment(nowMonth, 'MM'),true).includes('a month') ? 
      //     '1 month' : moment(pastMonth, 'MM').from(moment(nowMonth, 'MM'),true)==='Invalid date' ? 
      //     false :  moment(pastMonth, 'MM').from(moment(nowMonth, 'MM'),true)
      //   }
      // }


      return { date: start && end && `${start} - ${end}`, job: `${job}${company === '' ? '' : ` @ ${company}`}`, timeSpan: `${diff[0] === 'a' ? '1' + diff.slice(1) : diff} ${diffMonths ? diffMonths : ''}` }
    }) : []


  let ethnicity = null
  let maxEthnicity = 0.0

  let totalExperience = null
  let relevantExperienceTime = []
  let relevantExperiences = []

  if (item?.scoring?.experience_found) {
    item?.scoring?.experience_found.forEach((exp, i) => {
        if (exp.includes('prospect has worked')) {
          relevantExperienceTime.push(exp
            .split(' ')
            .slice(exp.split(' ').lastIndexOf('for') + 1)
            .join(' '))
          relevantExperiences.push(exp
            .split(' ')
            .slice(3)
            .slice(0, exp
              .split(' ')
              .slice(3)
              .lastIndexOf('for'))
            .join(' ')
          )
        }
        else if (exp.includes('of total experience')) {
          totalExperience = exp
            .split(' ')
            .slice(0, 2)
            .join(' ')
        }
        else if (exp.includes('The prospect has previously worked')) {
          return null
        }
        else if (i + 1 === item?.scoring?.experience_found?.length) {
          totalExperience = exp
        }
        else {
          relevantExperienceTime.push(exp
            .split(' ')
            .slice(exp.split(' ').lastIndexOf('for') + 1)
            .join(' '))
          relevantExperiences.push(exp
            .split(' ')
            .slice(0, exp.split(' ').lastIndexOf('for'))
            .join(' ')
          )
        }
      })
  }

  // useEffect(() => {
  //     setSelectAllProspectsFlag(totalRecord === selected.length)
  // }, [selected.length])

  const handleChangeCheckbox = (id) => {
    // dispatch(selected.includes(id) ? unselectProspect(id) : selectProspect(id))
    dispatch(isSelected ? unselectProspect(id) : selectProspect(id))


    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "TOGGLE_SELECT_PROSPECTS" })
    //   })

    // console.log("==========================================[69]")
    // console.log(isSelected)  
    // console.log(selectedCount)  
    // console.log(totalRecord)  

    if (!isSelected && selectedCount + 1 === totalRecord)
      setSelectAllProspectsFlag(true)

    else
      setSelectAllProspectsFlag(false)
  }

  const handleClickEnhanceProfile = (prospect) => {
    const params = qs.parse(search)


    // manualApiCall('/api/auth/user/activity/store',
    //   {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "actionName": "ENHANCE_PROFILE" })
    //   })


    getToken()
      .then(token => {
        dispatch(enhanceProfile({
          publicIdentifier: prospect.publicIdentifier,
          id: prospect.id,
          jobId: params.jId,
          token: token,
          profileUrl: prospect.profileUrl
        }))
      })

    setRefState(item.id)

  }

  const handleSaveProspect = (e, pId) => {

    setTooltipSaveOpen(false)
    
      

    // manualApiCall('/api/auth/user/activity/store',
    // {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ "actionName": "SAVE_PROSPECTS" })
    // })


    const params = qs.parse(search)
    dispatch(saveProspects({ jobId: params.jId, prospects: [pId] }))

  }
  const handleUnsaveProspect = (e, pId) => {

    setTooltipUnsaveOpen(false)    

    // manualApiCall('/api/auth/user/activity/store',
    // {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ "actionName": "UNSAVE_PROSPECTS" })
    // })

    const params = qs.parse(search)
    dispatch(unsaveProspects({ jobId: params.jId, prospects: [pId] }))

  }

  educationArr = item.education ? item.education.map(({ end, degreeName, schoolName }) => {
    return {
      date: degreeName || schoolName ? end ? end : '' : '',
      degree: `${!degreeName && !schoolName ? '' :
        `${degreeName ? degreeName : ''} ${degreeName && schoolName ?
          'from' : ''} ${schoolName ? schoolName : ''}`}`
    }
  }) : []



  if (item.ethnicity) {
    for (const [key, value] of Object.entries(item.ethnicity)) {
      if (parseFloat(value) > maxEthnicity) {
        maxEthnicity = parseFloat(value);
        ethnicity = key;
      }
    }
  }



  const Education = (props) => {
    const eduDateFlag = props.educationArr.length > 0 ? props.educationArr.every(({ date }) => !date) : false
    return (props.educationArr.length > 0 ?
      props.educationArr.map(({ date, degree }, i) =>
        <div key={i}>
          <div className='expContainer'>
            {eduDateFlag ? ''
              : date ?
                <p className='exp1'>
                  {date}
                </p> : <p className='exp1'>{''}</p>}
            {degree ?
              <p key={i} className="exp2">
                {degree}
              </p> : NA}
          </div>
          {i < props.educationArr.length - 1 && <hr className="bar" />}
        </ div>) : NA)
  }

  const findClosedCompany = (exp) => {
    const arr = exp
      .trim()
      .split(' ')
      .map(itm => itm === 'at' ? '@' : itm)
    if (arr[arr.length - 1] === '@')
      return [...arr].slice(0, -1).join(' ')
    return arr.join(' ')
  }

  const getRelevantTime = time => {
    return `${time}`
  }

  const RelevantExperience = (props) => {

    return (props.relevantExperiences.length > 0 ?
      props.relevantExperiences.map((exp, i) => (
        <div key={i} style={{ width: '100%' }}>
          <div className='expContainer'>
            {props.relevantExperienceTime[i] ?
              <p className='exp1'>
                {getRelevantTime(props.relevantExperienceTime[i])}
              </p> : ''}
            {exp ? <p className='exp2'>{findClosedCompany(exp)}</p> : NA}
          </div>
          {i < props.relevantExperiences.length - 1 && <hr className="bar" />}
        </div>)) : NA)
  }

  const TotalExperience = (props) =>
    props.totalExperiences.length > 0 ?
      props.totalExperiences.length < 4 ?
        props.totalExperiences.map(({ date, job, timeSpan }, i) =>
          <div key={`a${i}`} style={{ width: '100%' }}>
            <div className='expContainer'>
              <p className='exp1'>
                {date}
              </p>
              <p className='exp2'>
                {job}
                <span className='verBar' />
                <a className='timeSpan'>{timeSpan}</a>
              </p>
            </div>
            {i < props.totalExperiences.length - 1 && <hr className="bar" />}
          </div>) :
        <React.Fragment>
          {props.totalExperiences.slice(0, 3).map(({ date, job, timeSpan }, i) =>
            <div key={`a${i}`} style={{ width: '100%' }}>
              <div className='expContainer'>
                <p className='exp1'>
                  {date}
                </p>
                <p className='exp2'>
                  {job}
                  <span className='verBar' />
                  <a className='timeSpan'>{timeSpan}</a>
                </p>
              </div>
              {<hr className="bar" />}
            </div>
          )}
        </React.Fragment> : NA


  const getHeadline = (headline) => headline
    .trim()
    .split(' ')
    .map(itm => itm === 'at' ? '@' : itm)
    .join(' ')


  return (
    <div className="sectionlist" key={item.id} id={item.id}>
      <ul>
        <li >
          <Input
            type="checkbox"
            name="prospect"
            className="checkbox"
            onChange={() => handleChangeCheckbox(item.id)}
            checked={isSelected}
          />
          <div className='indexContainer'>
            <p className='notAvailable'>
              {index + 1}
            </p>
          </div>
          {item.scoringType !== "DEEP" ?
            <React.Fragment>
              <Card className="scorecard-container">
                <CardHeader
                  className="cardHeader"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <img
                      className="enhanceDisplayPicture"
                      src={DisplayPictureURL ? DisplayPictureURL : DisplayPlaceholder}
                      onError={() => setDisplayPictureURL(DisplayPlaceholder)} />
                    <h2 className='displayName'>{item.fullName}</h2>
                    <a
                      href={item.profileUrl}
                      target='_blank' >
                      <img
                        className='linkedIcon'
                        src={LinkedIcon}
                        alt="Linkedin Profile" /></a>
                    <p className='connectionDegree'>
                      {item.connection_degree ? item.connection_degree : '3rd'}
                    </p>
                  </div>
                  {/* <Button
                    disabled={isEnhancing}
                    outline
                    color='primary'
                    style={{ width: '145px' }}
                    onClick={() => handleClickEnhanceProfile(item)}
                  >
                    {isEnhancing ? <Loader color={'#007bff'} /> : 'Enhance Profile'}
                  </Button> */}
                </CardHeader>
                <CardBody>
                  <Container className="scoreContainer" style={{ display: 'block' }} >
                    <Row className="odd">
                      <Col className="values">
                        <div className="attributes" xs="3" lg="3" md="3" >
                          <h6 className="boldAttributes">
                            Current Title
                          </h6>
                        </div>
                        {item.headline ? <p className="info">{item.headline}</p> : NA}
                      </Col>
                    </Row>
                    <Row className="even">
                      <Col className="values">
                        <div className="attributes" xs="3" lg="3" md="3">
                          <h6 className="boldAttributes">
                            Location
                          </h6>
                        </div>
                        {item.locationName ? <p className="info">{item.locationName}</p> : NA}
                      </Col>
                    </Row>
                  </Container>
                </ CardBody>
              </ Card>
            </React.Fragment> :
            <React.Fragment>
              <Card className="scorecard-container">
                <CardBody>
                  <Container className="scoreContainer">
                    <span className="sidebar">
                      <div className='displayProspectContainer'>
                      {qs.parse(search).tF!=='Replied' &&
                        <React.Fragment>
                          {item.saved ? 
                          <React.Fragment>
                            {/* <Button 
                              id="unsaveIcnBtn"
                              outline color="secondary"
                              onClick={(e) => handleUnsaveProspect(e, item.id)}
                              onMouseEnter={() => {
                                UnsaveIconRef.current.src=SaveIconHover
                                setTooltipUnsaveOpen(true)}}
                              onMouseOut={() => {
                                UnsaveIconRef.current.src=UnsaveProspectBlue
                                setTooltipUnsaveOpen(false)}}
                              className='bookmarkProspectIconButton'>
                              <img 
                                ref = {UnsaveIconRef} 
                                src={UnsaveProspectBlue} 
                                className="bookmarkProspectIcon" />
                            </Button>
                            <Tooltip
                              target='unsaveIcnBtn'
                              placement='bottom'
                              isOpen={TooltipUnsaveOpen}
                              // fade={true}
                              style={{ zIndex: 9 }}
                              className='tooltip-root'>
                              Unsave this prospect
                          </Tooltip> */}
                          </React.Fragment>:
                          <React.Fragment>
                            {/* <Button 
                              id="saveIcnBtn"
                              outline color="secondary"
                              onClick={(e) => handleSaveProspect(e, item.id)}
                              onMouseEnter={() => {
                                SaveIconRef.current.src=SaveIconHover
                                setTooltipSaveOpen(true)}}
                              onMouseOut={() => {
                                SaveIconRef.current.src=SaveProspectGrey
                                setTooltipSaveOpen(false)}}
                              className='bookmarkProspectIconButton'>
                              <img 
                                ref = {SaveIconRef} 
                                src={SaveProspectGrey} 
                                className="bookmarkProspectIcon" />
                            </Button>
                            <Tooltip
                              target='saveIcnBtn'
                              placement='bottom'
                              isOpen={TooltipSaveOpen}
                              // fade={true}
                              style={{ zIndex: 9 }}
                              className='tooltip-root'>
                              Save this prospect
                            </Tooltip> */}
                          </React.Fragment>}
                        </React.Fragment>
                        }
                        <img
                          className="displayPicture"
                          src={DisplayPictureURL ? DisplayPictureURL : DisplayPlaceholder}
                          onError={() => setDisplayPictureURL(DisplayPlaceholder)} />
                        <div className='displayNameContainer'>
                          <h2 className='displayName' >
                            {item.fullName}
                          </h2>
                          <a
                            href={item.profileUrl}
                            target='_blank' >
                            <img
                              className='linkedIcon'
                              src={LinkedIcon}
                              alt="Linkedin Profile" />
                          </a>
                          <p className='connectionDegree'>
                            {item.connection_degree ? item.connection_degree : '3rd'}
                          </p>
                        </div>
                        {item.headline ? <p className="info">{getHeadline(item?.headline)}</p> : NA}
                        {item?.experience && item?.experience[0] && item?.experience[0]?.location ? <p className="info locationInfo">{item.experience[0].location}</p> : ''}
                      </div>

                      {progressItems.map(it => {
                        const progress = ((item?.scoring && item?.scoring[`${it.tag}_score`] || 0) /
                          (item?.scoring && item?.scoring[`${it.tag}_total`] || 1)) * 100
                        return (
                          <div key={it.id} className="progressContainer">
                            <p className="progressTitles">
                              {it.name}
                            </p>
                            {item?.scoring && item?.scoring[`${it.tag}_total`] == 0 ?
                              <p className="notSpecified">
                                Not Specified
                      </p> :
                              <Progress value={progress} />}
                          </ div>)
                      })}

                    </span>
                    <span className="scoreTable">
                      <Row className="even">
                        <Col className="values" id='expValues'>
                          <div className="attributes">
                            <h6 className="boldAttributes">Experience</h6>
                          </div>
                          <TotalExperience
                            totalExperiences={totalExperiences} />
                          {totalExperiences.length > 3 &&
                            <React.Fragment>
                              <Collapse
                                isOpen={seeMoreExp}>
                                <Card id="collapse-card" className="dropcard">
                                  <CardBody className="dropcardbody">
                                    {totalExperiences.slice(3).map(({ date, job, timeSpan }, i) =>
                                      <div key={`b${i}`} style={{ width: '100%' }}>
                                        <div className='expContainer'>
                                          <p className='exp1'>
                                            {date}
                                          </p>
                                          <p className='exp2'>
                                            {job}
                                            <span className='verBar' />
                                            <a className='timeSpan'>{timeSpan}</a>
                                          </p>
                                        </div>
                                        {<hr className="bar" />}
                                      </div>)}
                                  </CardBody>
                                </Card>
                              </Collapse>
                              {/* <hr className="bar" /> */}
                              <Button
                                className="toggler"
                                outline
                                color="secondary"
                                id="togglerEXP"
                                style={{ border: "0px" }}
                                onClick={() => setSeeMoreExp(!seeMoreExp)}
                                aria-controls="collapse-card"
                                aria-expanded={seeMoreExp}>
                                <div className="greyed">
                                  {`See ${totalExperiences.length - 3} ${seeMoreExp ? 'less' : 'more'} experiences    `}
                                </div>
                                <img
                                  src={DropDownIcon}
                                  alt="drop down"
                                  style={{ transform: seeMoreExp ? "rotate(180deg)" : "rotate(0deg)" }} />
                              </Button>
                            </React.Fragment>
                          }
                        </Col>
                      </Row>
                      <Row className="odd">
                        <Col className="values" id='expValues'>
                          <div className="attributes" xs="3" lg="3" md="3">
                            <h6 className="boldAttributes">Relevant Experience</h6>
                            <p
                              className={item?.scoring?.qualification_status ?
                                item?.scoring?.qualification_status === "Under Qualified" ?
                                  "RelevantExpQualificationTagUnder" : "RelevantExpQualificationTagOver" : ''}
                            >
                              {item?.scoring?.qualification_status ?
                                item?.scoring?.qualification_status === "Under Qualified" ?
                                  "Underqualified" : "Overqualified" : ''}
                            </p>
                          </div>
                          <RelevantExperience
                            relevantExperienceTime={relevantExperienceTime}
                            relevantExperiences={relevantExperiences} />
                        </Col>
                      </Row>
                      <Row className="even">
                        <Col className="values" id='expValues'>
                          <div id="expDiv" className="attributes" xs="3" lg="3" md="3">
                            <h6 className="boldAttributes">Total Experience</h6>
                          </div>
                          <p className="info">{totalExperience ? totalExperience : NA}</p>
                        </Col>
                      </Row>
                      <Row className="odd">
                        <Col className="values">
                          <div className="attributes" xs="3" lg="3" md="3">
                            <h6 className="boldAttributes">Education</h6>
                          </div>
                          <Education educationArr={educationArr} NA={NA} />
                        </Col>
                      </Row>
                      <Row className="even">
                        <Col className="values">
                          <div className="attributes" xs="3" lg="3" md="3">
                            <h6 className="boldAttributes">Skills</h6>
                          </div>
                          {item?.scoring?.skill_importance ?
                            <DropDownSkills importance={item?.scoring?.skill_importance} skills_required={item?.scoring?.skills_required} /> : 
                            <OldJobsSkillsComponent skills_required={item?.scoring?.skills_required} skills_found={item?.scoring?.skills_profile} />
                          }
                        </Col>
                      </Row>
                      <Row className="even">
                        <Col className="valuesVisa">
                          <div className="attributes" xs="3" lg="3" md="3">
                            <h6 className="boldAttributes">Visa Status</h6>
                          </div>
                          {item.visa_status ? item.visa_status !== 'None' ?
                            <p className="info">{item?.visa_status}</p> : NA : NA}
                        </Col>
                        <Col className="values">
                          <div className="attributes" xs="3" lg="3" md="3">
                            <h6 className="boldAttributes">Demographics</h6>
                          </div>
                          {item.gender || item.age || ethnicity ?
                            <>
                              <p className="Info">
                                {item.gender ? parseFloat(item.gender.male) > parseFloat(item.gender.female) ? 'Male' : 'Female' : item.age ?
                                  `${item.age}` : ethnicity ?
                                    `${ethnicity}` : <></>}
                                {item.gender && item.age ?
                                  `, ${item.age}` : ethnicity ?
                                    `${ethnicity}` : <></>}
                                {item.gender && item.age && ethnicity ? `, ${ethnicity}` : <></>}
                              </p>
                            </> : NA}
                        </Col>
                      </Row>
                      <Row id="detailsContainer" className="odd">
                        <MoreDetails
                          moreDetailsPopUp={moreDetailsPopUp}
                          setMoreDetailsPopUp={setMoreDetailsPopUp}
                          data={item} />
                      </Row>
                    </span>
                  </Container>
                </CardBody>
              </Card>
            </ React.Fragment >}
        </li>
      </ul>
    </div >
  )
})

export default withRouter(onlyUpdateForKeys([
  'item',
  'isSelected',
  'isEnhancing',
  'location',
  // 'moreDetailsPopUp',
])(ListItem))


// index,
//   // SelectAllProspectsFlag,
//   setSelectAllProspectsFlag,
//   moreDetailsPopUp,
//   setMoreDetailsPopUp,
//   item,
//   // selected,
//   isSelected,
//   selectedCount,
//   isEnhancing,
//   dispatch,
//   totalRecord,
//   setRefState,
//   // refState,
//   location: {
//     search
//   }