import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { Button, Label, Table, Container, Spinner } from 'reactstrap';
// import JobCard from './JobCard';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
// import { set } from 'lodash';
import { connect } from 'react-redux';
import usePrevious from '../../customHooks/usePrevious'
import { addOrganization, fetchOrganizations } from '../../actions/addOrganization';
import Loader from '../../components/Loader'
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment'
import 'react-toastify/dist/ReactToastify.css';
import './style.css'
// import { set, values } from 'lodash';

const AddOrganizations = ({
    dispatch,
    message,
    // location: {
    //     pathname
    // },
    organizations,
    isLoadingOrganizations,
    history: { push },

}) => {
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [key, setKey] = useState(1)

    // const isAddingPrev = usePrevious(isAdding)


    
  let To = moment().format("YYYY-MM-DD");
  let From = moment(To).subtract(6, 'days').format('YYYY-MM-DD');
    toast.configure()
    const nameChange = e => {
        setName(e.target.value);
        setError('')
    }

    useEffect(() => {
        if (localStorage.getItem('userType') === 'SALES-ADMIN' || localStorage.getItem('userType') === 'CUSTOMER-ADMIN') {
            push('/html/logs.html')
        }
        dispatch(fetchOrganizations())
    }, [])

    const handleValidSubmit = (e, values) => {
        setName(values.name);
        dispatch(addOrganization(values.name))
    }
    useEffect(() => {
        setName('')
        setError('')
    }, [organizations])

    const handleInvalidSubmit = (event, values) => {
        setName(values.name);
    }

    useEffect(() => {
        setError('')
        if (message === "Validation error")
            setError('Already Exists!')
        else if (message === "Organization Created Successfully") {
            setKey(key + 1)
            toast.info('Organization Added successfully!', { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 5000 })
            dispatch(fetchOrganizations())
        }
    }, [message])

    return (
        <div >
            <Helmet>
                <title>DNNae Admin - Add Organization </title>
            </Helmet>
            <div className="add-candidate-container">
                <Header />
                <div className="add-candidate-container-form">

                    <h1>Add Organization</h1>

                    <AvForm onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit} key={String(key)} >
                        <AvGroup className="form-fields">
                            <Label for="name">Name</Label>
                            <AvField name="name"
                                placeholder="Enter Organization Name"
                                value={name}
                                type="text"
                                onChange={nameChange}
                                required

                                validate={{
                                    required: { value: true, errorMessage: 'Please enter the name' }
                                }} />
                        </AvGroup>

                        <Label className="invalid-login-error"> {!isLoadingOrganizations && error && "Already Exists!"}</Label>
                        <br />
                        <Button color="primary" type="submit">Add</Button>
                    </AvForm>
                </div>

                <Container style={{ marginTop: '40px', maxHeight: '450px', maxWidth: '800px', overflow: 'auto' }}>
                    {organizations && organizations.length > 0 ?
                        <div>
                            <Table striped size='sm'  >
                                <thead>
                                    <tr>
                                        <th >#</th>
                                        <th >Name</th>
                                        <th >Total Users</th>
                                        <th style={{ textAlign: 'right' }}>Created On</th>
                                        <th >View</th>
                                        {/* <th style={{ textAlign: 'right' }}>Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        organizations.map((org, index) => (
                                            <tr key={index}>
                                                <th style={{ width: '100px', wordBreak: 'break-word' }} scope="row">{org.id}</th>
                                                <td style={{ width: '50%', wordBreak: 'break-word' }}>{org.name}</td>
                                                <td style={{ width: '120px', fontSize: '15px', textAlign: "right" }}>{org.totalUsers}</td>
                                                <td style={{ width: '180px', fontSize: '15px' }}>
                                                    <span className="analytics-column-data-flow">{org.createdAt.slice(0, 10)}</span>
                                                    <br />
                                                    <span className="analytics-column-data-flow">{org.createdAt.slice(11, 19)}</span>
                                                </td>
                                                <td style={{ textAlign: 'right' }}>
                                                <Link to={{
                                                    pathname: '/html/users.html',
                                                    search: '?organization_id='+org.id+'&organization_name='+org.name+''
                                                }}
                                                    target="_blank">Users</Link>
                                                    <br/>
                                                    <Link to={{
                                                    pathname: '/html/organization-graph.html',
                                                    search: `?organization_id=${org.id}&from=${From}&to=${To}`
                                                }}
                                                    target="_blank"
                                                    >Graph</Link>
                                                    
                                                    </td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                                {/* {shouldRenderWaypoint &&
                                 <Waypoint color="primary"
                                     fireOnRapidScroll={true}
                                     onEnter={handleWaypointOnEnter}
                                 />
                             } */}
                              {isLoadingOrganizations &&
                                <div className="spinner">
                                    <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
                                </div>
                            }
                            </Table>

                        </div>
                        :
                        !isLoadingOrganizations &&
                        <p className="spinner" style={{ marginTop: '200px' }}>No Organizations</p>
                    }
                </Container>
            </div>
        </div>
    );
};

export default connect(state => ({
    message: state.organizations.message,
    // users: state.users.users,s
    organizations: state.organizations.organizations,
    isLoadingOrganizations: state.organizations.isLoadingOrganizations
}))(AddOrganizations)
