import Autosuggest from 'react-autosuggest'
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { fetchUsers } from '../../actions/activityLogs';
const UsersAuto = ({  users,  getUserActivities, dispatch }) => {
    const [dropdownOpen, setOpen] = useState(false);
   
    const [UserTextBox, setUserTextBox] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const onUserSuggestionsClearRequested = () => setSuggestions([])
  
    const renderSuggestion = suggestion => (
        <div className="renderedSuggestionContainer"> {suggestion.name} </div>
    )
    const getUserSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : users.filter(user =>
            user.name.toLowerCase().slice(0, inputLength) === inputValue)
    }
    const onUserSuggestionsFetchRequested = ({ value }) => setSuggestions(getUserSuggestions(value))
   
    const getSuggestionValue = suggestion => suggestion.name

    const inputProps = {
        placeholder: `Search for User`,
        value: UserTextBox,
        onChange: (e, { newValue }) => {
            if(newValue==='') 
            getUserActivities('');
            setUserTextBox(newValue)}
    }
    const toggle = () => setOpen(!dropdownOpen);

    const showValue=(e,props)=>{
        // getUserActivities(props.suggestionValue)
        
        const index = users.findIndex(user => user.id ===props.suggestion.id);
        getUserActivities(users[index].id)
     }
    useEffect(() => {
        dispatch(fetchUsers())

    }, []);
    return (
        <div style={{ marginLeft: '180px' }}>
            <FormGroup>
                <Autosuggest
                    theme={{
                        input: 'adv-filter-ip',
                        suggestion: 'suggestion-item',
                        suggestionsContainer: 'autosuggest-suggestions-container'
                    }}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onUserSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onUserSuggestionsClearRequested}
                    onSuggestionSelected={showValue}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
            </FormGroup>
        </div>
    )
}
export default connect(state => ({
    users: state.users.users,
    usersPending: state.users.usersPending,
    isDataAvailable: state.users.isDataAvailable
}))(UsersAuto)