import React, { useState, useEffect, useRef } from "react";
import { Container, Card, Button, Tooltip, Collapse } from 'reactstrap'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import qs from 'query-string'

import { manualApiCall } from '../../utils'
import Header from "../../components/Header"
import JdSteps from "../../components/JdSteps"
import Loader from "../../components/Loader"
// import '../JobDescription/JobDescription.css'
import './style.css'
import usePrevious from '../../customHooks/usePrevious'

import {
  fetchProspects,
  unselectAllProspects,
  setConnectLimit,
  initializeConnectSent,
  fetchAdvancedFilter,
  fetchPencilitAccount,
  clearAdvancedFilters
} from '../../actions/score'

import FilterSection from './FilterSection'
import AdvanceFilterSection from './AdvanceFilterSection'
import ListSection from './ListSection'

// import ComposeMessage from './views/ComposeMessage'
// import RequestMeeting from './views/RequestMeeting'
import BlankSlate from "../../components/BlankSlate";
import TabsSection from "./TabsSection";

import DropDownIcon from '../../../../img/vector.svg'
import MainTabsIcon from '../../../../img/greyCloseMenu.svg'
import HoverTabsIcon from '../../../../img/blueCloseMenu.svg'
import ScoringFilterSection from "./ScoringFilterSection";
// import usePrevious from '../../customHooks/usePrevious'

const dailyLimitSelectOptions = [{
  id: 1,
  value: 100
}, {
  id: 2,
  value: 250
}, {
  id: 3,
  value: 500
}, {
  id: 4,
  value: 5
}]

const ScoreAndConnect2 = (props) => {
  const [pencilitAccount, setpencilitAccount] = useState(false)
  const [VisaStatus, setVisaStatus] = useState(2)
  const [schoolsFilterList, setSchoolsFilterList] = useState([])
  const [companyFilterList, setCompanyFilterList] = useState([])
  const [skillsFilterList, setSkillsFilterList] = useState([])
  const [industryFilterList, setIndustryFilterList] = useState([])
  const [titleFilterList, setTitleFilterList] = useState([])
  const [activeTab, setActiveTab] = useState('Home');
  const [Visa, setVisa] = useState([{
    id: 'H1b',
    value: false
  },
  {
    id: 'Green Card',
    value: false
  },
  {
    id: 'Citizen',
    value: false
  },
  {
    id: 'OPT',
    value: false
  }])

  const [messageLimitTooltip, setMessageLimitTooltip] = useState(false)

  const [Show, setShow] = useState(false)
  const [ScoringFilterShow, setScoringFilterShow] = useState(false)
  const [moreDetailsPopUp, setMoreDetailsPopUp] = useState(false)

  const [viewState, setView] = useState('ScoreAndConnect')
  const [preSelected, setPreSelected] = useState(false)

  const [SelectAllProspectsFlag, setSelectAllProspectsFlag] = useState(false)

  const [refState, setRefState] = useState(false)
  const [RenderStepperState, setRenderStepperState] = useState(false)

  const [newJob, setNewJob] = useState(false)

  const [MainTabs, setMainTabs] = useState(true)
  const MainTabsRef = useRef(null)

  const [MainAddedTabs, setMainAddedTabs] = useState(true)
  const [MainMessagededTabs, setMainMessagedTabs] = useState(true)

  const [SavedTabs, setSavedTabs] = useState(false)
  const [SavedAddedTabs, setSavedAddedTabs] = useState(true)
  const [SavedMessagedTabs, setSavedMessagedTabs] = useState(true)


  let {
    user,
    score: {
      isLoading,
      isDataAvailable,
      isLoadingMore,
      isBlocked,
      data,
      shouldFetchAgain
    },
    dispatch,
    location: {
      search
    },
    history: {
      push
    },
    selected,
    archive,
    unarchive,
    downloadedState,
    save,
    unsave,
    enhancing,
    dailyLimit,
    advancedFilterData,
    fetchPencilitAccountDetail
  } = props

  const isEnhancingPrev = usePrevious(enhancing.isEnhancing)
  const isArchivingPrev = usePrevious(archive.isArchiving)
  const isUnarchivingPrev = usePrevious(unarchive.isUnarchiving)
  const isSavingPrev = usePrevious(save.isSaving)
  const isUnsavingPrev = usePrevious(unsave.isUnsaving)


  const visaCount = qs.parse(search).visa ? qs.parse(search).visa.split('||').length : 0
  const industryCount = qs.parse(search).industryFilter ? qs.parse(search).industryFilter.split('||').length : 0
  const skillCount = qs.parse(search).skillFilter ? qs.parse(search).skillFilter.split('||').length : 0
  const companyCount = qs.parse(search).companyFilter ? qs.parse(search).companyFilter.split('||').length : 0
  const schoolCount = qs.parse(search).schoolFilter ? qs.parse(search).schoolFilter.split('||').length : 0
  const titleCount = qs.parse(search).titleFilter ? qs.parse(search).titleFilter.split('||').length : 0
  const calendarIntegrationRedirection = qs.parse(search).calendarIntegrated ? qs.parse(search).calendarIntegrated : null


  // console.log('CALENDAR INTEGRATION REDIRECTION: ', calendarIntegrationRedirection)

  const [FilterCount, setFilterCount] = useState(visaCount +
    industryCount +
    skillCount +
    companyCount +
    schoolCount +
    titleCount)

  const [ScoringFilterCount, setScoringFilterCount] = useState(0)

  const {
    data: {
      skills,
      industries,
      companies,
      schools,
      titles,
      locations
    }
  } = advancedFilterData




  // chrome.storage.local.get('jobArray', (result) => {
  //   const [jobData] = result.jobArray.filter(jobs => jobs.jobID == qs.parse(search).jId)
  //   if (jobData?.meta?.isNew)
  //     setNewJob(true)
  // })


  useEffect(() => {
    if (!advancedFilterData.isDataAvailable) {

      dispatch(fetchAdvancedFilter(qs.parse(search)))
    }
    // const todaysDate = new Date()
    // const todaysConnects = `${todaysDate.getDate()}-${todaysDate.getMonth()}-${todaysDate.getFullYear()}-connects`
    // chrome.storage.local.get(['ConnectDailyLimitSelected', todaysConnects], function (r) {
    //   if (r['ConnectDailyLimitSelected']) {
    //     dispatch(setConnectLimit(r['ConnectDailyLimitSelected']))
    //   } else {
    //     dispatch(setConnectLimit(100))
    //   }

    //   if (r[todaysConnects]) {
    //     dispatch(initializeConnectSent(r[todaysConnects]))
    //   } else {
    //     dispatch(initializeConnectSent(0))
    //   }
    // })
    // chrome.storage.local.get('jobArray', function (result) {
    //   if (result['jobArray'].length === 1)
    //     setRenderStepperState(true)
    // })
    const tabName = qs.parse(search)?.tF
    switch (tabName) {
      case "ConnectMessaged":
        setActiveTab("ConnectMessaged")
        break;
      case "FollowUp1st":
        setActiveTab("FollowUp1st")
        break;
      case "isFollowUp2Sent":
        setActiveTab("isFollowUp2Sent")
        break;
      case "SavedANDMessaged":
        setActiveTab("SavedANDMessaged")
        break;
      case "SavedANDFollowUp1st":
        setActiveTab("SavedANDFollowUp1st")
        break;
      case "SavedANDFollowUp2nd":
        setActiveTab("SavedANDFollowUp2nd")
        break;
      case "SavedAND1stAdded":
        setActiveTab("SavedAND1stAdded")
        break;
      case "SavedAND2ndAdded":
        setActiveTab("SavedAND2ndAdded")
        break;
      case "SavedANDReplied":
        setActiveTab("SavedANDReplied")
        break;
      case "SavedANDPrevMessaged":
        setActiveTab("SavedANDPrevMessaged")
        break;
      case "Archived":
        setActiveTab("Archived")
        break;
      case "Other":
        setActiveTab("Other")
        break;
      case "Downloaded":
        setActiveTab("Downloaded")
        break;
      case "Replied":
        setActiveTab("Replied")
        break;
      case "Added1st":
        setActiveTab("Added1st")
        break;
      default:
        setActiveTab("Home")
        break;
    }

  }, [])

  // useEffect(() => {
  //   if ((isEnhancingPrev && !enhancing.isEnhancing) ||
  //     // (isArchivingPrev && !archive.isArchiving) ||
  //     // (isUnarchivingPrev && !unarchive.isUnarchiving) ||
  //     (isSavingPrev && !save.isSaving) ||
  //     (isUnsavingPrev && !unsave.isUnsaving)) {
  //     // console.log('DATA.PAGE NUMBER: ', data.pageNo)
  //     getProspects(data.pageNo)
  //   }
  // }, [enhancing.isEnhancing, save.isSaving, unsave.isUnsaving])

  useEffect(() => {
    if (shouldFetchAgain) {
      getProspects(data.pageNo)
    }
  }, [shouldFetchAgain])

  // useEffect(() => {
  //   if (!isLoading && isDataAvailable && refState) {

  //     const id = refState;
  //     const yOffset = -110;
  //     const element = document.getElementById(id);
  //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  //     window.scrollTo({ top: y, behavior: 'smooth' });
  //     // document.getElementById(refState).scrollIntoView({ behavior: "smooth", block: "end" })
  //     setRefState(false)

  //   }
  // }, [isDataAvailable, isLoading])

  useEffect(() => {
    getProspects()

  }, [search])

  const getProspects = (pageUptil = null) => {
    const params = qs.parse(search)

    if (pageUptil) {
      params['pU'] = pageUptil
    }

    // console.log('PARAMS ARE --------- ', params)

    dispatch(fetchProspects(params, pageUptil ? null : 1))

  }

  // useEffect(() => {

  //   if (user && user.name) {
  //     dispatch(fetchPencilitAccount(user))
  //     // alert(JSON.stringify(fetchPencilitAccountDetail))
  //     if (fetchPencilitAccountDetail.isSuccess) {
  //       // alert('sdfsdf')
  //     }
  //     // if (calendarIntegrationRedirection !== null) {
  //     //   dispatch(fetchPencilitAccount(user))
  //     // }
  //     // if (calendarIntegrationRedirection !== null) {
  //     //   isLoading = true
  //     // }
  //   }
  //   // alert(user)
  // }, [user])

  const handleWaypointOnEnter = () => {
    const searchParams = qs.parse(search)
    const {
      pageNo
    } = data

    // console.log("=====================waypoint", pageNo)

    dispatch(fetchProspects(searchParams, (parseInt(pageNo, 10) + 1)))
  }

  const handleChangeDailyLimit = (e) => {
    // const newVal = e.target.value
    // chrome.storage.local.set({
    //   'ConnectDailyLimitSelected': `${e.target.value}`
    // }, function () {
    //   dispatch(setConnectLimit(newVal))
    // })
  }
  if (fetchPencilitAccountDetail.isSuccess) {
    // alert(JSON.stringify(fetchPencilitAccountDetail))
  }
  const heading = user && user.name ? `Welcome, ${user.name}` : `Welcome back`
  // if (user.isDataAvailable && !(pencilitAccount)) dispatch(fetchPencilitAccount(user))
  const shouldRenderWaypoint = isDataAvailable ?
    data.prospectsArray.length > 0 && parseInt(data.pageNo, 10) !== Math.ceil(parseInt(data.counts.currentCount) / parseInt(data.prospectsPerPage)) : false



  // useEffect(() => {

  //   if (isDataAvailable && calendarIntegrationRedirection !== null) {
  //     // alert('Hello got')
  //     console.log('DEBUG [1]')

  //     chrome.storage.local.get('calendarIntegrated', (r) => {
  //       if (!r?.calendarIntegrated) {
  //         console.log('DEBUG [2]')
  //         selected.push(...qs.parse(location.search).selectedProspects.split('-').map(str => Number(str)))
  //         // console.log('CAALING SET VIEW REQU')
  //         setView('RequestMeeting')
  //         isLoading = false

  //         // chrome.storage.local.set({ 'calendarIntegrated': true })
  //       }
  //     })



  //   }
  //   // alert(user)
  // }, [isDataAvailable])


  useEffect(() => {

    const searchParams = qs.parse(search)
    const newParams = {
      jId: searchParams.jId,
      isConnectPage: 1,
      fS: searchParams.fS,
      fSO: searchParams.fSO,
      secS: searchParams.secS,
      secSO: searchParams.secSO
    }

    if (searchParams.tF)
      newParams['tF'] = searchParams.tF
    if (searchParams.calendarIntegrated)
      newParams['calendarIntegrated'] = searchParams.calendarIntegrated
    if (searchParams.selectedProspects)
      newParams['selectedProspects'] = searchParams.selectedProspects


    dispatch(clearAdvancedFilters())


    push(`/html/score.html?${qs.stringify(newParams)}`)


  }, [activeTab])

  const setHome = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false
    if (qs.parse(search).tF) {
      if ('' !== qs.parse(search).tF) {

        setActiveTab('Home')
        setSelectAllProspectsFlag(false)

        const parameters = qs.parse(search)

        delete parameters.tF

        // manualApiCall('/api/auth/user/activity/store',
        //   {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ "actionName": "2ND_ADDED_FILTER" })
        //   })

        const qsStr = qs.stringify(parameters)
        dispatch(unselectAllProspects())
        push(`/html/score.html?${qsStr}`)
      }
    }

  }
  const setAdded1st = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false
    if (qs.parse(search)?.tF !== "Added1st") {
      setActiveTab('Added1st')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'Added1st'

      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "1ST_ADDED_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }
  }

  const set1stMessaged = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    if ('ConnectMessaged' !== qs.parse(search).tF) {

      setActiveTab('ConnectMessaged')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'ConnectMessaged'


      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "CONNECT_MESSAGE_SENT_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }

  }
  const set2ndMessaged = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    if ('FollowUp1st' !== qs.parse(search).tF) {

      setActiveTab('FollowUp1st')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'FollowUp1st'


      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "1ST_FOLLOW_UP_SENT_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }

  }
  const set3rdMessaged = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    if ('FollowUp2nd' !== qs.parse(search).tF) {

      setActiveTab('FollowUp2nd')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'FollowUp2nd'


      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "2ND_FOLLOW_UP_SENT_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }

  }


  const setReplied = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    const tabFilter = qs.parse(search).tF
    if ('Replied' != tabFilter) {
      // if ('ConnectMessaged' !== qs.parse(search).tF ||
      //     'FollowUp1st' !== qs.parse(search).tF ||
      //     'FollowUp2nd' !== qs.parse(search).tF) {

      setActiveTab('Replied')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'Replied'
      if (parameters.cTF)
        delete parameters.cTF


      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "MESSAGE_REPLIED_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }
  }

  const setSaved2nd = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    if ('SavedAND2ndAdded' !== qs.parse(search).tF) {

      setActiveTab('SavedAND2ndAdded')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'SavedAND2ndAdded'


      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "SAVED_ADDED_2ND_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }
  }


  const setSaved1st = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    if ('SavedAND1stAdded' !== qs.parse(search).tF) {

      setActiveTab('SavedAND1stAdded')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'SavedAND1stAdded'

      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "SAVED_ADDED_1ST_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }
  }


  const setSavedMsg = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    if ('SavedANDMessaged' !== qs.parse(search).tF) {

      setActiveTab('SavedANDMessaged')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'SavedANDMessaged'


      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "SAVED_CONNECT_MESSAGE_SENT_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }
  }

  const setSavedFu1 = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    if ('SavedANDFollowUp1st' !== qs.parse(search).tF) {

      setActiveTab('SavedANDFollowUp1st')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'SavedANDFollowUp1st'

      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "SAVED_1ST_FOLLOW_UP_SENT_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }
  }

  const setSavedFu2 = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    if ('SavedANDFollowUp2nd' !== qs.parse(search).tF) {

      setActiveTab('SavedANDFollowUp2nd')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'SavedANDFollowUp2nd'



      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "SAVED_2ND_FOLLOW_UP_SENT_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }
  }


  const setSavedANDReplied = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    if ('SavedANDReplied' !== qs.parse(search).tF) {

      setActiveTab('SavedANDReplied')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'SavedANDReplied'


      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "SAVED_MESSAGE_REPLIED_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }
  }


  const setSavedANDPrevMessaged = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    if ('SavedANDPrevMessaged' !== qs.parse(search).tF) {

      setActiveTab('SavedANDPrevMessaged')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'SavedANDPrevMessaged'


      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "SAVED_PREVIOUSLY_MESSAGED_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }
  }

  const setArchived = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    if ('Archived' !== qs.parse(search).tF) {

      setActiveTab('Archived')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'Archived'



      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "ARCHIVED_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }

  }

  const setDownloaded = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false

    if ('Downloaded' !== qs.parse(search).tF) {

      setActiveTab('Downloaded')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'Downloaded'



      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "DOWNLOADED_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }

  }


  const setOther = () => {
    if (viewState === 'ComposeMessage' ||
      viewState === 'RequestMeeting')
      return false
    const tabFilter = qs.parse(search).tF
    if ('Other' != tabFilter) {
      // if ('ConnectMessaged' !== qs.parse(search).tF ||
      //     'FollowUp1st' !== qs.parse(search).tF ||
      //     'FollowUp2nd' !== qs.parse(search).tF) {

      setActiveTab('Other')
      setSelectAllProspectsFlag(false)

      const parameters = qs.parse(search)
      parameters['tF'] = 'Other'
      if (parameters.cTF)
        delete parameters.cTF


      // manualApiCall('/api/auth/user/activity/store',
      //   {
      //     method: 'POST',
      //     headers: { 'Content-Type': 'application/json' },
      //     body: JSON.stringify({ "actionName": "PREVIOUSLY_MESSAGED_FILTER" })
      //   })

      const qsStr = qs.stringify(parameters)
      dispatch(unselectAllProspects())
      push(`/html/score.html?${qsStr}`)
    }
  }

  const handleMainTabsToggle = (e) => {
    setMainTabs(prev => !prev)
  }


  return (
    <div style={isBlocked ? { height: '100vh', overflow: 'hidden' } : {}}>
      {isBlocked &&
        <>
          {/* <Card className='BlockPopUp'>
            <div className='helpingTextContainer'>
              <img src='../../../img/info.svg' />
              <p className='helpingText'>Free usage limit reached. Please contact support at admin@dnnae.com</p>
            </div>
            <div className='contactSupportContainer'>
              <a href='mailto:admin@dnnae.com'>
                <Button
                  color='primary'
                  outline
                  // onClick={() => }
                  className='contactSupportButton'>
                  Contact Support
              </Button>
              </a>
            </div>
          </Card> */}
          <div className='CoverBlocked'>
          </div>
        </>}

      <Header {...props.user} />
      {(RenderStepperState && data.prospectsArray) ?
        <div className='headerContainer'>
          <div className="mainTitleHeadingContainer">
            <h1 className="heading">{heading}</h1>
            <p className="subheading">Let's get back to your prospect search.</p>
          </ div>
          {/* <h1 className="newFlowHeading">
            {data?.jobData?.jobTitle}
      </h1> */}
          <JdSteps onStep={4} />
          <hr className='bottomBorder' />
        </div> :
        <div className="newFlowHeaderContainer" >
          <h1 className="newFlowHeading">
            {data?.jobData?.jobTitle}
          </h1>
        </div>
      }
      {isBlocked ?
        <div className='blockedContainer'>
          <img className="blockedScreen" src='../../../img/BlockScreen.png' />
        </div> :
        <div className="main-sac-body-container">
          <Container
            className="leftNavContainer"
            style={{ width: MainTabs ? "20%" : "50px" }}>
            <div className="leftNavSection">
              <div className="leftNavHeaderContainer">
                <h3
                  className="leftNavHeader">
                  {MainTabs &&
                    `Prospects (${data?.counts?.totalProspects ?? 0})`}
                </h3>
                <img
                  ref={MainTabsRef}
                  className='mainTabsIcon'
                  onClick={handleMainTabsToggle}
                  onMouseEnter={() => MainTabsRef.current.src = HoverTabsIcon}
                  onMouseOut={() => MainTabsRef.current.src = MainTabsIcon}
                  src={MainTabsIcon}
                  alt="drop down"
                  style={{ transform: MainTabs ? "rotate(0deg)" : "rotate(180deg)" }} />
              </div>
              <Collapse
                isOpen={MainTabs}
                className="leftNavBodyContainer">
                <div className="leftNavBodyMain" >
                  <ul>
                    <li>
                      <a
                        onClick={() => setMainAddedTabs(prev => !prev)}
                        className="leftNavBodyItem">
                        <div className="leftNavBodyMainTitleContainer">
                          <img
                            className='seeMoreCaret'
                            src={DropDownIcon}
                            alt="drop down"
                            style={{ transform: MainAddedTabs ? "rotate(180deg)" : "rotate(0deg)" }} />
                          <h3 className="leftNavBodyMainTitle">
                            {`Added`}
                          </h3>
                        </div>
                        <div className="navBodyCounterContainer">
                          <h3 className="navBodyCounter">
                            {`${data?.counts?.totalAddedProspects ?? 0}`}
                          </h3>
                        </div>
                      </a>
                    </li>
                    <Collapse
                      isOpen={MainAddedTabs}>
                      <li>
                        <a
                          className={`${activeTab === "Home" ?
                            "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                              viewState === 'RequestMeeting' ?
                              "stopCursor " : ""}leftNavBodyItem`}
                          onClick={setHome} >
                          <div className="leftNavBodyMainTitleContainer">
                            <span className="caretSpaceholder" />
                            <h3 className={activeTab === "Home" ?
                              "navTitleActive leftNavBodyMainTitle indent1stLevel" :
                              "leftNavBodyMainTitle indent1stLevel"}>
                              2nd/3rd
                          </h3>
                          </div>
                          <div className="navBodyCounterContainer">
                            <h3 className={activeTab === "Home" ?
                              "navCounterActive navBodyCounter" :
                              "navBodyCounter"}>
                              {`${data?.counts?.totalAdded2nd ?? 0}`}
                            </h3>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className={`${activeTab === "Added1st" ?
                            "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                              viewState === 'RequestMeeting' ?
                              "stopCursor " : ""}leftNavBodyItem`}
                          onClick={setAdded1st} >
                          <div className="leftNavBodyMainTitleContainer">
                            <span className="caretSpaceholder" />
                            <h3 className={activeTab === "Added1st" ?
                              "navTitleActive leftNavBodyMainTitle indent1stLevel" :
                              "leftNavBodyMainTitle indent1stLevel"}>
                              1st
                          </h3>
                          </div>
                          <div className="navBodyCounterContainer">
                            <h3 className={activeTab === "Added1st" ?
                              "navCounterActive navBodyCounter" :
                              "navBodyCounter"}>
                              {`${data?.counts?.totalAdded1st ?? 0}`}
                            </h3>
                          </div>
                        </a>
                      </li>
                    </Collapse>
                    <li>
                      <a
                        onClick={() => setMainMessagedTabs(prev => !prev)}
                        className="leftNavBodyItem">
                        <div className="leftNavBodyMainTitleContainer">
                          <img
                            className='seeMoreCaret'
                            src={DropDownIcon}
                            alt="drop down"
                            style={{ transform: MainMessagededTabs ? "rotate(180deg)" : "rotate(0deg)" }} />
                          <h3 className="leftNavBodyMainTitle">
                            {`Messaged`}
                          </h3>
                        </div>
                        <div className="navBodyCounterContainer">
                          <h3 className="navBodyCounter">
                            {`${data?.counts?.totalMessaged ?? 0}`}
                          </h3>
                        </div>
                      </a>
                    </li>
                    <Collapse
                      isOpen={MainMessagededTabs}>
                      <li>
                        <a
                          className={`${activeTab === "ConnectMessaged" ?
                            "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                              viewState === 'RequestMeeting' ?
                              "stopCursor " : ""}leftNavBodyItem`}
                          onClick={set1stMessaged} >
                          <div className="leftNavBodyMainTitleContainer">
                            <span className="caretSpaceholder" />
                            <h3 className={activeTab === "ConnectMessaged" ?
                              "navTitleActive leftNavBodyMainTitle indent1stLevel" :
                              "leftNavBodyMainTitle indent1stLevel"}>
                              Connect Messaged
                          </h3>
                          </div>
                          <div className="navBodyCounterContainer">
                            <h3 className={activeTab === "ConnectMessaged" ?
                              "navCounterActive navBodyCounter" :
                              "navBodyCounter"}>
                              {`${data?.counts?.totalConnectMessaged ?? 0}`}
                            </h3>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className={`${activeTab === "FollowUp1st" ?
                            "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                              viewState === 'RequestMeeting' ?
                              "stopCursor " : ""}leftNavBodyItem`}
                          onClick={set2ndMessaged} >
                          <div className="leftNavBodyMainTitleContainer">
                            <span className="caretSpaceholder" />
                            <h3 className={activeTab === "FollowUp1st" ?
                              "navTitleActive leftNavBodyMainTitle indent1stLevel" :
                              "leftNavBodyMainTitle indent1stLevel"}>
                              1st Follow Up
                          </h3>
                          </div>
                          <div className="navBodyCounterContainer">
                            <h3 className={activeTab === "FollowUp1st" ?
                              "navCounterActive navBodyCounter" :
                              "navBodyCounter"}>
                              {`${data?.counts?.total1stFollowupMessaged ?? 0}`}
                            </h3>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a
                          className={`${activeTab === "FollowUp2nd" ?
                            "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                              viewState === 'RequestMeeting' ?
                              "stopCursor " : ""}leftNavBodyItem`}
                          onClick={set3rdMessaged} >
                          <div className="leftNavBodyMainTitleContainer">
                            <span className="caretSpaceholder" />
                            <h3 className={activeTab === "FollowUp2nd" ?
                              "navTitleActive leftNavBodyMainTitle indent1stLevel" :
                              "leftNavBodyMainTitle indent1stLevel"}>
                              2nd Follow Up
                          </h3>
                          </div>
                          <div className="navBodyCounterContainer">
                            <h3 className={activeTab === "FollowUp2nd" ?
                              "navCounterActive navBodyCounter" :
                              "navBodyCounter"}>
                              {`${data?.counts?.total2ndFollowupMessaged ?? 0}`}
                            </h3>
                          </div>
                        </a>
                      </li>
                    </Collapse>
                    {data?.jobData?.meta?.isNew &&
                      <li>
                        <a
                          className={`${activeTab === "Replied" ?
                            "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                              viewState === 'RequestMeeting' ?
                              "stopCursor " : ""}leftNavBodyItem`}
                          onClick={setReplied} >
                          <div className="leftNavBodyMainTitleContainer">
                            <span className="caretSpaceholder" />
                            <h3 className={activeTab === "Replied" ?
                              "navTitleActive leftNavBodyMainTitle" :
                              "leftNavBodyMainTitle"}>
                              Replied
                        </h3>
                          </div>
                          <div className="navBodyCounterContainer">
                            <h3 className={activeTab === "Replied" ?
                              "navCounterActive navBodyCounter" :
                              "navBodyCounter"}>
                              {`${data?.counts?.replied ?? 0}`}
                            </h3>
                          </div>
                        </a>
                      </li>}
                  </ul>
                </div>
                <hr className="navHorizontalDevider" />
                <div className="leftNavBodySecondary" >
                  <ul>
                    <li>
                      <a
                        className={`${activeTab === "Archived" ?
                          "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                            viewState === 'RequestMeeting' ?
                            "stopCursor " : ""}leftNavBodyItem`}
                        onClick={setArchived} >
                        <div className="leftNavBodySecondaryTitleContainer">
                          <span className="caretSpaceholder" />
                          <h3 className={activeTab === "Archived" ?
                            "navTitleActive leftNavBodySecondaryTitle" :
                            "leftNavBodySecondaryTitle"}>
                            Archived
                      </h3>
                        </div>
                        <div className="navBodyCounterContainer">
                          <h3 className={activeTab === "Archived" ?
                            "navCounterActive navBodyCounter" :
                            "navBodyCounter"}>
                            {`${data?.counts?.totalArchived ?? 0}`}
                          </h3>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        className={`${activeTab === "Downloaded" ?
                          "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                            viewState === 'RequestMeeting' ?
                            "stopCursor " : ""}leftNavBodyItem`}
                        onClick={setDownloaded} >
                        <div className="leftNavBodySecondaryTitleContainer">
                          <span className="caretSpaceholder" />
                          <h3 className={activeTab === "Downloaded" ?
                            "navTitleActive leftNavBodySecondaryTitle" :
                            "leftNavBodySecondaryTitle"}>
                            Downloaded
                      </h3>
                        </div>
                        <div className="navBodyCounterContainer">
                          <h3 className={activeTab === "Downloaded" ?
                            "navCounterActive navBodyCounter" :
                            "navBodyCounter"}>
                            {`${data?.counts?.totalDownloaded ?? 0}`}
                          </h3>
                        </div>
                      </a>
                    </li>
                    {data?.jobData?.meta?.isNew &&
                      <li>
                        <a
                          className={`${activeTab === "Other" ?
                            "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                              viewState === 'RequestMeeting' ?
                              "stopCursor " : ""}leftNavBodyItem`}
                          onClick={setOther} >
                          <div className="leftNavBodySecondaryTitleContainer">
                            <span className="caretSpaceholder" />
                            <h3 className={activeTab === "Other" ?
                              "navTitleActive leftNavBodySecondaryTitle" :
                              "leftNavBodySecondaryTitle"}>
                              Previously Messaged
                        </h3>
                          </div>
                          <div className="navBodyCounterContainer">
                            <h3 className={activeTab === "Other" ?
                              "navCounterActive navBodyCounter" :
                              "navBodyCounter"}>
                              {`${data?.counts?.totalOthers ?? 0}`}
                            </h3>
                          </div>
                        </a>
                      </li>}
                    <li>
                      <a
                        onClick={() => setSavedTabs(prev => !prev)}
                        className="leftNavBodyItem">
                        <div className="leftNavBodyMainTitleContainer">
                          <img
                            className='seeMoreCaret'
                            src={DropDownIcon}
                            alt="drop down"
                            style={{ transform: SavedTabs ? "rotate(180deg)" : "rotate(0deg)" }} />
                          <h3 className="leftNavBodyMainTitle">
                            {`Saved`}
                          </h3>
                        </div>
                        <div className="navBodyCounterContainer">
                          <h3 className="navBodyCounter">
                            {`${data?.counts?.totalSaved ?? 0}`}
                          </h3>
                        </div>
                      </a>
                    </li>
                    <Collapse
                      isOpen={SavedTabs}>
                      <li>
                        <a
                          onClick={() => setSavedAddedTabs(prev => !prev)}
                          className="leftNavBodyItemSub">
                          <div className="leftNavBodyMainTitleContainer">
                            <img
                              className='seeMoreCaret'
                              src={DropDownIcon}
                              alt="drop down"
                              style={{ transform: SavedAddedTabs ? "rotate(180deg)" : "rotate(0deg)" }} />
                            <h3 className="leftNavBodyMainTitle indent1stLevel">
                              {`Added`}
                            </h3>
                          </div>
                          <div className="navBodyCounterContainer">
                            <h3 className="navBodyCounter">
                              {`${data?.counts?.totalSavedNotMessaged ?? 0}`}
                            </h3>
                          </div>
                        </a>
                      </li>
                      <Collapse
                        isOpen={SavedAddedTabs}>
                        <li>
                          <a
                            className={`${activeTab === "SavedAND2ndAdded" ?
                              "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                                viewState === 'RequestMeeting' ?
                                "stopCursor " : ""}leftNavBodyItem`}
                            onClick={setSaved2nd} >
                            <div className="leftNavBodySecondaryTitleContainer">
                              <span className="caretSpaceholder" />
                              <h3 className={activeTab === "SavedAND2ndAdded" ?
                                "navTitleActive leftNavBodySecondaryTitle indent2ndLevel" :
                                "leftNavBodySecondaryTitle indent2ndLevel"}>
                                2nd/3rd
                            </h3>
                            </div>
                            <div className="navBodyCounterContainer">
                              <h3 className={activeTab === "SavedAND2ndAdded" ?
                                "navCounterActive navBodyCounter" :
                                "navBodyCounter"}>
                                {`${data?.counts?.totalSavedAdded2nd ?? 0}`}
                              </h3>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            className={`${activeTab === "SavedAND1stAdded" ?
                              "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                                viewState === 'RequestMeeting' ?
                                "stopCursor " : ""}leftNavBodyItem`}
                            onClick={setSaved1st} >
                            <div className="leftNavBodySecondaryTitleContainer">
                              <span className="caretSpaceholder" />
                              <h3 className={activeTab === "SavedAND1stAdded" ?
                                "navTitleActive leftNavBodySecondaryTitle indent2ndLevel" :
                                "leftNavBodySecondaryTitle indent2ndLevel"}>
                                1st
                              </h3>
                            </div>
                            <div className="navBodyCounterContainer">
                              <h3 className={activeTab === "SavedAND1stAdded" ?
                                "navCounterActive navBodyCounter" :
                                "navBodyCounter"}>
                                {`${data?.counts?.totalSavedAdded1st ?? 0}`}
                              </h3>
                            </div>
                          </a>
                        </li>
                      </Collapse>
                      <li>
                        <a
                          onClick={() => setSavedMessagedTabs(prev => !prev)}
                          className="leftNavBodyItemSub">
                          <div className="leftNavBodyMainTitleContainer">
                            <img
                              className='seeMoreCaret'
                              src={DropDownIcon}
                              alt="drop down"
                              style={{ transform: SavedMessagedTabs ? "rotate(180deg)" : "rotate(0deg)" }} />
                            <h3 className="leftNavBodyMainTitle indent1stLevel">
                              {`Messaged`}
                            </h3>
                          </div>
                          <div className="navBodyCounterContainer">
                            <h3 className="navBodyCounter">
                              {`${data?.counts?.totalSavedMessaged ?? 0}`}
                            </h3>
                          </div>
                        </a>
                      </li>
                      <Collapse
                        isOpen={SavedMessagedTabs}>
                        <li>
                          <a
                            className={`${activeTab === "SavedANDMessaged" ?
                              "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                                viewState === 'RequestMeeting' ?
                                "stopCursor " : ""}leftNavBodyItem`}
                            onClick={setSavedMsg} >
                            <div className="leftNavBodySecondaryTitleContainer">
                              <span className="caretSpaceholder" />
                              <h3 className={activeTab === "SavedANDMessaged" ?
                                "navTitleActive leftNavBodySecondaryTitle indent2ndLevel" :
                                "leftNavBodySecondaryTitle indent2ndLevel"}>
                                Connect Messaged
                              </h3>
                            </div>
                            <div className="navBodyCounterContainer">
                              <h3 className={activeTab === "SavedANDMessaged" ?
                                "navCounterActive navBodyCounter" :
                                "navBodyCounter"}>
                                {`${data?.counts?.totalSaved1stMessaged ?? 0}`}
                              </h3>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            className={`${activeTab === "SavedANDFollowUp1st" ?
                              "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                                viewState === 'RequestMeeting' ?
                                "stopCursor " : ""}leftNavBodyItem`}
                            onClick={setSavedFu1} >
                            <div className="leftNavBodySecondaryTitleContainer">
                              <span className="caretSpaceholder" />
                              <h3 className={activeTab === "SavedANDFollowUp1st" ?
                                "navTitleActive leftNavBodySecondaryTitle indent2ndLevel" :
                                "leftNavBodySecondaryTitle indent2ndLevel"}>
                                1st Follow Up
                              </h3>
                            </div>
                            <div className="navBodyCounterContainer">
                              <h3 className={activeTab === "SavedANDFollowUp1st" ?
                                "navCounterActive navBodyCounter" :
                                "navBodyCounter"}>
                                {`${data?.counts?.totalSaved2ndMessaged ?? 0}`}
                              </h3>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            className={`${activeTab === "SavedANDFollowUp2nd" ?
                              "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                                viewState === 'RequestMeeting' ?
                                "stopCursor " : ""}leftNavBodyItem`}
                            onClick={setSavedFu2} >
                            <div className="leftNavBodySecondaryTitleContainer">
                              <span className="caretSpaceholder" />
                              <h3 className={activeTab === "SavedANDFollowUp2nd" ?
                                "navTitleActive leftNavBodySecondaryTitle indent2ndLevel" :
                                "leftNavBodySecondaryTitle indent2ndLevel"}>
                                2nd Follow Up
                              </h3>
                            </div>
                            <div className="navBodyCounterContainer">
                              <h3 className={activeTab === "SavedANDFollowUp2nd" ?
                                "navCounterActive navBodyCounter" :
                                "navBodyCounter"}>
                                {`${data?.counts?.totalSaved3rdMessaged ?? 0}`}
                              </h3>
                            </div>
                          </a>
                        </li>
                      </Collapse>
                      {data?.jobData?.meta?.isNew &&
                        <li>
                          <a
                            className={`${activeTab === "SavedANDReplied" ?
                              "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                                viewState === 'RequestMeeting' ?
                                "stopCursor " : ""}leftNavBodyItem`}
                            onClick={setSavedANDReplied} >
                            <div className="leftNavBodySecondaryTitleContainer">
                              <span className="caretSpaceholder" />
                              <h3 className={activeTab === "SavedANDReplied" ?
                                "navTitleActive leftNavBodySecondaryTitle indent1stLevel" :
                                "leftNavBodySecondaryTitle indent1stLevel"}>
                                Replied
                              </h3>
                            </div>
                            <div className="navBodyCounterContainer">
                              <h3 className={activeTab === "SavedANDReplied" ?
                                "navCounterActive navBodyCounter" :
                                "navBodyCounter"}>
                                {`${data?.counts?.totalSavedANDReplied ?? 0}`}
                              </h3>
                            </div>
                          </a>
                        </li>}
                      {data?.jobData?.meta?.isNew &&
                        <li>
                          <a
                            className={`${activeTab === "SavedANDPrevMessaged" ?
                              "navItemActive " : ""}${viewState === 'ComposeMessage' ||
                                viewState === 'RequestMeeting' ?
                                "stopCursor " : ""}leftNavBodyItem`}
                            onClick={setSavedANDPrevMessaged} >
                            <div className="leftNavBodySecondaryTitleContainer">
                              <span className="caretSpaceholder" />
                              <h3 className={activeTab === "SavedANDPrevMessaged" ?
                                "navTitleActive leftNavBodySecondaryTitle indent1stLevel" :
                                "leftNavBodySecondaryTitle indent1stLevel"}>
                                Previously Messaged
                              </h3>
                            </div>
                            <div className="navBodyCounterContainer">
                              <h3 className={activeTab === "SavedANDPrevMessaged" ?
                                "navCounterActive navBodyCounter" :
                                "navBodyCounter"}>
                                {`${data?.counts?.totalSavedANDPrevMessaged ?? 0}`}
                              </h3>
                            </div>
                          </a>
                        </li>}
                    </Collapse>
                  </ul>
                </div>
              </Collapse>
            </div>
          </Container>
          <Container className='sac-container'>

            <div className='sac-content'>
              {/* {activeTab === "Messaged" &&
                <div className="sectionTabs">
                  <TabsSection
                    setSelectAllProspectsFlag={setSelectAllProspectsFlag}
                    totalFirstMsgd={data?.counts?.totalConnectMessaged}
                    totalSecondMsgd={data?.counts?.total1stFollowupMessaged}
                    totalThirdMsgd={data?.counts?.total2ndFollowupMessaged}
                    search={search}
                    push={push}
                    dispatch={dispatch} />
                </div>}
              {activeTab === "Saved" &&
                <div className="sectionTabs">
                  <TabsSection
                    setSelectAllProspectsFlag={setSelectAllProspectsFlag}
                    totalFirstMsgd={data?.counts?.totalSavedNotMessaged}
                    totalSecondMsgd={data?.counts?.totalSavedMessaged}
                    search={search}
                    push={push}
                    dispatch={dispatch} />
                </div>} */}
              <div className='sacMainContainer'>
                {(activeTab === 'Home' || qs.parse(search).tF === "SavedANDNotMessaged") ?
                  <div className="sectionHeading">
                    {/* <h2 style={{ margin: "0px" }}>Message Prospects</h2> */}

                    {/* <span style={{ height: '20px' }} className='verBar' /> */}
                    {/* <div className="dailyLimitConatiner" style={{ display: 'flex' }}>
                      <span>Daily Limit</span>
                      <select
                        value={dailyLimit.limit}
                        onChange={handleChangeDailyLimit}
                        className='limit-selector'
                      >
                        {dailyLimitSelectOptions.map(item =>
                          <option key={item.id}>{item.value}</option>
                        )}
                      </select>
                      <p>requests</p>
                      <div
                        className='info-icon'
                        id='messageLimitsTooltip'
                        style={{
                          backgroundColor: '#CDD1D7',
                          marginLeft: '10px'
                        }}
                      />
                      {/* <Tooltip
                        placement="bottom"
                        isOpen={messageLimitTooltip}
                        target="messageLimitsTooltip"
                        toggle={() => setMessageLimitTooltip(!messageLimitTooltip)}
                        fade={true}
                      >
                        Selecting a limit too high may result in LinkedIn blocking your account.
                    </Tooltip>
                    </div>  */}
                  </div> :
                  <div className="sectionHeading">
                  </div>}
                {/* {viewState === 'ComposeMessage' &&
                  <ComposeMessage
                    setSelectAllProspectsFlag={setSelectAllProspectsFlag}
                    userName={props.user.name}
                    viewState={viewState}
                    setView={(view) => {
                      dispatch(unselectAllProspects())
                      setView(view)
                      getProspects()
                    }}
                    selected={selected}
                    data={data.prospectsArray}
                    jobData={data.jobData}
                    SelectAllProspectsFlag={SelectAllProspectsFlag}
                  />
                } */}

                {viewState === 'ScoreAndConnect' &&
                  <React.Fragment>
                    <div
                      className="sectionFilter"
                      id="filters"
                      style={{
                        position: moreDetailsPopUp ? 'relative' : 'sticky',
                        paddingBottom: selected.length > 0 ? '12px' : '24px'
                      }}>
                      {(!advancedFilterData.isLoading && advancedFilterData.isDataAvailable) &&

                        <AdvanceFilterSection
                          FilterCount={FilterCount}
                          setFilterCount={setFilterCount}
                          setSchoolsFilterList={setSchoolsFilterList}
                          schoolsFilterList={schoolsFilterList}
                          setVisaStatus={setVisaStatus}
                          VisaStatus={VisaStatus}
                          setCompanyFilterList={setCompanyFilterList}
                          companyFilterList={companyFilterList}
                          setSkillsFilterList={setSkillsFilterList}
                          skillsFilterList={skillsFilterList}
                          setIndustryFilterList={setIndustryFilterList}
                          industryFilterList={industryFilterList}
                          setTitleFilterList={setTitleFilterList}
                          titleFilterList={titleFilterList}
                          setVisa={setVisa}
                          Visa={Visa}

                          setSelectAllProspectsFlag={setSelectAllProspectsFlag}

                          search={search}
                          push={push}
                          Show={Show}
                          setShow={setShow}

                          locations={locations}
                          skills={skills}
                          industries={industries}
                          companies={companies}
                          schools={schools}
                          titles={titles}
                          isDataAvailable={advancedFilterData.isDataAvailable}
                          activeTab={activeTab}
                        />
                      }
                      <ScoringFilterSection
                        titleFlag={data?.prospectsArray[0]?.scoring?.title_total > 0 || data?.counts?.currentCount < 1}
                        skillFlag={data?.prospectsArray[0]?.scoring?.skill_total > 0 || data?.counts?.currentCount < 1}
                        industryFlag={data?.prospectsArray[0]?.scoring?.industry_total > 0 || data?.counts?.currentCount < 1}
                        experienceFlag={data?.prospectsArray[0]?.scoring?.experience_total > 0 || data?.counts?.currentCount < 1}
                        educationFlag={data?.prospectsArray[0]?.scoring?.education_total > 0 || data?.counts?.currentCount < 1}
                        FilterCount={ScoringFilterCount}
                        setFilterCount={setScoringFilterCount}
                        Show={ScoringFilterShow}
                        setShow={setScoringFilterShow}
                        setSelectAllProspectsFlag={setSelectAllProspectsFlag}
                        search={search}
                        push={push}
                        activeTab={activeTab}
                      />
                      <FilterSection
                        FilterCount={FilterCount}
                        setFilterCount={setFilterCount}
                        ScoringFilterCount={ScoringFilterCount}
                        setScoringFilterCount={setScoringFilterCount}

                        setSchoolsFilterList={setSchoolsFilterList}
                        schoolsFilterList={schoolsFilterList}
                        setVisaStatus={setVisaStatus}
                        VisaStatus={VisaStatus}
                        setCompanyFilterList={setCompanyFilterList}
                        companyFilterList={companyFilterList}
                        setSkillsFilterList={setSkillsFilterList}
                        skillsFilterList={skillsFilterList}
                        setIndustryFilterList={setIndustryFilterList}
                        industryFilterList={industryFilterList}
                        setTitleFilterList={setTitleFilterList}
                        titleFilterList={titleFilterList}
                        setVisa={setVisa}
                        Visa={Visa}

                        activeTab={activeTab}
                        isArchiving={archive.isArchiving}
                        isDownloading={downloadedState.isDownloading}
                        newJob={newJob}


                        setView={setView}
                        Show={Show}
                        setShow={setShow}
                        ScoringFilterShow={ScoringFilterShow}
                        setScoringFilterShow={setScoringFilterShow}
                        selected={selected}
                        dispatch={dispatch}
                        totalProspects={data.prospectsArray.length}
                        totalRecord={data?.counts?.currentCount}
                        csvData={data.prospectsArray}
                        jobTitle={data?.jobData?.jobTitle}
                        jobId={data?.jobData?.jobId}
                        SelectAllProspectsFlag={SelectAllProspectsFlag}
                        setSelectAllProspectsFlag={setSelectAllProspectsFlag}
                        isLimitExceeded={parseInt(dailyLimit.sent, 10) >= parseInt(dailyLimit.limit, 10)}
                      />

                    </ div>
                    {(!isLoading && !isBlocked && isDataAvailable) &&
                      <React.Fragment>
                        <ListSection
                          refState={refState}
                          setRefState={setRefState}
                          totalRecord={data?.counts?.currentCount}
                          SelectAllProspectsFlag={SelectAllProspectsFlag}
                          setSelectAllProspectsFlag={setSelectAllProspectsFlag}
                          moreDetailsPopUp={moreDetailsPopUp}
                          setMoreDetailsPopUp={setMoreDetailsPopUp}
                          data={data?.prospectsArray}
                          selected={selected}
                          isDataAvailable={isDataAvailable}
                          dispatch={dispatch}
                          enhancing={enhancing}
                        />
                        {shouldRenderWaypoint &&
                          <Waypoint
                            fireOnRapidScroll={true}
                            onEnter={handleWaypointOnEnter}
                          />
                        }
                        {isLoadingMore && <Container className="loader">
                          <Loader color='blue' height='100px' width='100px' />
                        </Container>}
                      </React.Fragment>
                    }
                  </ React.Fragment>
                }
                {(!isLoading && !isBlocked && data.prospectsArray.length < 1) &&
                  <BlankSlate setSelectAllProspectsFlag={setSelectAllProspectsFlag} />}
              </ div>
              {isLoading && <Container className="loader"><Loader color='blue' height='100px' width='100px' /></Container>}
              {/* {calendarIntegrationRedirection !== null && !fetchPencilitAccountDetail.isSuccess && <Container className="loader"><Loader color='blue' height='100px' width='100px' /></Container>} */}

              {/* {viewState === 'RequestMeeting' && fetchPencilitAccountDetail.isSuccess &&
                <RequestMeeting
                  viewState={viewState}
                  setView={(view) => {
                    dispatch(unselectAllProspects())
                    setView(view)

                    

                    const searchParams = qs.parse(search)
                    const newParams = {
                      jId: searchParams.jId,
                      fS: searchParams.fS,
                      fSO: searchParams.fSO,
                      secS: searchParams.secS,
                      secSO: searchParams.secSO
                    }

                    if (searchParams.tF)
                      newParams['tF'] = searchParams.tF

                      push(`/html/score.html?${qs.stringify(newParams)}`)




                    // getProspects()
                  }}
                  selected={selected}
                  fetchPencilitAccountDetail={fetchPencilitAccountDetail}
                  dailyLimit={dailyLimit}
                  data={data?.prospectsArray}
                  jobData={data.jobData}
                  setSelectAllProspectsFlag={setSelectAllProspectsFlag} />
              } */}
              {/*<RequestMeeting viewState={viewState} setView={setView} selected={selected} />*/}

              {/* <RequestMeeting viewState={viewState} setView={setView} selected={selected} /> */}

            </div>
          </Container >
        </div>}
    </div >
  )
}


export default
  connect(state => {
    return {
      downloadedState: state.score.downloaded,
      user: state.auth.user,
      score: state.score.list,
      selected: state.score.selected,
      archive: state.score.archive,
      unarchive: state.score.unarchive,
      save: state.score.save,
      unsave: state.score.unsave,
      enhancing: state.score.enhancing,
      dailyLimit: state.score.dailyLimit,
      advancedFilterData: state.score.advancedFilterData,
      fetchPencilitAccountDetail: state.score.fetchPencilitAccountDetail
    }
  })(ScoreAndConnect2);
