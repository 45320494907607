import React from "react";
import ListItem from './ListItem'
import './style.css'





const ListSection = (props) => {

  const {
    data,
    totalRecord,
    selected,
    dispatch,
    enhancing,
    // moreDetailsPopUp,
    // setMoreDetailsPopUp,
    SelectAllProspectsFlag,
    setSelectAllProspectsFlag,
    setRefState,
    refState
    
  } = props

  console.log('LIST SECTION RENDER: ', selected)

  return (

    <div>
      {data.map((item, i) => {
        const isEnhancing = enhancing.data.includes(item.id)
        return (
          <ListItem
            index={i}
            refState={refState}
            setRefState={setRefState}
            totalRecord={totalRecord}
            SelectAllProspectsFlag={SelectAllProspectsFlag}
            setSelectAllProspectsFlag={setSelectAllProspectsFlag}
            // moreDetailsPopUp={moreDetailsPopUp}
            // setMoreDetailsPopUp={setMoreDetailsPopUp}
            item={item}
            key={item.id || i}
            // selected={selected}
            isSelected={selected.includes(item.id)}
            selectedCount={selected.length}
            dispatch={dispatch}
            isEnhancing={isEnhancing}
          />
        )
      }
      )}
    </div>
  )
}
export default ListSection