import React, { useEffect } from 'react';
import Header from '../../components/Header';
import { Spinner, Table } from 'reactstrap';
import './style.css'
import { Link } from 'react-router-dom'
// import JobCard from './JobCard';
import { fetchJobs } from '../../actions/allJobs';
import qs from 'query-string';
import { Helmet } from 'react-helmet';
import moment from 'moment'
// import { set } from 'lodash';
import { connect } from 'react-redux'
const AllJobs = ({ jobs, jobsPending, dispatch, location: {
    search
}, }) => {
    // const [jLength, setJLength] = useState(0)
    useEffect(() => {
        dispatch(fetchJobs(qs.parse(search)))
        // setJLength(jobs.length);
    }, []);

    const extId = process.env.REACT_APP_ENV === 'production' ? 'kgilfabddfgbgodjclngamckpdagbagp' : 'hlpelohdpfokcdcffbbhcakbfboheglc'


    return (
        <div>
            <Helmet>
                <title>DNNae Admin - Jobs </title>
            </Helmet>
            <Header />
            {
                jobsPending &&
                <div className="spinner">
                    <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
                </div>
            }

            {jobs &&
                jobs.length > 0
                ?
                <div className="all-jobs-content">
                    <Table striped size="sm" className="jobs-table">
                        <thead >
                            <tr>
                                <th >#</th>
                                <th>Title</th>
                                <th >Location</th>
                                <th ><span>Created At</span></th>
                                <th ><span >Scored</span></th>
                                <th ><span >Messaged</span></th>
                                <th><span >Accepted</span></th>
                                <th ><span>Replied</span></th>
                                <th ><span>Downloaded</span></th>

                                {localStorage.getItem('userType') !== 'CUSTOMER-ADMIN' &&
                                    <><th ><span >Deep Scored</span></th>
                                        <th><span >Quick Scored</span></th>
                                    </>
                                }
                                <th >Details   </th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                jobs.map((job, index) => (
                                    <tr key={index}>

                                        <th scope="row">{index + 1}</th>
                                        <td>{job.jobTitle}</td>
                                        <td>{
                                            job.jobArray.filter(item => item.tag === 'JOB_LOCATION')[0].data.map(item => typeof (item) === 'string' ? item : item.name).join(', ')}</td>
                                        <td className="apply-width">
                                            <span> {moment(job.createdAt).utcOffset('-06:00').format('YYYY MM DD')}</span>
                                            <br />
                                            <span>  {moment(job.createdAt).utcOffset('-06:00').format('HH:MM:SS')}</span>
                                        </td>
                                        <td style={{ width: '80px' }}><p>{job.profileScored}</p></td>
                                        <td style={{ width: '80px' }}><p>{job.totalSent}</p></td>

                                        <td style={{ width: '80px' }}><p>{job.totalAccepted}</p></td>
                                        <td style={{ width: '80px' }} ><p>{job.totalReplied}</p></td>
                                        <td style={{ width: '80px' }} ><p>{job.totalDownloads}</p></td>

                                        {localStorage.getItem('userType') !== 'CUSTOMER-ADMIN' &&
                                            <>
                                                <td className="apply-width"><span>{job.deepScored}</span></td>
                                                <td className="apply-width"><span >{job.quickScored}</span></td>
                                            </>
                                        }
                                        <td className="apply-width">
                                            <span>
                                                <Link style={{ fontSize: '15px' }} to={{
                                                    pathname: '/html/jobDetails.html',
                                                    search: '?jobId=' + job.jobID
                                                }}
                                                    target="_blank" >
                                                    Job Criteria
                                                </Link>
                                                <br />

                                                <Link style={{ fontSize: '15px' }}
                                                    to={{
                                                        pathname: `chrome-extension://${extId}/html/job.html`,
                                                        search: `?fS=title_score&fSO=desc&isConnectPage=1&jId=${job.jobID}&secS=skill_score&secSO=desc&tN=2&T=${localStorage.getItem('token')}`
                                                    }}
                                                    target="_blank" >Job Prospects</Link>
                                                {/* <Link style={{ fontSize: '15px' }}
                                                    to={{
                                                        pathname: '/html/score.html',
                                                        search: '?cTF=2&fS=title_score&fSO=desc&isConnectPage=1&jId=' + job.jobID + '&secS=skill_score&secSO=desc',
                                                    }}
                                                    target="_blank" >Job Prospects</Link> */}
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </div>
                :
                !jobsPending &&
                <p className="spinner">No Jobs Found</p>
            }
        </div>
    );
};
export default connect(state => ({
    jobsError: state.jobs.jobsError,
    jobs: state.jobs.jobs,
    jobsPending: state.jobs.jobsPending
}))(AllJobs)