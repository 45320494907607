import React, { useState } from 'react'
import Section from '../Section'
import checkSmall from '../img/check-small.svg'
// import { setJobTitle, selectAdditionalTitle, addAdditionalTitle } from '../../actions/jobDescription'

import './ReviseJd.css'

const titles = [
  "resident engineer",
  "engineer, level 1",
  "splunk engineer",
  "engineer/associate engineer ii",
  "sr. ate engineer",
  "entry level engineer",
  "engineer journeyman- module c",
  "intern - engineer",
  "presales engineer",
  "engineer full-time 2021"
]

const JobTitleSection = ({
  title,
  additionalTitles,
  dispatch,
  toggle
}) => {
  const [newAdditionalTitle, setNewAdditionalTitle] = useState('')
  // const selectedAdditionalTitleCount = additionalTitles.filter(item => item.isSelected).length
  return (
    <Section title='Job Title'>
      <input className='job-title-ip' value={title} onChange={(e) => {
        // dispatch(setJobTitle(e.target.value)) 
      }} />
      <div className='revise-footer'>
        <button
          onClick={toggle}>
          Job Description
        </button>
        </div>
       {additionalTitles.length>0&& <div>
        <p style={{ marginBottom: '12px', marginTop: '32px' }} className='paragraph-secondary'>
          Additional Titles
      </p>


        <div className='additional-titles'>
          <div className='chips-container'>
            {additionalTitles.map((item, i) =>
              <div
                className={'chip chip-selected'}
              >
                {item}
                <img
                  style={{ marginLeft: '14px', cursor: 'pointer' }}
                  src={checkSmall}
                />
              </div>
            )}
          </div>
          {/* <div className='add-title'>
          <input
            style={{ marginBottom: 0 }}
            className='job-title-ip'
            placeholder='Add a new additional title'
            value={newAdditionalTitle}
            onChange={() => {}}
          />
          <span 
          disbaled={true}
          onClick={() => {
            // dispatch(addAdditionalTitle({
            //   name: newAdditionalTitle,
            //   isSelected: selectedAdditionalTitleCount < 2 ? true : false
            // }))
            setNewAdditionalTitle('')
          }}>Add Title</span>
        </div> */}
        </div>
        </div>}
    </Section>
  )
}

export default JobTitleSection