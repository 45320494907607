import React, { useEffect } from 'react';
import ProtectedRoute from '../ProtectedRoute';
import { Switch, Route } from 'react-router-dom'
import Login from '../../views/Login'
import ActivityLogs from '../../views/ActivityLogs'
import DetailedAnalytics from '../../views/DetailedAnalytics'
import AllJobs from '../../views/AllJobs'
import JobDetails from '../../views/JobDetails'
import ScoreandConnect2 from '../../views/ScoreandConnect2'
import './main.css'
import AddCandidate from '../../views/AddCandidate'
import AddSalesPerson from '../../views/AddSalesPerson'
import AddOrganizations from '../../views/AddOrganization'
import Users from '../../views/Users'
import AnalyticsGraph from '../../views/AnalyticsGraph'
import CustomerDashboard from '../../views/CustomerDashboard';
import moment from 'moment';

const Page404 = ({
  location: {
    pathname
  },
  history: { push }
}) => {
  let To = moment().format("YYYY-MM-DD");
  let From = moment(To).subtract(6, 'days').format('YYYY-MM-DD');
  useEffect(() => {
    if (pathname === '/') {
      if(localStorage.userType==='CUSTOMER-ADMIN')
      push(`/html/dashboard.html?userId=${localStorage.userId}&from=${From}&to=${To}`)
      else
      push('/html/logs.html')
    }

  }, [])

  return (
    <div>
      <h4>Seems like you've wandered into unknown land.</h4>

      <a href='/html/logs.html'>Take me home.</a>
    </div>
  )
}

const Main = () => (
  <main>
    <Switch>
      <Route exact path='/admin/login' component={Login} />
      <ProtectedRoute exact path='/html/logs.html' component={ActivityLogs} />
      <ProtectedRoute exact path='/html/analytics.html' component={DetailedAnalytics} />
      <ProtectedRoute path="/html/jobs.html" component={AllJobs} />
      <ProtectedRoute path="/html/jobDetails.html" component={JobDetails} />
      <ProtectedRoute path='/html/score.html' component={ScoreandConnect2} />
      <ProtectedRoute path='/html/add-candidate-form.html' component={AddCandidate} />
      <ProtectedRoute path='/html/add-sales-admin-form.html' component={AddSalesPerson} />
      <ProtectedRoute path='/html/organizations.html' component={AddOrganizations} />
      <ProtectedRoute path='/html/users.html' component={Users} />
      <ProtectedRoute path='/html/graph.html' component={AnalyticsGraph} />
      <ProtectedRoute path='/html/dashboard.html' component={CustomerDashboard} />
      <ProtectedRoute path='/html/organization-graph.html' component={CustomerDashboard} />
     
      <Route component={Page404} />
    </Switch>
  </main>
)

export default Main