import {
    CALL_API
  } from '../middlewares/apiMiddleware'

export const FETCH_JOB = 'FETCH_JOB';
export const FETCH_JOB_SUCCESS = 'FETCH_JOB_SUCCESS';
export const FETCH_JOB_FAILURE = 'FETCH_JOB_FAILURE';

export const fetchJob = (jobId) => {
    return {
      [CALL_API]: {
        types: [FETCH_JOB, FETCH_JOB_SUCCESS, FETCH_JOB_FAILURE],
        endpoint: '/api/auth/admin/single/job',
        options: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
                'Accept': 'application/json'
          },
          body: JSON.stringify({
            jobId
        }),
        }
      }
    }
  }
  