import {
    FETCH_USER_TYPES,
    FETCH_USER_TYPES_SUCCESS,
    FETCH_USER_TYPES_FAILURE
} from '../actions/addUser';

const initialState = {
    userTypesPending: false,
    userTypes: [],
    userTypesError: null,
    isDataAvailable: false
}

export default function userTypes(state = initialState, action) {

    switch (action.type) {
        case FETCH_USER_TYPES:
            return {
                ...state,
                userTypesPending: true,
                isDataAvailable: false,
            }
        case FETCH_USER_TYPES_SUCCESS:
            return {
                ...state,
                userTypesPending: false,
                userTypes: action.payload,
                isDataAvailable: action.payload.length > 0
            }
        case FETCH_USER_TYPES_FAILURE:
            return {
                ...state,
                userTypesPending: false,
                userTypesError: action.error,
                isDataAvailable: false
            }

        default:
            return state;
    }
}


