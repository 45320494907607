import {
    CALL_API
  } from '../middlewares/apiMiddleware'

export const FETCH_JOBS = 'FETCH_JOBS';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_FAILURE = 'FETCH_JOBS_FAILURE';

export const fetchJobs = (params) => {
    return {
      [CALL_API]: {
        types: [FETCH_JOBS, FETCH_JOBS_SUCCESS, FETCH_JOBS_FAILURE],
        endpoint: '/api/auth/admin/list',
        options: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
                'Accept': 'application/json'
          },
          body: JSON.stringify({
            recruiter_id :params.recruiterId
            
        }),
        }
      }
    }
  }
  