import React from 'react'
import ReactLoader from 'react-loader-spinner'

const Loader = ({ color = 'white', height = '24px', width ='24px'}) => {
  return(
    <ReactLoader 
      type={'TailSpin'}
      color={color}
      height={height}
      width={width}
    />
  )
}

export default Loader