import { omit } from 'lodash'
import * as ActionTypes from '../../actions/score'

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: { prospectsArray: [] },
  isLoadingMore: false,
  isBlocked: false,
  shouldFetchAgain: false
}

const list = (state = initialState, action) => {
  const isLoadingMore = (action.meta && action.meta.pageNo) > 1 ? true : false
  switch (action.type) {
    case ActionTypes.FETCH_PROSPECTS:
      return {
        ...state,
        isLoading: !isLoadingMore,
        isLoadingMore: isLoadingMore
      }


    case ActionTypes.FETCH_PROSPECTS_SUCCESS: {

      // console.log('ACTION.PAYLOAD: ', action.payload)
      const isBlocked = action.payload.isBlocked || false
      console.log('isBlocked, ', isBlocked)
      return {
        ...state,
        isLoading: false,
        isLoadingMore: false,
        isBlocked: isBlocked,
        shouldFetchAgain: false,
        data: isBlocked ? { ...initialState.data } : {
          prospectsArray: (parseInt(action.payload.pageNo, 10) === 1) || (action.payload.prospectsArray.length > 100) ?
            action.payload.prospectsArray :
            [...state.data.prospectsArray, ...action.payload.prospectsArray],
          ...omit(action.payload, 'prospectsArray')
        },
        isDataAvailable: isBlocked ? false : state.data.prospectsArray.length > 0 ? true : action.payload.prospectsArray.length > 0
      }
    }

    case ActionTypes.FETCH_PROSPECTS_FAILURE:
      return initialState

    case ActionTypes.ARCHIVE_PROSPECTS_SUCCESS:
    case ActionTypes.UNARCHIVE_PROSPECTS_SUCCESS:
      case ActionTypes.SETDOWNLOADED_PROSPECTS_SUCCESS: {
        return {
          ...state,
          data: {
            ...state.data,
            counts: {...action.payload.counts, currentCount:state.data.counts.currentCount,totalDownloaded: action.payload.counts.totalDownloaded},
            // prospectsArray: ["Added1st", "Home", "SavedAND2ndAdded", "SavedAND1stAdded"].includes(action.payload.activeTab) ? state.data.prospectsArray.filter(item => !action.payload.ids.includes(item.id)) : state.data.prospectsArray,
            totalAddedProspects: state.data.totalAddedProspects - action.payload.length,
            totalProspectsArchived: state.data.totalProspectsArchived + action.payload.length
          },
          shouldFetchAgain: action.shouldFetchAgain
        }
      }


    default:
      return state
  }
}

export default list