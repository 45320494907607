import { omit } from 'lodash'
import {
    FETCH_LOGS,
    FETCH_LOGS_SUCCESS,
    FETCH_LOGS_FAILURE
} from '../actions/activityLogs';

const initialState = {
    isLoadingMore: false,
    logsPending: false,
    logs: [],
    logsError: null,
    totalCount: 0,
    isDataAvailable: false,
    currentPageNo: 1,
    totalDataLeft: 0
}

export default function activityLogs(state = initialState, action) {

    switch (action.type) {

        case FETCH_LOGS:
            return {

                ...state,
                logsPending: true,
                isDataAvailable: false,
                totalDataLeft: 0
            }
        case FETCH_LOGS_SUCCESS:
            if (action.payload.data.pageNo == 1) {
                state.totalDataLeft = 0
                state.logs = []
            }
            return {
                ...state,
                logsPending: false,
                logs: (parseInt(action.payload.data.pageNo, 10) === 1) || (action.payload.data.list.length > 100) ?
                    action.payload.data.list :
                    [...state.logs, ...action.payload.data.list],
                ...omit(action.payload.data, 'list'),
                totalCount: action.payload.data.totalCount,
                totalDataLeft: action.payload.data.totalCount - (state.logs.length+action.payload.data.list.length),
                isDataAvailable: action.payload.data.list.length > 0,
                currentPageNo: action.payload.data.pageNo,

            }
        case FETCH_LOGS_FAILURE:
            return {
                ...state,
                logsPending: false,
                logsError: action.error,
                isDataAvailable: false
            }

        default:
            return state;
    }
}


