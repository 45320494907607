import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { connect } from 'react-redux';
import {
    Button
} from 'reactstrap'
import { Helmet } from 'react-helmet';
import { fetchGraphData } from '../../actions/graphData'
import { fetchAnalytics } from '../../actions/detailedAnalytics'
import qs from 'query-string'
import './style.css'
import Chart from './Chart'
import moment from 'moment'
const AnalyticsGraph = ({
    analytics,
    isUserDataAvailable,
    data,
    isLoading,
    isDataAvailable,
    name,
    email,
    dispatch,
    location: {
        search
    },
    history: {
        push
    }, }) => {
    const [activeTab, setActiveTab] = useState('2');
    const [formDataType, setFormDataType] = useState('scored');
    const [dataKeyXAxis, setDataKeyXAxis] = useState('date')
    const [dataKeyLine, setDataKeyLine] = useState('totalScored')
    
    const userId = qs.parse(search).userId
    const to = qs.parse(search).to
    const from = qs.parse(search).from
    const start_date = from;
    const end_date = to;
    const log = analytics[0]
    // const name=data.name
    // const email=data.email
    const {totalScored,totalReplied,totalMessaged,totalConnected}=data.length>0 ? data[data.length-1] :0
    useEffect(() => {
        const start_date =  moment(from);
        const end_date =moment(to);
        const days=end_date.diff(start_date,'days')+1
        console.log(days)
        switch (days) {
            case 1:
                setActiveTab('1')
                dispatch(fetchAnalytics(start_date, end_date, false, 1, userId, "createdAt"));
                return
            case 7:
                setActiveTab('2')
                dispatch(fetchAnalytics(start_date, end_date, false, 1, userId, "createdAt"));
                return
            case 30:
                setActiveTab('3')
                dispatch(fetchAnalytics(start_date, end_date, false, 1, userId, "createdAt"));
                return
            case 90:
                setActiveTab('4')
                dispatch(fetchAnalytics(start_date, end_date, false, 1, userId, "createdAt"));
                return
            case 180:
                setActiveTab('5')
                dispatch(fetchAnalytics(start_date, end_date, false, 1, userId, "createdAt"));
                return
            case 365:
                setActiveTab('6')  
                dispatch(fetchAnalytics(start_date, end_date, false, 1, userId, "createdAt"));
                return
            default:
                setActiveTab('7')
                 dispatch(fetchAnalytics(start_date, end_date, false, 1, userId, "createdAt"));
                
        }
       
       
    }, []);

    const getStartEndDate = (type) => {
        const currentMonth = moment().month();
        const currentDay = moment().day();
        const currentWeek = moment().week();
        let start_date = null;
        let end_date = null;
        switch (type) {
            case '1':
                start_date = moment().format("YYYY-MM-DD");
                end_date = moment().format("YYYY-MM-DD");
                return { start_date, end_date }

            case '2':
                end_date = moment().format("YYYY-MM-DD");
                start_date = moment(end_date).subtract(6, 'days').format('YYYY-MM-DD');
                return { start_date, end_date }

            case '3':
                end_date = moment().format("YYYY-MM-DD");
                start_date = moment(end_date).subtract(29, 'days').format('YYYY-MM-DD');
                return { start_date, end_date }

            case '4':
                end_date = moment().format("YYYY-MM-DD");
                start_date = moment(end_date).subtract(89, 'days').format('YYYY-MM-DD');
                return { start_date, end_date }

            case '5':
                end_date = moment().format("YYYY-MM-DD");
                start_date = moment(end_date).subtract(179, 'days').format('YYYY-MM-DD');
                return { start_date, end_date }

            case '6':
                end_date = moment().format("YYYY-MM-DD");
                start_date = moment(end_date).subtract(364, 'days').format('YYYY-MM-DD');
                return { start_date, end_date }
            default:
            case '7':
                start_date = moment(analytics[0].createdAt).format("YYYY-MM-DD");
                end_date = moment().format("YYYY-MM-DD");
                return { start_date, end_date }
        }
    }

    const setTimeFrame = (type) => {
        const { start_date, end_date } = getStartEndDate(type)
        // setStartDate(start_date)
        console.log({start_date,end_date})
        push(`/html/graph.html?userId=${userId}&from=${start_date}&to=${end_date}`)
        dispatch(fetchAnalytics(start_date, end_date, false, 1, userId, "createdAt"))
       
    }
    useEffect(() => {
        dispatch(fetchGraphData(qs.parse(search)))
      
      }, [search])

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            setTimeFrame(tab)
         ;}
    }

    const chartToggle = type => {
        if (formDataType !== type) 
        setFormDataType(type);
        if (type === 'scored')
            setDataKeyLine('totalScored')
        if (type === 'messaged')
            setDataKeyLine('totalMessaged')
        if (type === 'replied')
            setDataKeyLine('totalReplied')
        if (type === 'connected')
            setDataKeyLine('totalConnected')
    }

    return (
        <div>
            <Helmet>
                <title>DNNae Admin - Analytics Graph </title>
            </Helmet>
            <Header />
            <div className="customer-analytics-container">
                <div className="customer-analytics-content">
                    <div className="timeFrameGraphContainer">
                        <div className="timeLabelContainer">
                            <h5>{activeTab === '1' ? moment(from).format('MMM D') : 
                                
                                (
                                (activeTab==='6'||activeTab==='7' )
                                ?
                                moment(from).format('MMM D YYYY') + ' - ' + moment(to).format('MMM D YYYY')
                                :
                                moment(from).format('MMM D') + ' - ' + moment(to).format('MMM D'))} </h5>
                        </div>
                        <div className="timeFrameGraphButtons">
                            <Button
                                className={`${activeTab === '1' ? "pillButtonLeftActive " : "pillButtonLeftInActive"} pillButtonLeft`}
                                onClick={() => { toggle('1') }}>
                                Today
                                </Button>
                            <Button
                                className={`${activeTab === '2' ? "pillButtonActive " : "pillButtonInActive"} pillButton`}
                                onClick={() => { toggle('2') }}>7D
                             </Button>
                            <Button className={`${activeTab === '3' ? "pillButtonActive " : "pillButtonInActive"} pillButton`}
                                onClick={() => { toggle('3') }}>30D
                            </Button>
                            <Button
                                className={`${activeTab === '4' ? "pillButtonActive " : "pillButtonInActive"} pillButton`}
                                onClick={() => { toggle('4') }}>3M
                              </Button>
                            <Button className={`${activeTab === '5' ? "pillButtonActive " : "pillButtonInActive"} pillButton`}
                                onClick={() => { toggle('5') }}>6M
                             </Button>
                            <Button
                                className={`${activeTab === '6' ? "pillButtonActive " : "pillButtonInActive"} pillButton`}
                                onClick={() => { toggle('6') }}>1Y
                              </Button>
                            <Button
                                className={`${activeTab === '7' ? "pillButtonRightActive " : "pillButtonRightInActive"} pillButtonRight`}
                                onClick={() => { toggle('7') }}>All Time
                             </Button>
                        </div>
                    </div>
                    <div className="userAnalyticsContent">
                        <div className="userAnalyticsContentInner">
                            <div className="userAnalyticsLeftLabel">
                                <h4 >{name} </h4>
                                <p > {email}</p>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div
                                        className={`${formDataType === 'scored' ? "graphTabActive" : "graphTabInActive"} graphTab userAnalyticsLeftTab`}
                                        onClick={() => { chartToggle('scored') }}>
                                        <span>Scored</span>
                                        <span>{isUserDataAvailable && totalScored >0 ? totalScored:0}</span>
                                    </div>
                                    <div
                                        className={`${formDataType === 'messaged' ? "graphTabActive" : "graphTabInActive"} graphTab userAnalyticsLeftTab`}
                                        onClick={() => { chartToggle('messaged') }}>
                                        <span>Messaged</span>
                                        <span>{isUserDataAvailable&&totalMessaged>=0&&totalScored>=0  ? parseInt(totalMessaged * 100 / totalScored) : 0}%
                                            <span style={{ color: '#A7ABB0' }}>({isUserDataAvailable&&totalMessaged>0 ? totalMessaged : 0})</span></span>
                                    </div>
                                    <div
                                        className={`${formDataType === 'connected' ? "graphTabActive" : "graphTabInActive"} graphTab userAnalyticsLeftTab`}
                                        onClick={() => { chartToggle('connected') }}>
                                        <span>Connected</span>
                                        <span>{isUserDataAvailable && totalConnected>0&&totalMessaged>0  ? parseInt(totalConnected * 100 / totalMessaged) : 0}%
                                            <span style={{ color: '#A7ABB0' }}>({isUserDataAvailable&&totalConnected>0 ? totalConnected : 0})</span></span>
                                    </div>
                                    <div
                                        className={`${formDataType === 'replied' ? "graphTabActive" : "graphTabInActive"} graphTab userAnalyticsLeftTab`}
                                        onClick={() => { chartToggle('replied') }}>
                                        <span>Replied</span>
                                        <span>{isUserDataAvailable&&totalReplied>0 &&totalConnected>0 ? parseInt((totalReplied/ totalConnected) * 100) : 0}%
                                            <span style={{ color: '#A7ABB0' }}>({isUserDataAvailable &&totalReplied>0? totalReplied :0})</span></span>
                                    </div>
                                    <span className="graphLine"></span>
                                    <div className={`graphTabInActive graphTab userAnalyticsGraphDownloads`} style={{marginTop:'10px'}}>
                                        <span>Downloads</span>
                                        <span >{isUserDataAvailable && log.totalDownload>0 ?log.totalDownload:0}</span>
                                    </div>
                                    <div className={`graphTabInActive graphTab userAnalyticsGraphActions`}style={{marginTop:'-10px'}}>
                                        <span>Total Actions</span>
                                        <span >{isUserDataAvailable && log.activityLog >0 ? log.activityLog:0}</span>
                                    </div>
                                    <span className="graphLine"></span>

                                </div>
                            </div>

                        </div>
                        <div className="chartFilterContainer">
                            <div>
                                <Button
                                    className={`${formDataType === 'scored' ? "pillButtonLeftActive " : "pillButtonLeftInActive"} pillButtonLeft`}
                                    onClick={() => { chartToggle('scored') }}>
                                    Scored
                                </Button>
                                <Button
                                    className={`${formDataType === 'messaged' ? "pillButtonActive " : "pillButtonInActive"} pillButton`}
                                    onClick={() => { chartToggle('messaged') }}>Messaged
                             </Button>
                                <Button className={`${formDataType === 'connected' ? "pillButtonActive " : "pillButtonInActive"} pillButton`}
                                    onClick={() => { chartToggle('connected') }}>Connected
                            </Button>
                                <Button
                                    className={`${formDataType === 'replied' ? "pillButtonRightActive " : "pillButtonRightInActive"} pillButtonRight`}
                                    onClick={() => { chartToggle('replied') }}>Replied
                             </Button>
                            </div>
                            <Chart
                                data={data}
                                dataKeyXAxis={dataKeyXAxis}
                                dataKeyBar={formDataType}
                                dataKeyLine={dataKeyLine}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default connect(state => ({
    ...state.graphData,
    analytics: state.detailedAnalytics.analytics,
    isUserDataAvailable: state.detailedAnalytics.isDataAvailable,
    name:state.detailedAnalytics.name,
    email:state.detailedAnalytics.email
}))(AnalyticsGraph)