import { combineReducers } from 'redux'
// import {} from '../../'
// import * as ActionTypes from '../actions'

import auth from './auth'
import activityLogs from './activityLogs'
import detailedAnalytics from './detailedAnalytics'
import jobs from './jobs'
import editJob from './editJob'
import advancedFilter from './advancedFilter'
// import score from './score'
import selected from './selected'
import activityTypes from './activityTypes'
import users from './users'
import userTypes from './userTypes'
import addUser from './addUser'
import addLead from './addLead'
import organizations from './organizations'
import score from './score'
import addAdmin from './addAdmin'
import orgUsers from './orgUsers'
import graphData from './graphData'
import organizationAnalytics from './organizationAnalytics'
export default combineReducers({
  auth,
  activityLogs,
  detailedAnalytics,
  jobs,
  editJob,
  advancedFilter,
  score,
  selected,
  activityTypes,
  users,
  userTypes,
  addUser,
  addLead,
  organizations,
  addAdmin,
  orgUsers,
  graphData,
  organizationAnalytics
})