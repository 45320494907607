import React, { useState } from 'react';
import { DropdownToggle, Dropdown, DropdownMenu, DropdownItem, Label } from 'reactstrap';
import { ReactComponent as ReactUserIcon } from '../user.svg';
import { ReactComponent as ReactLogoutIcon } from '../logout-icon.svg';
const Sort = ({ logout }) => {
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    return (
        <div className="user-logout-container">
            <ReactUserIcon className="logo" />
            < Dropdown  isOpen={dropdownOpen} onClick={toggle} toggle={toggle} >
                <DropdownToggle
                    tag="span"
                    onClick={toggle}
                    data-toggle="dropdown"
                    aria-expanded={dropdownOpen}
                >
                    <Label className="username"> Hi, {localStorage.getItem('userName')}</Label>
                    <ReactLogoutIcon className="logout-icon" />
                </DropdownToggle>
                <DropdownMenu className="logout-z-index">
                    <DropdownItem size="sm" onClick={logout} className="logout-button">Logout</DropdownItem>
                </DropdownMenu>
            </Dropdown >
        </div>
    )
}
export default Sort;