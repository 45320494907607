import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { ReactComponent as ReactLogo } from '../full.svg';
import { logout } from '../../actions/auth'
import Logout from '../Logout'
import './style.css'
import moment from 'moment'
const Header = ({ dispatch }) => {
    let To = moment().format("YYYY-MM-DD");
    let From = moment(To).subtract(6, 'days').format('YYYY-MM-DD');
    return (
        <header className="headerr sticky">
            <nav className="header-inner">
                <Link to={localStorage.getItem('userType') === 'CUSTOMER-ADMIN' ? `/html/dashboard.html?userId=${localStorage.userId}&from=${From}&to=${To}` : "/html/logs.html"}>
                    <ReactLogo className="logo"  />
                    </Link>

                {
                    localStorage.getItem('userType') === 'CUSTOMER-ADMIN' &&
                    <Link className="link" to={`/html/dashboard.html?userId=${localStorage.userId}&from=${From}&to=${To}`} >Dashboard</Link>
                }
                <Link className="link" to="/html/logs.html" > Activity Logs</Link>
                <Link className="link" to="/html/analytics.html" >Detailed Analytics</Link>


                {localStorage.getItem('userType') === 'ADMIN'
                    ?
                    <>
                        <Link className="link" to="/html/add-sales-admin-form.html" >Add Admin</Link>
                        <Link className="link" to="/html/organizations.html" >Organizations</Link>
                    </>
                    :
                    localStorage.getItem('userType') === 'SALES-ADMIN' && <Link className="link" to="/html/add-candidate-form.html" >Add Prospect</Link>
                }


                {/* <Button onClick={() => { dispatch(logout()) }} color="danger">Logout</Button> */}
                <Logout logout={() => { dispatch(logout()) }} />

            </nav>
        </header>
    );
};
export default connect()(Header);