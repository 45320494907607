import {
    FETCH_ORGANIZATION_DATA,
    FETCH_ORGANIZATION_DATA_SUCCESS,
    FETCH_ORGANIZATION_DATA_FAILURE
} from '../actions/organizationAnalytics';

const initialState = {
    isLoading: false,
    data: [],
    error: null,
    isDataAvailable: false
}

export default function users(state = initialState, action) {

    switch (action.type) {
        case  FETCH_ORGANIZATION_DATA:
            return {
                ...state,
                isLoading: true,
                isDataAvailable: false,
            }
        case  FETCH_ORGANIZATION_DATA_SUCCESS:
            
            return {
                ...state,
                isLoading: false,
                data: action.payload.data.list,
                isDataAvailable: action.payload.data.list.length > 0
            }
        case  FETCH_ORGANIZATION_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                isDataAvailable: false
            }

        default:
            return state;
    }
}


