import {
    ADD_LEAD,
    ADD_LEAD_SUCCESS,
    ADD_LEAD_FAILURE
} from '../actions/addLead';

import * as ActionTypes from '../actions/addLead'

const initialState = {
   isLoading: false,
    isDataAvailable: false,
    message:'',
    leads: [],
    isLoadingLeads: false,
    isDeleting: false,
    deleting: [],
    
}

export default function addLead(state = initialState, action) {

    switch (action.type) {
        case  ADD_LEAD:
            return {
                ...state,
                isLoading:true,
                isDataAvailable: false,
                message:''
            }
        case  ADD_LEAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            }
        case   ADD_LEAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message,
                isDataAvailable: false
            }

        case ActionTypes.FETCH_LEADS:
            return {...state, message:'',isLoadingLeads: true,isDeleting:false}

        case ActionTypes.FETCH_LEADS_SUCCESS:
            return {...state,  message:'',isLoadingLeads: false, isDeleting:false,leads: action.payload.leads}

        case ActionTypes.FETCH_LEADS_FAILURE:
            return {...state, isLoadingLeads: false, leads: []}


            case ActionTypes.DELETE_LEAD:
                return {
                    ...state, 
                    isDeleting: true,
                    deleting: [...state.deleting, action.payload]
                }

            case ActionTypes.DELETE_LEAD_SUCCESS:
            case ActionTypes.DELETE_LEAD_FAILURE:
                    return {
                        ...state, 
                        isDeleting: false,
                        deleting: state.deleting.filter(item => item != action.payload)
                    }

        default:
            return state;
    }
}


