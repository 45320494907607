import React from 'react';
import Sort from '../../../components/Sort';
import TimeFrameFilter from '../../../components/TimeFrameFilter'
import UsersAuto from '../UsersAuto'
import EmailSearchAuto from '../EmailSearchAuto'
const Filter = ({
    // submit,
    // startDateChange,
    // endDateChange,
    users,
    sortBy,
    endDate,
    startDate,
    organization_id,
    setTimeFrame,
    UserTextBox,
    setUserTextBox,
    getUserActivitiesName,
    getUserActivitiesEmail,
    EmailTextBox,
    setEmailTextBox
}) => {
    // const disabled = startDate.length < 1 || endDate.length < 1
    return (
        <div className="users-date-filters">
            <div className="users-date-filter-inner">
                <div style={{ display: 'flex' }}>
                    <UsersAuto
                        organization_id={organization_id}
                        users={users}
                        UserTextBox={UserTextBox}
                        setUserTextBox={setUserTextBox}
                        getUserActivities={getUserActivitiesName} />
                    <EmailSearchAuto
                        organization_id={organization_id}
                        users={users}
                        EmailTextBox={EmailTextBox}
                        setEmailTextBox={setEmailTextBox}
                        getUserActivities={getUserActivitiesEmail} />
                </div>
                {/* <p className="date-filter-title">Showing data from</p>
                <Form inline id="filter-form">
                    <FormGroup >
                        <Col className="start-date-text" sm={{  offset: 1 }}>
                            <Input  size="sm" style={{width:'120px'}} onChange={startDateChange} type="text" name="startDate" id="startDate" value={startDate}placeholder="YYYY-MM-DD" />
                        </Col>
                    </FormGroup>
                    <p className="end-date-text">to</p>
                    <FormGroup>
                        <Col sm={{ offset: 1 }}>
                            <Input  size="sm" style={{width:'120px'}} onChange={endDateChange} type="text" name="endDate" value={endDate}id="endDate" placeholder="YYYY-MM-DD" />
                        </Col>
                    </FormGroup>
                    
                    <Button size="sm" className="apply-filter-button"outline onClick={submit} disabled={disabled} color="primary">Apply Filter</Button>
                    {!disabled &&<Button size="sm" className="reset-filter-form"outline onClick={reset} color="danger">Reset</Button>}
                </Form> */}
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '450px' }}>
                    <TimeFrameFilter setTimeFrame={setTimeFrame} />
                    <Sort sortBy={sortBy} />
                </div>
            </div>
        </div>
    )
}
export default Filter;