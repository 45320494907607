import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import { fetchJob } from '../../actions/jobDetails'
import qs from 'query-string';
import '../../components/JobDescription/JobDescription.css'
import Header from '../../components/Header'
import ReviseJd from '../../components/ReviseJd'
import Loader from '../../components/Loader'

import { Helmet } from 'react-helmet';

const JobDetails = ({
  dispatch,
  editJob,
  job,
  match: { params },
  location: {
    search
},
  ...rest
}) => {

const jobId=qs.parse(search).jobId

  useEffect(() => {
    dispatch(fetchJob(jobId))
  }, [])

  // console.log('rest props are: ', rest)
  // console.log('query string params are: ',)s

  //   const heading = auth.isAuthenticated ? `Welcome back, ${auth.user.name.split(' ')[0]}` :
  //     `Welcome aboard`

  //     const message = ``
  // const message = user.isDataAvailable ? `Let's get started with your ${user.data.isPopupOpened ? '' : 'first'} prospect search!` :
  //   `Let's get started with your first prospect search`
  return (
    <div>
      <Helmet>
        <title>DNNae Admin - Job Criteria</title>
      </Helmet>
      <Header />

      {/* <div className='jd-headerContainer'>
        <h1 className="jd-heading">{heading}</h1>
        <p className='jd-message'>{message}</p>
        <JdSteps onStep={editJob.step} />
        <hr className='jd-bottomBorder' />
      </div> */}

      <Container className='jd-container'>

        <div className='jd-content'>
          {editJob.isLoading && 
          <div className="spinner"><Loader color='blue' height='100px' width='100px'/>
         </div> 
          }
          {(!editJob.isLoading && editJob.isDataAvailable) &&
            <React.Fragment>
              {editJob.step === 2 &&
                <ReviseJd
                  editMode={true}
                  jobId={jobId}
                />
              }
            </React.Fragment>
          }
        </div>
      </Container>
    </div>
  )
}

export default connect(state => ({
  job: state.editJob.job,
  editJob: state.editJob
}))(JobDetails)