import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const JobDescription = ({ toggle, modal, title, description }) => {
  return (
    <div>
      <Modal placement='bottom' isOpen={modal} toggle={toggle} className='job-description-modal' centered>
        <ModalHeader toggle={toggle} >
          <p className='job-description-modal-text-header'>
            {title}
          </p>
        </ModalHeader>
        <ModalBody>
          <p className="job-description-modal-text">
            {description}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default JobDescription;