import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { connect } from 'react-redux';
import {
    Button
} from 'reactstrap'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { fetchAnalytics, fetchUserOrganizationAnalytics } from '../../actions/detailedAnalytics'
import { fetchOrganizationAnalytics } from '../../actions/organizationAnalytics'
import qs from 'query-string'
import './style.css'
import Chart from './Chart'
import moment from 'moment'
const AnalyticsGraph = ({
    analytics,
    isUserDataAvailable,
    data,
    // isLoading,
    isDataAvailable,
    dispatch,
    location: {
        search
    },
    history: {
        push
    }, }) => {
    const [activeTab, setActiveTab] = useState('2');
    const [formDataType, setFormDataType] = useState('scored');
    const [dataKeyXAxis, setDataKeyXAxis] = useState('date')
    const [dataKeyLine, setDataKeyLine] = useState('totalScored')
    const [timeKey, setTimeKey] = useState('in last 7 days')
    const [sortBy, setSortBy] = useState('totalScored')
    const userId = qs.parse(search).userId
    const organization_id = qs.parse(search).organization_id
    const to = qs.parse(search).to
    const from = qs.parse(search).from

    const getStartEndDate = (type) => {
        // const currentMonth = moment().month();
        // const currentDay = moment().day();
        // const currentWeek = moment().week();
        let start_date = null;
        let end_date = null;
        switch (type) {
            case '1':
                start_date = moment().format("YYYY-MM-DD");
                end_date = moment().format("YYYY-MM-DD");
                setTimeKey(' today')
                return { start_date, end_date }

            case '2':
                end_date = moment().format("YYYY-MM-DD");
                start_date = moment(end_date).subtract(6, 'days').format('YYYY-MM-DD');
                setTimeKey('in last 7 days')
                return { start_date, end_date }

            case '3':
                end_date = moment().format("YYYY-MM-DD");
                start_date = moment(end_date).subtract(29, 'days').format('YYYY-MM-DD');
                setTimeKey('in last 30 days')
                return { start_date, end_date }

            case '4':
                end_date = moment().format("YYYY-MM-DD");
                start_date = moment(end_date).subtract(89, 'days').format('YYYY-MM-DD');
                setTimeKey('in last 3 months')
                return { start_date, end_date }


            case '5':
                end_date = moment().format("YYYY-MM-DD");
                start_date = moment(end_date).subtract(179, 'days').format('YYYY-MM-DD');
                setTimeKey('in last 6 months')
                return { start_date, end_date }

            case '6':
                end_date = moment().format("YYYY-MM-DD");
                start_date = moment(end_date).subtract(364, 'days').format('YYYY-MM-DD');
                setTimeKey('in last year')
                return { start_date, end_date }
            default:
            case '7':
                end_date = moment().format("YYYY-MM-DD");
                start_date = moment(end_date).subtract(364, 'days').format('YYYY-MM-DD');

                setTimeKey('in all time')
                return { start_date, end_date }
        }
    }

    const setTimeFrame = (type) => {
        const { start_date, end_date } = getStartEndDate(type)
        console.log({ start_date, end_date })

        if (localStorage.userType === 'CUSTOMER-ADMIN') {
            dispatch(fetchAnalytics(start_date, end_date, false, 1, "", sortBy));
            push(`/html/dashboard.html?userId=${userId}&from=${start_date}&to=${end_date}`)
        }
        else {
            dispatch(fetchUserOrganizationAnalytics(start_date, end_date, 1, organization_id ,sortBy))
            push(`/html/organization-graph.html?organization_id=${organization_id}&from=${start_date}&to=${end_date}`)
        }

    }

    useEffect(() => {
        dispatch(fetchOrganizationAnalytics(qs.parse(search)))
    }, [search])

    useEffect(() => {
        localStorage.userType === 'CUSTOMER-ADMIN' ?
            dispatch(fetchAnalytics(from, to, false, 1, "", sortBy))
            :
            dispatch(fetchUserOrganizationAnalytics(from, to,  1, organization_id,sortBy))
    }, [formDataType])

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            setTimeFrame(tab)
        }
    }

    const chartToggle = type => {
        if (formDataType !== type)
            setFormDataType(type);
        if (type === 'scored') {
            setDataKeyLine('totalScored')
            setSortBy('totalScored')
        }
        if (type === 'messaged') {
            setDataKeyLine('totalMessaged')
            setSortBy('totalSent')
        }
        if (type === 'replied') {
            setDataKeyLine('totalReplied')
            setSortBy('totalReplied')
        }
        if (type === 'connected') {
            setDataKeyLine('totalConnected')
            setSortBy('totalAccepted')
        }
    }

const final = data.length > 0 ? data[data.length - 1] : { totalScored: 0, totalMessaged: 0, totalConnected: 0, totalAccepted: 0, totalDownload: 0, totalActivityLog: 0 }
    return (
        <div>
            <Helmet>
                <title>DNNae Admin - Dashboard </title>
            </Helmet>
            <Header />
            <div className="customer-analytics-containerDashboard">
                <div className="customer-dashboard-titleDashboard">
                    <p>{ localStorage.userType === 'CUSTOMER-ADMIN' ? 'Dashboard': data.length>0?data[0].name:'... '}</p>
                </div>
                <div className="customer-analytics-contentDashboard">
                    <div className="titleContainerDashboard">
                        <h5>Team Stats</h5>
                        <div className="timeFrameGraphContainerDashboard">

                            <div className="timeLabelContainerDashboard">
                                <h5>{activeTab === '1' ? moment(to).format('MMM D') :

                                    (
                                        (activeTab === '6' || activeTab === '7')
                                            ?
                                            moment(from).format('MMM D YYYY') + ' - ' + moment(to).format('MMM D YYYY')
                                            :
                                            moment(from).format('MMM D') + ' - ' + moment(to).format('MMM D'))} </h5>
                            </div>
                            <div className="timeFrameGraphButtonsDashboard">
                                <Button
                                    className={`${activeTab === '1' ? "pillButtonLeftActiveDashboard " : "pillButtonLeftInActiveDashboard"} pillButtonLeftDashboard`}
                                    onClick={() => { toggle('1') }}>
                                    Today
                                </Button>
                                <Button
                                    className={`${activeTab === '2' ? "pillButtonActiveDashboard " : "pillButtonInActiveDashboard"} pillButtonDashboard`}
                                    onClick={() => { toggle('2') }}>7D
                             </Button>
                                <Button className={`${activeTab === '3' ? "pillButtonActiveDashboard" : "pillButtonInActiveDashboard"} pillButtonDashboard`}
                                    onClick={() => { toggle('3') }}>30D
                            </Button>
                                <Button
                                    className={`${activeTab === '4' ? "pillButtonActiveDashboard" : "pillButtonInActiveDashboard"} pillButtonDashboard`}
                                    onClick={() => { toggle('4') }}>3M
                              </Button>
                                <Button className={`${activeTab === '5' ? "pillButtonActiveDashboard" : "pillButtonInActiveDashboard"} pillButtonDashboard`}
                                    onClick={() => { toggle('5') }}>6M
                             </Button>
                                <Button
                                    className={`${activeTab === '6' ? "pillButtonActive " : "pillButtonInActive"} pillButton`}
                                    onClick={() => { toggle('6') }}>1Y
                              </Button>
                                <Button
                                    className={`${activeTab === '7' ? "pillButtonRightActive " : "pillButtonRightInActive"} pillButtonRight`}
                                    onClick={() => { toggle('7') }}>All Time
                             </Button>
                            </div>
                        </div>
                    </div>
                    <div className="userAnalyticsContentDashboard">
                        <div className="userAnalyticsContentInnerDashboard">
                            <div className="userAnalyticsLeftLabelDashboard">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div
                                        className={`${formDataType === 'scored' ? "graphTabActiveDashboard" : "graphTabInActiveDashboard"} graphTabDashboard userAnalyticsLeftTabDashboard`}
                                        onClick={() => { chartToggle('scored') }}>
                                        <span>Scored</span>
                                        <span>{isDataAvailable && final.totalScored > 0 ? final.totalScored : 0}</span>
                                    </div>
                                    <div
                                        className={`${formDataType === 'messaged' ? "graphTabActiveDashboard" : "graphTabInActiveDashboard"} graphTabDashboard userAnalyticsLeftTabDashboard`}
                                        onClick={() => { chartToggle('messaged') }}>
                                        <span>Messaged</span>
                                        <span>{isDataAvailable && final.totalMessaged > 0 && final.totalScored > 0 ? parseInt(final.totalMessaged * 100 / final.totalScored) : 0}%
                                            <span style={{ color: '#A7ABB0' }}>({isDataAvailable ? final.totalMessaged : 0})</span></span>
                                    </div>
                                    <div
                                        className={`${formDataType === 'connected' ? "graphTabActiveDashboard" : "graphTabInActiveDashboard"} graphTabDashboard userAnalyticsLeftTabDashboard`}
                                        onClick={() => { chartToggle('connected') }}>
                                        <span>Connected</span>
                                        <span>{isDataAvailable && final.totalConnected > 0 && final.totalMessaged > 0 ? parseInt(final.totalConnected * 100 / final.totalMessaged) : 0}%
                                            <span style={{ color: '#A7ABB0' }}>({isDataAvailable ? final.totalConnected : 0})</span></span>
                                    </div>
                                    <div
                                        className={`${formDataType === 'replied' ? "graphTabActiveDashboard" : "graphTabInActiveDashboard"} graphTabDashboard userAnalyticsLeftTabDashboard`}
                                        onClick={() => { chartToggle('replied') }}>
                                        <span>Replied</span>
                                        <span>{isDataAvailable && final.totalReplied > 0 && final.totalConnected > 0 ? parseInt((final.totalReplied / final.totalConnected) * 100) : 0}%
                                            <span style={{ color: '#A7ABB0' }}>({isDataAvailable ? final.totalReplied :0 })</span></span>
                                    </div>
                                    <span className="graphLine"></span>
                                    <div className={`graphTabInActiveDashboard graphTabDashboard userAnalyticsGraphDownloadsDashboard`} style={{ marginTop: '10px' }}>
                                        <span>Downloads</span>
                                        <span >{isDataAvailable && final.totalDownload > 0 ? final.totalDownload : 0}</span>
                                    </div>
                                    <div className={`graphTabInActiveDashboard graphTabDashboard userAnalyticsGraphActionsDashboard`} style={{ marginTop: '-10px' }}>
                                        <span>Total Actions</span>
                                        <span >{isDataAvailable && final.totalActivityLog > 0 ? final.totalActivityLog : 0}</span>
                                    </div>
                                    <span className="graphLineDashboard"></span>
                                </div>
                            </div>

                        </div>
                        <span className="dashboardLine" />
                        <div className="chartFilterContainerDashboard">
                            <div>
                                <Button
                                    className={`${formDataType === 'scored' ? "pillButtonLeftActiveDashboard" : "pillButtonLeftInActiveDashboard"} pillButtonLeftDashboard`}
                                    onClick={() => { chartToggle('scored') }}>
                                    Scored
                                </Button>
                                <Button
                                    className={`${formDataType === 'messaged' ? "pillButtonActiveDashboard " : "pillButtonInActiveDashboard"} pillButtonDashboard`}
                                    onClick={() => { chartToggle('messaged') }}>Messaged
                             </Button>
                                <Button className={`${formDataType === 'connected' ? "pillButtonActiveDashboard " : "pillButtonInActiveDashboard"} pillButtonDashboard`}
                                    onClick={() => { chartToggle('connected') }}>Connected
                            </Button>
                                <Button
                                    className={`${formDataType === 'replied' ? "pillButtonRightActiveDashboard " : "pillButtonRightInActiveDashboard"} pillButtonRightDashboard`}
                                    onClick={() => { chartToggle('replied') }}>Replied
                             </Button>
                            </div>
                            <Chart
                                data={data}
                                dataKeyXAxis={dataKeyXAxis}
                                dataKeyBar={formDataType}
                                dataKeyLine={dataKeyLine}
                            />
                        </div>
                        <span className="dashboardLine" />
                        <div className="userAnalyticsRightLabelDashboard">

                            <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 20px 5px 20px', borderBottom: '1px solid #E6E9ED' }}>
                                <h5>Team ranking {timeKey}</h5>
                                <p style={{ color: '#A7ABB0' }}>By most prospects {formDataType}</p>
                            </div>
                            <div style={{ overflow: 'auto', maxHeight: '350px' }}>
                                {isUserDataAvailable && analytics.length > 0 &&
                                    <>
                                        {analytics.map((log, index) => (
                                            <div key={index}>
                                                <div className={`graphTabInActiveDashboard graphTabDashboard userAnalyticsGraphDownloadsDashboard`} style={{ padding: '30px' }}>
                                                    <h6>{log.name}</h6>
                                                    <h6 >{log[sortBy]}</h6>
                                                </div>
                                                <div style={{ display: 'flex', paddingLeft: '30px', marginTop: '-20px' }}>
                                                    <span>

                                                        <Link style={{ fontSize: '15px' }}
                                                            to={{
                                                                pathname: '/html/jobs.html',
                                                                search: '?recruiterId=' + log.id
                                                            }}
                                                            target="_blank">View Jobs
                                                   </Link>
                                                        <span style={{ color: '#A7ABB0', marginLeft: '10px', fontSize: '12px' }}>|</span>
                                                        <Link style={{ fontSize: '15px', marginLeft: '10px' }}
                                                            to={{
                                                                pathname: '/html/graph.html',
                                                                search: `?userId=${log.id}&to=${to}&from=${from}`
                                                            }}
                                                            target="_blank" >View Graph</Link>
                                                    </span>
                                                </div>
                                            </div>

                                        ))}

                                    </>
                                }
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default connect(state => ({
    ...state.organizationAnalytics,
    analytics: state.detailedAnalytics.analytics,
    isUserDataAvailable: state.detailedAnalytics.isDataAvailable
}))(AnalyticsGraph)