import {
    CALL_API
  } from '../middlewares/apiMiddleware'
  import { manualApiCall } from '../utils';
export const FETCH_ANALYTICS = 'FETCH_ANALYTICS';
export const FETCH_ANALYTICS_SUCCESS = 'FETCH_ANALYTICS_SUCCESS';
export const FETCH_ANALYTICS_FAILURE = 'FETCH_ANALYTICS_FAILURE';

export const BLOCK_USER = 'BLOCK_USER';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAILURE = 'BLOCK_USER_FAILURE';

export const FETCH_TEAM_RANK_ANALYTICS = 'FETCH_TEAM_RANK_ANALYTICS';
export const FETCH_TEAM_RANK_ANALYTICS_SUCCESS = 'FETCH_TEAM_RANK_ANALYTICS_SUCCESS';
export const FETCH_TEAM_RANK_ANALYTICS_FAILURE = 'FETCH_TEAM_RANK_ANALYTICS_FAILURE';

export const fetchAnalytics = (startDate,endDate,isBlocking,pageNo,userId,sortby) => {
    return {
      [CALL_API]: {
        types: [FETCH_ANALYTICS, FETCH_ANALYTICS_SUCCESS, FETCH_ANALYTICS_FAILURE],
        endpoint: '/api/auth/admin/analytics',
        options: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
                'Accept': 'application/json'
          },
          body: JSON.stringify({
            startDate,
            endDate,
            pageNo,
            userId,
            sortby
        }),
        }
      }
    }
  }

  export const fetchUserOrganizationAnalytics = (startDate,endDate,pageNo,organization_id,sortby) => {
    return {
      [CALL_API]: {
        types: [FETCH_TEAM_RANK_ANALYTICS, FETCH_TEAM_RANK_ANALYTICS_SUCCESS, FETCH_TEAM_RANK_ANALYTICS_FAILURE],
        endpoint: '/api/auth/admin/organization/users/analytics',
        options: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
          body: JSON.stringify({
            startDate,
            endDate,
            pageNo,
            organization_id,
            sortby
        }),
        }
      }
    }
  }


export const blockUser = (status,userId) => async (dispatch) => {
try {
    dispatch({
        type: BLOCK_USER,
        payload:userId
       
    })

    await manualApiCall('/api/auth/admin/user/block', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
            'Accept': 'application/json'
      },
      body: JSON.stringify({
        status,
        userId
        
    })
    })

    dispatch({type: BLOCK_USER_SUCCESS,payload:userId})

} catch (e){
    dispatch({type: BLOCK_USER_FAILURE,payload:userId})
}
}

