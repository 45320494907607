import * as ActionTypes from '../../actions/jobDetails'

const initialState = {
    isLoading: false,
    isDataAvailable: false,
    isUpdating: false,
    isUpated: false,
    step: 2,
    jobData: {},
    value: '',
    locationState: []
}

const editJob = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.FETCH_JOB:
            return { ...state, isLoading: true }

        case ActionTypes.FETCH_JOB_SUCCESS:
            const locs = action.payload.data[0].jobArray.filter(item => item.tag === 'JOB_LOCATION')
            const locactions = locs.length > 0 ? locs[0].data : []
            return {
                ...state, isLoading: false, isDataAvailable: true,
                jobData: action.payload.data[0],
                additionalTitles: action.payload.data[0].jobArray[4].data,
                importantSkills: action.payload.data[0].jobArray[9].data,
                // additionalSkills:,
                experience: action.payload.data[0].jobArray[13].data,
                industries: action.payload.data[0].jobArray[1].data,
                education: action.payload.data[0].jobArray[0].data,
                value: '',
                locationState: locactions
            }

        case ActionTypes.FETCH_JOB_FAILURE:
            return { ...initialState }


        default:
            return state
    }
}

export default editJob