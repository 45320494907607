import {
    FETCH_USERS,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE
} from '../actions/activityLogs';

const initialState = {
    usersPending: false,
    users: [],
    usersError: null,
    isDataAvailable: false
}

export default function users(state = initialState, action) {

    switch (action.type) {
        case FETCH_USERS:
            return {
                ...state,
                usersPending: true,
                isDataAvailable: false,
            }
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                usersPending: false,
                users: action.payload.data,
                isDataAvailable: action.payload.data.length > 0
            }
        case FETCH_USERS_FAILURE:
            return {
                ...state,
                usersPending: false,
                usersError: action.error,
                isDataAvailable: false
            }

        default:
            return state;
    }
}


