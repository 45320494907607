import React, { PureComponent } from 'react';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Scatter,
    ResponsiveContainer,
} from 'recharts';


import moment from 'moment'

const Chart = ({ data, dataKeyXAxis, dataKeyBar, dataKeyLine }) => {

    const formatedData = data.map(item => ({ 
        ...item, 
        [dataKeyBar]: parseInt(item[dataKeyBar]),
        [dataKeyLine]: parseInt(item[dataKeyLine]),  
        "date": moment(item.date).format('MMM D') }))

    console.log(formatedData)
    return (
        <ResponsiveContainer>
            <ComposedChart
                data={formatedData}
                margin={{
                    top: 40,
                    right: 20,
                    bottom: 20,
                    left: 0,
                }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey={dataKeyXAxis} />
                <YAxis range={[0, 1000000]}/>
                <Tooltip />
                <Legend />
                <Bar dataKey={dataKeyBar} barSize={20} fill="#CDD1D7" />
                <Line type="monotone" dataKey={dataKeyLine} stroke="#297AF7" />
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export default Chart;