import {
    FETCH_ACTIVITY_TYPES,
    FETCH_ACTIVITY_TYPES_SUCCESS,
    FETCH_ACTIVITY_TYPES_FAILURE} from '../actions/activityLogs';

const initialState = {
    activityTypesPending: false,
    activityTypes: [],
    activityTypesError: null,
    totalCount:0,
    isDataAvailable:false
}

export default function activityTypes(state = initialState, action) {

    switch(action.type) {
        case FETCH_ACTIVITY_TYPES: 
            return {
                ...state,
                activityTypesPending: true,
                isDataAvailable:false,
            }
        case  FETCH_ACTIVITY_TYPES_SUCCESS:
            return {
                ...state,
                activityTypesPending: false,
                activityTypes:action.payload.data,
               isDataAvailable: action.payload.data.length > 0
            }
        case  FETCH_ACTIVITY_TYPES_FAILURE:
            return {
                ...state,
                activityTypesPending: false,
                activityTypesError: action.error,
                isDataAvailable:false
            }
         
        default: 
            return state;
    }
}


