import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { Waypoint } from 'react-waypoint'
import { Spinner, Table } from 'reactstrap';
// import JobCard from './JobCard';
import { fetchActivityLogs } from '../../actions/activityLogs';
import { Helmet } from 'react-helmet';
// import { set } from 'lodash';
import { connect } from 'react-redux';
import ActivityTypesAuto from '../../components/ActivityTypesAuto'
import UsersAuto from '../../components/UsersAuto'
import './style.css'
import moment from 'moment'
const ActivityLogs = ({ logs, logsPending, totalDataLeft, isDataAvailable, dispatch }) => {
    const [pageNo, setPageNo] = useState(1);
    const [actionType, setActionType] = useState('')
    const [userId, setUserId] = useState('')

    useEffect(() => {
        dispatch(fetchActivityLogs(pageNo, actionType != '' ? actionType : null, userId != '' ? userId : null))
    }, [pageNo, actionType, userId]);

    const handleWaypointOnEnter = () => {
        setPageNo((parseInt(pageNo, 10) + 1))
    }
    const sortBy = (type) => {
        setPageNo(1)
        setActionType(type)
    }
    const getUserActivities = (userId) => {
        console.log(userId)
        setPageNo(1)
        setUserId(userId)
    }
    
    const shouldRenderWaypoint = isDataAvailable ? totalDataLeft > 0 : false
    // const searchInputChange= e => {
    //     setInput(e.target.value);
    //     globalSearch();
    // }
    // const globalSearch =()=>{
    //     const filteredData = logs.filter(log => {
    //         return (
    //           log.name.toLowerCase().includes(input.toLowerCase()) ||
    //           log.email.toLowerCase().includes(input.toLowerCase()) ||
    //           log.activity.toLowerCase().includes(input.toLowerCase())||
    //           log.createdAt.toLowerCase().includes(input.toLowerCase())
    //         );
    //       });
    //       setSearchedData(filteredData)
    //      if(input!=='') setIsSearching(true)
    //      else
    //      setIsSearching(false)
    // }
    return (
        <div>
            <Helmet>
                <title>DNNae Admin - Activity Logs </title>
            </Helmet>
            <Header />
            <div className="activity-types" >
                <UsersAuto getUserActivities={getUserActivities} />
                <ActivityTypesAuto sortBy={sortBy} />
            </div>
            {/* <div className="activity-types" >
            <Users getUserActivities={getUserActivities}/>
        </div> */}
            <div className="logs-content">
                {
                    logsPending && pageNo == 1 ?
                        <div className="spinner">
                            <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
                        </div>
                        :
                        logs &&
                            logs.length > 0
                            ?
                            <div>
                                <Table striped size='sm' className="logs-table" >
                                    <thead>
                                        <tr>
                                            <th >#</th>
                                            <th >Name</th>
                                            <th >Email</th>
                                            <th >Activity Title</th>
                                            <th style={{ textAlign: 'right' }}>Activity Time-(Central)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            logs.map((log, index) => (
                                                <tr key={index}>
                                                    <th style={{ width: '100px', wordBreak: 'break-word' }} scope="row">{index + 1}</th>
                                                    <td style={{ width: '200px', wordBreak: 'break-word' }}>{log.name}</td>
                                                    <td style={{ width: '300px', wordBreak: 'break-word' }}>{log.email}</td>
                                                    <td style={{ width: '400px', fontSize: '15px', wordBreak: 'break-word' }}>{log.activity}</td>
                                                    <td style={{ width: '180px', fontSize: '15px' }}>
                                                        <span className="analytics-column-data-flow">{moment(log.createdAt).utcOffset('-06:00').format('YYYY MM DD')  }</span>
                                                        <br />
                                                        <span className="analytics-column-data-flow">{moment(log.createdAt).utcOffset('-06:00').format('HH:MM:SS')   }</span>
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                    {shouldRenderWaypoint &&
                                        <Waypoint color="primary"
                                            fireOnRapidScroll={true}
                                            onEnter={handleWaypointOnEnter}
                                        />
                                    }
                                </Table>

                            </div>
                            :
                            !logsPending &&
                            <p className="spinner">No Logs Found</p>
                }
                {logsPending && pageNo != 1 &&
                    <div className="spinner">
                        <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
                    </div>
                }
            </div>
        </div>
    );
};

export default connect(state => ({
    logsError: state.activityLogs.logsError,
    logs: state.activityLogs.logs,
    logsPending: state.activityLogs.logsPending,
    isDataAvailable: state.activityLogs.isDataAvailable,
    totalDataLeft: state.activityLogs.totalDataLeft
}))(ActivityLogs)