import Autosuggest from 'react-autosuggest'
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormGroup} from 'reactstrap';
import { fetchActivityTypes } from '../../actions/activityLogs';
import './style.css'
const ActivityTypesAuto = ({ sortBy, activityTypes, dispatch }) => {
    // const [dropdownOpen, setOpen] = useState(false);
    // const [dropDownValue, setDropDownValue] = useState("");
    // const [activitySuggestions, setActivitySuggestions] = useState([])
    const [ActivityTextBox, setActivityTextBox] = useState('')
    const [suggestions,setSuggestions]=useState([])
    const onActivitySuggestionsClearRequested = () => setSuggestions([])
 
    // const typesForCustomer=[
    //     'APPLY_ADVANCED_FILTER_RELEVANT_EXPERIENCE_FILTER', 
    //     'MESSAGE_SENT_FILTER',
    //     'FIRST_SORT_INDUSTRY_SCORE',
    //     'SECOND_SORT_SKILL_SCORE',
    //     'EDIT_JOB',
    //     'PAUSE_JOB_CLICKED',
    //     'SELECT_TOP_PROSPECTS',
    //     'APPLY_SCORING_FILTER_EDUCATION_FILTER',
    //     'CONFIGURE_CALENDER_WITH_OUTLOOK',
    //     'SCORE_AND_CONNECT',
    //     'PROFILE_VISIT',
    //     'ARCHIVED_FILTER',
    //     'CONNECTION_FILTER_2',
    //     'SAVED_ADDED_1ST_FILTER',
    //     'MESSAGE_REPLIED_FILTER',
    //     'SEND_CONNECT_MESSAGE',
    //     'TOGGLE_SELECT_ALL',
    //     '2ND_ADDED_FILTER',
    //     'PREVIOUSLY_MESSAGED_FILTER',
    //     '1ST_FOLLOW_UP_SENT_FILTER',
    //     'CREATE_JOB',
    //     'SKIPPING_ZOOM_INTEGRATION',
    //     'SAVE_PROSPECTS',
    //     'RESUME_JOB_CLICKED',
    //     'CONNECTION_FILTER_1',
    //     'ARCHIVE_PROSPECT',
    //     'SEND_MESSAGE',
    //     'ALL_DONE_REQUEST_MEETING',
    //     'CONNECT_MESSAGE_SENT_FILTER',
    //     'DOWNLOAD_CSV',
    //     'APPLY_SCORING_FILTER_TITLE_FILTER',
    //     'DELETE_JOB',
    //     'START_SEARCH_CLICKED',
    //     'SELECT_15_MINUTES_REQUEST_MEETING',
    //     'CONFIGURE_CALENDER_WITH_GOOGLE',
    //     '2ND_FOLLOW_UP_SENT_FILTER',
    //     'VIEW_RESULTS',
    //     'TOGGLE_DROP_DOWN_REQUEST_MEETING',
    //     'SECOND_FOLLOW_UP_MESSAGE_ENABLED',
    //     'REQUEST_15_ZOOM',
    //     'OPEN_ANALYTICS',
    //     'APPLY_ADVANCED_FILTER_HIGHLIGHT_COMPANY_FILTER',
    //     'OPEN_SUPPORT_PAGE',
    //     'POPUP_OPENED',
    //     'SENDING_REQUEST_MEETING_TO_2ND/3RD_CONNECTION',
    //     'CONFIGURE_CALENDSR_REQUEST_MEETING',
    //     'INTEGRATE_ZOOM',
    //     'ADD_PROSPECTS',
    //     'CALENDAR_PAGE_OPENED',
    //     'UNARCHIVE_PROSPECT'
    //     ];
        
    const renderSuggestion = suggestion => (
        <div className="renderedSuggestionContainer"> {suggestion.activity} </div>
    )
    const getActivitySuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
     
        return inputLength === 0 ? [] : activityTypes.filter(action =>
         action.activity.toLowerCase().slice(0, inputLength) === inputValue)
      }
    const onActivitySuggestionsFetchRequested = ({ value }) => setSuggestions(getActivitySuggestions(value))
    
    const getSuggestionValue = suggestion => suggestion.activity
 
    const inputProps = {
        placeholder: `Search for Activity Title`,
        value: ActivityTextBox,
        onChange: (e, { newValue }) => {
            setActivityTextBox(newValue)
            if(newValue==='') 
            sortBy('');
             
        }
    }
    // const toggle = () => setOpen(!dropdownOpen);

    // const changeSortValue = e => {
    //     setDropDownValue(e.currentTarget.textContent);
    //     sortBy(e.currentTarget.value);
    // }
    const showValue=(e,props)=>{
       sortBy(props.suggestionValue)

    }
    useEffect(() => {
        dispatch(fetchActivityTypes())

    }, []);
    return (
        <div style={{ marginLeft: '370px' }}>
            <FormGroup >
                <Autosuggest
                    theme={{
                        input: 'adv-filter-ip-activity',
                        suggestion: 'suggestion-item-activity',
                        suggestionsContainer: 'autosuggest-suggestions-container-activity'
                    }}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onActivitySuggestionsFetchRequested}
                    onSuggestionsClearRequested={onActivitySuggestionsClearRequested}
                    onSuggestionSelected={showValue}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
            </FormGroup>
        </div>
    )
}
export default connect(state => ({
    activityTypes: state.activityTypes.activityTypes,
    activityTypesPending: state.activityTypes.activityTypesPending,
    isDataAvailable: state.activityTypes.isDataAvailable
}))(ActivityTypesAuto)