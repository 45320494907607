import {
    CALL_API
} from '../middlewares/apiMiddleware'
// import { manualApiCall } from '../utils';

export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const ADD_ORGANIZATION_SUCCESS = 'ADD_ORGANIZATION_SUCCESS';
export const ADD_ORGANIZATION_FAILURE = 'ADD_ORGANIZATION_FAILURE';

export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS'
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS'
export const FETCH_ORGANIZATIONS_FAILURE = 'FETCH_ORGANIZATIONS_FAILURE'

export const FETCH_ORGANIZATION_USERS = 'FETCH_ORGANIZATION_USERS'
export const FETCH_ORGANIZATION_USERS_SUCCESS = 'FETCH_ORGANIZATION_USERS_SUCCESS'
export const FETCH_ORGANIZATION_USERS_FAILURE = 'FETCH_ORGANIZATION_USERS_FAILURE'

export const fetchOrganizations = () => ({
    [CALL_API]: {
        types: [FETCH_ORGANIZATIONS, FETCH_ORGANIZATIONS_SUCCESS, FETCH_ORGANIZATIONS_FAILURE],
        endpoint: '/api/auth/admin/organizations',
        options: {
            method: 'GET',
        }
    }
})

export const addOrganization = (name) => {
    return {
        [CALL_API]: {
            types: [ADD_ORGANIZATION, ADD_ORGANIZATION_SUCCESS, ADD_ORGANIZATION_FAILURE],
            endpoint: '/api/auth/admin/createOrganization',
            options: {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    name
                })
            }
        }
    }
}

export const getOrganizationUsers = (startDate,endDate,organization_id,sortby,userId) => {
    return {
        [CALL_API]: {
            types: [FETCH_ORGANIZATION_USERS, FETCH_ORGANIZATION_USERS_SUCCESS, FETCH_ORGANIZATION_USERS_FAILURE],
            endpoint: '/api/auth/admin/organization/users/analytics',
            options: {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    startDate,
                    endDate,
                    organization_id,
                    sortby,
                    userId
                })
            }
        }
    }
}