import {
    ADD_USER,
    ADD_USER_SUCCESS,
    ADD_USER_FAILURE
} from '../actions/addUser';

const initialState = {
   isLoading: false,
    isDataAvailable: false,
    message:''
}

export default function addUser(state = initialState, action) {

    switch (action.type) {
        case ADD_USER:
            return {
                ...state,
                isLoading:true,
                isDataAvailable: false,
                message:''
            }
        case  ADD_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message
            }
        case  ADD_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message,
                isDataAvailable: false
            }

        default:
            return state;
    }
}


