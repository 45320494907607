import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
// import 'typeface-inter/index.css'

import { store } from './js/store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'regenerator-runtime'
import App from './js/App'
import './index.css'
require('typeface-inter')
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.getElementById('root'))