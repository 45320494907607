import * as ActionTypes from '../actions/addOrganization'
import {
    ADD_ADMIN,
ADD_ADMIN_SUCCESS,
ADD_ADMIN_FAILURE}
from  '../actions/addUser'
const initialState = {
    isLoading: false,
    isDataAvailable: false,
    message: '',
    users: [],
    isLoadingOrganizations: false,
    isAdding: false,
    adding: [],
    count:1,
    allUsers:[]

}

export default function orgUsers(state = initialState, action) {

    switch (action.type) {
        case ActionTypes.FETCH_ORGANIZATION_USERS:
            return {
                ...state,
                isLoading: true,
                isDataAvailable: false,
                message: '',


            }
        case ActionTypes.FETCH_ORGANIZATION_USERS_SUCCESS:
            
            return {
                ...state,
                count:state.count+1,
                allUsers:state.count==1?action.payload.data.list: (state.count>1&&state.allUsers.length<action.payload.data.list.length? action.payload.data.list:state.allUsers),
                isLoading: false,
                message: action.payload.message,
                users: action.payload.data.list

            }
        case ActionTypes.FETCH_ORGANIZATION_USERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: action.payload.message,
                isDataAvailable: false,

            }
        case ADD_ADMIN:
            return {
                ...state,
                isAdding: true,
                adding: [...state.adding, action.payload]
            }

        case ADD_ADMIN_SUCCESS:
        case ADD_ADMIN_FAILURE:
            return {
                ...state,
                isAdding: false,
                adding: state.adding.filter(item => item != action.payload)
            }
        default:
            return state;
    }
}


