import { API_HOST, } from '../../config'

export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS'
export const REQUEST_LOGIN_FAILURE = 'REQUEST_LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'


export const logout = () => ({ type: LOGOUT })

export const requestLogin = ({
    email,
    password
}) => async (dispatch) => {
    email = email.toLowerCase();
    dispatch({ type: REQUEST_LOGIN })
    try {
        const json = await fetch(API_HOST+'/public/admin/login', {
            method: 'POST',
            body: JSON.stringify({
                email,
                password
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())
        if (json.message === 'Login User Successfully') {
            dispatch({
                type: REQUEST_LOGIN_SUCCESS,
                payload: json
            })
        }
        else {
            dispatch({
                type: REQUEST_LOGIN_FAILURE,
                payload: json
            })
        }
    } catch (e) {

        dispatch({
            type: REQUEST_LOGIN_FAILURE
        })
    }
}
