import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { Table, Button, Spinner } from 'reactstrap';
import { Waypoint } from 'react-waypoint'
import { Link } from 'react-router-dom';
import Filter from '../../components/Filter';
// import { ReactComponent as ReactLogo } from '../../components/Vector.svg';
import { connect } from 'react-redux';
import { fetchAnalytics, blockUser } from '../../actions/detailedAnalytics'
// import UsersAuto from './UsersAuto'
// import EmailAuto from './EmailAuto'
import { Helmet } from 'react-helmet';
import usePrevious from '../../customHooks/usePrevious'
// import qs from 'query-string'
import moment from 'moment'
// import AddUser from './AddUser'
const DetailedAnalytics = ({ analytics,
    isDataAvailable,
    totalDataLeft,
    // currentPageNo,
    totalCount,
    analyticsPending,
    dispatch,
    // isBlocked,
    // blocking,
    isBlocking,
     }) => {

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [blockingIndex, setBlockingIndex] = useState(null)
    const [sortby, setSorting] = useState(null);
    const [orderedAnalytics, setOrderedAnalytics] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [UserTextBox, setUserTextBox] = useState('')
    const [EmailTextBox, setEmailTextBox] = useState('')
    const [userId, setUserId] = useState('')
    const isBlockingPrev = usePrevious(isBlocking)
    // const [addUserFormPopUp, setAddUserFormPopUp] = useState(false)


    const to = moment().format("YYYY-MM-DD");
    const from = moment(to).subtract(6, 'days').format('YYYY-MM-DD');
    const startDateChange = e => {
        setStartDate(e.target.value);
    }

    const endDateChange = e => {
        setEndDate(e.target.value);
    }

    const getStartEndDate = (type) => {
        const currentMonth = moment().month();
        const currentDay = moment().day();
        const currentWeek = moment().week();
        let start_date = null;
        let end_date = null;
        console.log(currentDay)
        switch (type) {
            case 'This Week':
                start_date = moment().week(currentWeek).subtract(moment().day(), 'days').format("YYYY-MM-DD");
                end_date = moment(start_date).add(6, 'days').format('YYYY-MM-DD');
                return { start_date, end_date }

            case '7 Days':
                end_date = moment().format("YYYY-MM-DD");
                start_date = moment(end_date).subtract(6, 'days').format('YYYY-MM-DD');
                return { start_date, end_date }

            case '3 Months':
                end_date = start_date = moment([2021, 0, 31]).month(currentMonth).format("YYYY-MM-DD");
                const monthStart = start_date = moment([2021, 0, 1]).month(currentMonth).format("YYYY-MM-DD");
                start_date = moment(monthStart).subtract(2, 'months').format('YYYY-MM-DD');
                return { start_date, end_date }

            case '6 Months':
                end_date = start_date = moment([2021, 0, 31]).month(currentMonth).format("YYYY-MM-DD");
                const monthStart6 = start_date = moment([2021, 0, 1]).month(currentMonth).format("YYYY-MM-DD");
                start_date = moment(monthStart6).subtract(5, 'months').format('YYYY-MM-DD');
                return { start_date, end_date }

            case 'Last Week':
                start_date = moment().week(currentWeek - 1).subtract(moment().day(), 'days').format("YYYY-MM-DD");
                end_date = moment(start_date).add(6, 'days').format('YYYY-MM-DD');
                return { start_date, end_date }

            case 'This Month':
                start_date = moment([2021, 0, 1]).month(currentMonth).format("YYYY-MM-DD");
                end_date = moment([2021, 0, 31]).month(currentMonth).format("YYYY-MM-DD");
                return { start_date, end_date }

            case 'Last Month':
                start_date = moment([2021, 0, 1]).month(currentMonth - 1).format("YYYY-MM-DD");
                end_date = moment([2021, 0, 31]).month(currentMonth - 1).format("YYYY-MM-DD");
                return { start_date, end_date }

            case 'All Time':
            default:
                setStartDate('')
                setEndDate('')
                return { start_date: '', end_date: '' }

        }
    }

    const setTimeFrame = (type) => {
        const { start_date, end_date } = getStartEndDate(type)
        setStartDate(start_date)
        setEndDate(end_date)
        console.log(userId)
        dispatch(fetchAnalytics(start_date === '' ? null : start_date, end_date === '' ? null : end_date, false, 1, userId, sortby));
    }

    const sortBy = order => {
        const sorting = order == '' ? null : order
        setSorting(sorting);
        setPageNo(1)
        console.log(userId)
        dispatch(fetchAnalytics(startDate === '' ? null : startDate, endDate === '' ? null : endDate, false, 1,  userId, sorting));
    }

    const shouldRenderWaypoint = isDataAvailable ? totalDataLeft > 0 && totalCount > 100 : false

    const handleWaypointOnEnter = () => {
        const updated_page_no = parseInt(pageNo, 10) + 1;
        setPageNo(updated_page_no)
        dispatch(fetchAnalytics(startDate === '' ? null : startDate, endDate === '' ? null : endDate, false, updated_page_no,  userId, sortby));
    }

    const toggleBlocking = (flag, userId, index) => {
        setBlockingIndex(index);
        dispatch(blockUser(flag, userId))
    }

    const getUserActivitiesName = (user_id) => {
        setPageNo(1)
        setUserId(user_id)
        dispatch(fetchAnalytics(startDate === '' ? null : startDate, endDate === '' ? null : endDate, false, 1, user_id, sortby));
        if (user_id!== '')
            setEmailTextBox('')
        else
            setEmailTextBox(EmailTextBox)
    }

    const getUserActivitiesEmail = (user_id) => {
        setPageNo(1)
        setUserId(user_id)
        dispatch(fetchAnalytics(startDate === '' ? null : startDate, endDate === '' ? null : endDate, false, 1, user_id, sortby));
        if (user_id !== '')
            setUserTextBox('')
        else
            setUserTextBox(UserTextBox)
    }

    useEffect(() => {
        dispatch(fetchAnalytics(startDate === '' ? null : startDate, endDate === '' ? null : endDate, false, 1,  userId,sortby));
    }, []);


    useEffect(() => {
        if (isBlockingPrev && !isBlocking) {
            let newArr = [...analytics]; // copying the old datas array
            newArr[blockingIndex].isBlocked = !analytics[blockingIndex].isBlocked; // replace e.target.value with whatever you want to change it to
            setOrderedAnalytics(newArr);
        }
    }, [isBlocking])
    return (
        <div>

            <Helmet>
                <title>DNNae Admin - Detailed Analytics </title>
            </Helmet>
            <Header />

            <Filter
                // submit={handleFilterSubmit}
                startDateChange={startDateChange}
                startDate={startDate}
                endDate={endDate}
                endDateChange={endDateChange}
                sortBy={sortBy}
                // reset={reset}
                UserTextBox={UserTextBox}
                setUserTextBox={setUserTextBox}
                getUserActivitiesName={getUserActivitiesName}
                EmailTextBox={EmailTextBox}
                setEmailTextBox={setEmailTextBox}
                getUserActivitiesEmail={getUserActivitiesEmail}
                setTimeFrame={setTimeFrame}
            />
            {/* <div className="user-filter-container">
                <UsersAuto
                    UserTextBox={UserTextBox}
                    setUserTextBox={setUserTextBox}
                    getUserActivities={getUserActivitiesName} />
                <EmailAuto
                    EmailTextBox={EmailTextBox}
                    setEmailTextBox={setEmailTextBox}
                    getUserActivities={getUserActivitiesEmail} />
            </div> */}
            {  analyticsPending && pageNo == 1 ?
                <div className="spinner">
                    <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
                </div>
                :
                analytics &&
                    analytics.length > 0
                    ?

                    <div>

                        <div className="analytics-content-container">
                            <div className="analytics-content">
                                {localStorage.getItem('userType') === 'CUSTOMER-ADMIN'
                                    ?
                                    <Table striped size="sm" >
                                        <thead className="thead-analytics">
                                            <tr >
                                                <th className="thead-analytics">No.</th>
                                                <th className="thead-analytics">Name</th>
                                                <th className="thead-analytics">Email</th>
                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Scored</span></th>
                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Messaged</span></th>
                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Accepted</span></th>
                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Replied</span></th>
                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Activity</span></th>
                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Downloads</span></th>
                                                <th className="thead-analytics" style={{ textAlign: 'center' }}><span>Action</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                analytics.map((log, index) => (
                                                    <tr key={index} className="analytics-table">
                                                        <td scope="row">{index + 1}</td>
                                                        <td >{log.name}</td>
                                                        <td style={{ wordBreak: 'break-word' }}>{log.email}</td>
                                                        <td ><p className="analytics-column-data-flow">{log.totalScored}</p></td>
                                                        <td ><p className="analytics-column-data-flow">{log.totalSent} </p></td>
                                                        <td ><p className="analytics-column-data-flow">{log.totalAccepted || 0}</p> </td>
                                                        <td ><p className="analytics-column-data-flow">{log.totalReplied}</p></td>
                                                        <td ><p className="analytics-column-data-flow">{log.activityLog}</p></td>
                                                        <td ><p className="analytics-column-data-flow">{log.totalDownload}</p></td>
                                                        <td className="apply-width">
                                                            <span>
                                                                <Link style={{ fontSize: '15px' }}
                                                                    to={{
                                                                        pathname: '/html/jobs.html',
                                                                        search: '?recruiterId=' + log.id
                                                                    }}
                                                                    target="_blank">View Jobs
                                                   </Link>
                                                                <br />
                                                                <Link style={{ fontSize: '15px' }}
                                                                    to={{
                                                                        pathname: '/html/graph.html',
                                                                        search: `?userId=${log.id}&to=${to}&from=${from}`
                                                                    }}
                                                                    target="_blank" >View Graph</Link>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        {shouldRenderWaypoint &&
                                            <Waypoint color="primary"
                                                fireOnRapidScroll={true}
                                                onEnter={handleWaypointOnEnter}
                                            />
                                        }
                                    </Table>
                                    :
                                    <Table striped size="sm" >
                                        <thead className="thead-analytics">
                                            <tr >
                                                <th className="thead-analytics">No.</th>
                                                <th className="thead-analytics">Name</th>
                                                <th className="thead-analytics">Email</th>
                                                {/* <th className="thead-analytics">Type</th>
                                        // <th className="thead-analytics">Profile</th> */}
                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Scored</span></th>
                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Messaged</span></th>
                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Accepted</span></th>
                                                {/* <th className="thead-analytics"><span style={{ minWidth: '120px', float: 'right' }}>Connect Rate</span></th> */}
                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Replied</span></th>
                                                {/* <th className="thead-analytics"><span style={{ minWidth: '120px', float: 'right' }}>Response Rate</span></th> */}

                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Activity</span></th>

                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Downloads</span></th>
                                                <th className="thead-analytics"><span style={{ float: 'right',textAlign:'center' }}>View</span></th>
                                                <th className="thead-analytics"><span style={{ float: 'right' }}>Sign Up At</span></th>
                                                {(localStorage.getItem('userType') === 'ADMIN' || localStorage.getItem('userType') === 'SALES-ADMIN') && <th className="thead-analytics" style={{ textAlign: 'center' }}>Action</th>}
                                                {/* {localStorage.getItem('userType') === 'ADMIN' && <th className="thead-analytics" style={{ textAlign: 'center' }}>Add</th>} */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                analytics.map((log, index) => (
                                                    <tr key={index} className="analytics-table">
                                                        <td scope="row">{index + 1}</td>
                                                        <td >{log.name}</td>
                                                        <td style={{ wordBreak: 'break-word' }}>{log.email}</td>
                                                        {/* <td >{(log.linkedinUserType == null || log.linkedinUserType === "null") ? "N/A" : log.linkedinUserType}</td>
                                                <td >
                                                    {log.linkedinProfile != null ? <a  target='_blank' href={`https://www.linkedin.com/in/${log.linkedinProfile}`}> <ReactLogo /></a> : "N/A"}
                                                </td> */}
                                                        <td ><p className="analytics-column-data-flow">{log.totalScored}</p></td>
                                                        <td ><p className="analytics-column-data-flow">{log.totalSent} </p></td>
                                                        <td ><p className="analytics-column-data-flow">{log.totalAccepted || 0}</p> </td>
                                                        {/* <td ><p className="analytics-column-data-flow">{log.totalSent > 0 && log.totalAccepted > 0 ? parseInt(log.totalAccepted * 100 / log.totalSent) + '%' : '0%'}</p> </td> */}
                                                        <td ><p className="analytics-column-data-flow">{log.totalReplied}</p></td>
                                                        {/* <td ><p className="analytics-column-data-flow">{log.totalSent > 0 && log.totalReplied > 0 ? parseInt(log.totalReplied * 100 / log.totalSent) + '%' : '0%'}</p> </td> */}

                                                        <td ><p className="analytics-column-data-flow">{log.activityLog}</p></td>

                                                        <td ><p className="analytics-column-data-flow">{log.totalDownload}</p></td>
                                                        <td className="apply-width">
                                                            <span>
                                                                <Link style={{ fontSize: '15px',textAlign:'center' }}
                                                                    to={{
                                                                        pathname: '/html/jobs.html',
                                                                        search: '?recruiterId=' + log.id
                                                                    }}
                                                                    target="_blank">Jobs
                                                   </Link>
                                                                <br />
                                                                <Link style={{ fontSize: '15px',textAlign:'center' }}
                                                                    to={{
                                                                        pathname: '/html/graph.html',
                                                                        search: `?userId=${log.id}&to=${to}&from=${from}`
                                                                    }}
                                                                    target="_blank" >Graph</Link>
                                                            </span>
                                                        </td>
                                                        <td style={{ width: '105px' }}>
                                                            <span className="analytics-column-data-flow">{log.createdAt.slice(0, 10)}</span>
                                                            <br />
                                                            <span className="analytics-column-data-flow">{log.createdAt.slice(11, 19)}</span>
                                                        </td>
                                                        {(localStorage.getItem('userType') === 'ADMIN' || localStorage.getItem('userType') === 'SALES-ADMIN') &&
                                                            <td style={{ textAlign: 'center' }}>
                                                                {
                                                                    isBlocking && index === blockingIndex
                                                                        ?
                                                                        <b style={{ fontSize: '30px', color: '#0000ff' }} >...</b>
                                                                        :
                                                                        log.isBlocked
                                                                            ?
                                                                            <Button onClick={() => toggleBlocking(false, log.id, index)} color="success" style={{ width: '100px', marginTop: '5px' }}>UnBlock</Button>
                                                                            :
                                                                            <Button onClick={() => toggleBlocking(true, log.id, index)} color="danger" style={{ width: '100px', marginTop: '5px' }}>Block</Button>
                                                                }

                                                            </td>
                                                        }

                                                        {/* {localStorage.getItem('userType') === 'ADMIN' &&
                                                        <td style={{ textAlign: 'center' }}>
                                                            <AddUser
                                                            addUserFormPopUp={addUserFormPopUp}
                                                            setAddUserFormPopUp={setAddUserFormPopUp}
                                                            name={log.name}
                                                            email={log.email}
                                                            id={log.id}
                                                            isAdmin={log.is_org_admin}
                                                            organization_id={log.organization_id}
                                                            />
                                                            
                                                        </td>
                                                    } */}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        {shouldRenderWaypoint &&
                                            <Waypoint color="primary"
                                                fireOnRapidScroll={true}
                                                onEnter={handleWaypointOnEnter}
                                            />
                                        }
                                    </Table>
                                }
                            </div>

                        </div >
                    </div>
                    :
                    !analyticsPending &&
                    <p className="spinner">No Analytics Found</p>
            }
            { analyticsPending && pageNo != 1
                &&
                <div className="spinner">
                    <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
                </div>
            }
        </div>
    );
};

export default connect(state => ({
    analyticsError: state.detailedAnalytics.analyticsError,
    analytics: state.detailedAnalytics.analytics,
    analyticsPending: state.detailedAnalytics.analyticsPending,
    isBlocked: state.detailedAnalytics.isBlocked,
    isBlocking: state.detailedAnalytics.isBlocking,
    isDataAvailable: state.detailedAnalytics.isDataAvailable,
    totalDataLeft: state.detailedAnalytics.totalDataLeft,
    currentPageNo: state.detailedAnalytics.currentPageNo,
    totalCount: state.detailedAnalytics.totalCount
}))(DetailedAnalytics)