import React, { useState } from 'react'
import { connect } from 'react-redux'

import JobTitleSection from './JobTitleSection'
import RequiredEducationSection from './RequiredEducationSection'
import ImportantSkillsSection from './ImportantSkillsSection'
import JobLocationSection from './JobLocationSection'
import RequiredExperienceSection from './RequiredExperienceSection'
import IndustrySection from './IndustrySection'
import JobDescription from './JobDescription'
// import { getToken } from '../../utils/index'
// import Loader from '../Loader'
// import usePrevious from '../../customHooks/usePrevious'

// import { addJob, fetchJobs, setStep } from '../../actions/jobDescription'
// import { updateJob } from '../../actions/editJob'

import './ReviseJd.css'

const ReviseJd = ({

  jobData,
  additionalTitles,
  experience,
  importantSkills,
  // additionalSkills,
  industries,
  education,
  data,
  jd,
  jobLocation,
  // industrySuggestions,
  dispatch,
  isAddingJob,
  isFetchingJobs,
  editJob,
  editMode = false,
  jobId = null

}) => {

  const [jobDescriptionPopUp, setJobDescriptionPopUp] = useState(false)
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  // console.log('REVISE JD, DATA')
  // const isAddingJobPrev = usePrevious(isAddingJob)
  // const isFetchingJobsPrev = usePrevious(isFetchingJobs)

  // const isUpdatingJobPrev = usePrevious(editJob.isUpdating)
  // const isUpdatedPrev = usePrevious(editJob.isUpdated)

  // useEffect(() => {
  //   // console.log({ isAddingJob, isAddingJobPrev })
  //   if (isAddingJobPrev && !isAddingJob) {
  //     dispatch(fetchJobs())
  //   }
  // }, [isAddingJob])

  // useEffect(() => {
  //   if (isUpdatingJobPrev && !editJob.isUpdating) {
  //     dispatch(fetchJobs())
  //   }
  // }, [editJob.isUpdating])

  // useEffect(() => {
  //   console.log({ isFetchingJobs, isFetchingJobsPrev })
  //   if (isFetchingJobsPrev && !isFetchingJobs) {
  //     dispatch(setStep(3))
  //   }
  // }, [isFetchingJobs])


  // const handleClickContinue = () => {
  //   if (!isAddingJob) {
  //     const scoreTextMap = {
  //       1: 'Nice to Have',
  //       3: 'Important',
  //       5: 'Must Have'
  //     }
  //     // console.log('ADD JOB:  ', { jobData, data, jd })



  //     if (!jobLocation || !jobData.jobTitle.trim()) {
  //       const itemsToAdd = []
  //       if (!jobLocation) {
  //         itemsToAdd.push('job location')
  //       }
  //       if (!jobData.jobTitle.trim()) {
  //         itemsToAdd.push('job title')
  //       }
  //       alert(`please add ${itemsToAdd.join(' and ')}`)
  //       return
  //     }

  //     getToken()
  //       .then(token => {
  //         const payload = {
  //           token: token,
  //           jobDescription: data,
  //           jobID: 'add',
  //           stepperData: {},
  //           sampleProspectsProfiles: {},
  //           jdText: jd,
  //           jdType: 'text',
  //           jobLocation,
  //           companyName: '',
  //           jobTitle: jobData.jobTitle
  //         }
  //         const jobArray = []
  //         jobArray.push({
  //           data: jobData.education.map(item => ({ ...item, scoreText: scoreTextMap[item.score] })),
  //           tag: 'DEGREE_FULL',
  //           title: 'Degree Name'
  //         })
  //         jobArray.push({
  //           data: jobData.industries.map(item => ({ ...item, scoreText: scoreTextMap[item.score] })),
  //           tag: 'COMPANY_INDUSTRY_SPECIALTIES',
  //           title: 'Company and industry specialties'
  //         })
  //         jobArray.push({
  //           data: [],
  //           tag: 'COMPANY_NAME',
  //           title: 'company name'
  //         })
  //         // jobArray.push({
  //         //   data: [],
  //         //   tag: 'OTHER_SKILLS',
  //         //   title: 'Other Skills'
  //         // })
  //         jobArray.push(...data.filter(item => item.tag === 'OTHER_SKILLS'))
  //         jobArray.push({
  //           data: jobData.additionalTitles.filter(item => item.isSelected).map(item => item.name),
  //           tag: 'SUGGESTED_JOB_TITLES',
  //           title: 'Suggested job titles'
  //         })
  //         jobArray.push({
  //           data: jobLocation,
  //           tag: 'JOB_LOCATION',
  //           title: 'Job Location'
  //         })

  //         let jobPosition = data.filter(item => item.tag === 'JOB_POSITION')[0]
  //         if (jobPosition.data.length === 0) {
  //           jobPosition.data.push(jobData.jobTitle)
  //         } else if (!jobPosition.data.includes(jobData.jobTitle)) {
  //           jobPosition.data = [jobData.jobTitle, ...jobPosition.data]
  //         }

  //         jobArray.push(
  //           jobPosition
  //         )
  //         jobArray.push({
  //           data: [],
  //           tag: 'DEGREE',
  //           title: 'Degree'
  //         })
  //         jobArray.push({
  //           data: [],
  //           tag: 'SALARY',
  //           title: 'Salary'
  //         })
  //         jobArray.push({
  //           data: [
  //             ...jobData.technicalSkills.map(item => ({
  //               ...item,
  //               scoreText: scoreTextMap[item.score],
  //               modifier: ''
  //             })),
  //             ...jobData.additionalSkills.filter(item => item.score > 1).map(item => ({
  //               ...item,
  //               scoreText: scoreTextMap[item.score],
  //               modifier: ''
  //             }))
  //           ],
  //           suggested: data.filter(item => item.tag === 'TECHNICAL_SKILLS')[0].suggested,
  //           tag: 'TECHNICAL_SKILLS',
  //           title: 'Technical Skills'
  //         })
  //         jobArray.push(...data.filter(item => item.tag === 'SOFT_SKILLS'))
  //         jobArray.push({
  //           data: [],
  //           tag: 'PUBLISHING_SITE',
  //           title: 'Publishing Site'

  //         })
  //         jobArray.push({
  //           data: [],
  //           tag: 'DEGREE_MAJOR',
  //           title: 'Degree Major'
  //         })
  //         jobArray.push({
  //           data: jobData.experience.map(item => ({
  //             ...item,
  //             scoreText: scoreTextMap[item.score]
  //           })),
  //           tag: 'TECHNOLOGY_WORK_EXPERIENCE',
  //           title: 'Technology work experience'
  //         })

  //         payload.jobArray = jobArray

  //         if (!editMode) {
  //           dispatch(addJob(payload))
  //         } else {
  //           dispatch(updateJob({ ...payload, jobID: jobId }))
  //         }
  //         // console.log('PAYLOAD FOR ADD JOB: ', payload)
  //       })
  //       .catch(err => {
  //         console.log(err)
  //         alert(err.message)
  //       })
  //   }
  // }

  return (
    <React.Fragment>
      <div className='revise-heading'></div>
      <div className='revise-jd-root'>
        <JobTitleSection
          title={jobData.jobTitle}
          additionalTitles={additionalTitles}
          dispatch={dispatch}
          toggle={toggle}
        />

        <hr className='jd-bottomBorder' />

        <ImportantSkillsSection
          technicalSkills={importantSkills}
          dispatch={dispatch}
        />

        {/* <hr className='jd-bottomBorder' />

        <AdditionalSkillsSection
          additionalSkills={[]}
          dispatch={dispatch}
        /> */}

        <hr className='jd-bottomBorder' />

        <RequiredExperienceSection
          experience={experience}
          dispatch={dispatch}
        />

        <hr className='jd-bottomBorder' />

        <IndustrySection
          industries={industries}
          dispatch={dispatch}
        />

        <hr className='jd-bottomBorder' />

        <RequiredEducationSection
          education={education}
          dispatch={dispatch}
        />

        <hr className='jd-bottomBorder' />

        <JobLocationSection />

      </div>
      <div className='revise-footer'>
        {/* <button
          onClick={toggle}>
          Job Description
        </button> */}

      </div>
     <JobDescription
     toggle={toggle}
     description={jobData.jdText}
     title={jobData.jobTitle}
     modal={modal}/>
    </React.Fragment>
  )
}

export default connect(state => ({
  // ...state.jobDescription.revise,
  // jd: state.jobDescription.choose.selectedJdText,
  // jobLocation: state.jobDescription.jobLocation.locationState,
  ...state.editJob
}))(ReviseJd)