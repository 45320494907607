import {
    CALL_API
  } from '../middlewares/apiMiddleware'

export const FETCH_ADVANCED_FILTER = 'FETCH_ADVANCED_FILTER'
export const FETCH_ADVANCED_FILTER_SUCCESS = 'FETCH_ADVANCED_FILTER_SUCCESS'
export const FETCH_ADVANCED_FILTER_FAILURE = 'FETCH_ADVANCED_FILTER_FAILURE'

export const fetchAdvancedFilter = (jobId) => {

  return {
    [CALL_API]: {
      types: [FETCH_ADVANCED_FILTER, FETCH_ADVANCED_FILTER_SUCCESS, FETCH_ADVANCED_FILTER_FAILURE],
      endpoint: `/api/auth/job/profile/advanced-filters-data?jobId=${jobId}`,
      options: {
        method: 'GET'
      }
    }
  }
}
