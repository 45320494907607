import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
const TimeFrameFilter = ({ setTimeFrame }) => {
    const [dropdownOpen, setOpen] = useState(false);
    const [dropDownValue, setDropDownValue]=useState("All");
    const toggle = () => setOpen(!dropdownOpen);

    const changeSortValue=e=>{
        e.preventDefault();
        setDropDownValue(e.currentTarget.textContent);
        setTimeFrame(e.currentTarget.textContent);
    }
    return (
        <div>
           Time Frame
            <span className="analytics-sorting-dropdown"/>
            <ButtonDropdown size="sm" isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle outline caret style={{fontSize:'12px'}}>
                    {dropDownValue==="All"?"All Time" :dropDownValue}
                </DropdownToggle>
                <DropdownMenu className="for-sort-item">
                    {/* <DropdownItem  onClick={changeSortValue} value="thisWeek">This Week</DropdownItem>
                    <DropdownItem  onClick={changeSortValue} value="lastWeek">This Month</DropdownItem>
                    <DropdownItem  onClick={changeSortValue} value="thisMonth">Last Week</DropdownItem>
                    <DropdownItem  onClick={changeSortValue} value="lastMonth">Last Month</DropdownItem> */}
                    <DropdownItem  onClick={changeSortValue} value="sevenDays">7 Days</DropdownItem>
                    <DropdownItem  onClick={changeSortValue} value="threeMonths">3 Months</DropdownItem>
                    <DropdownItem  onClick={changeSortValue} value="sixMonths">6 Months</DropdownItem>
                    <DropdownItem  onClick={changeSortValue} value="All">All Time</DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
        </div>
    )
}
export default TimeFrameFilter;