import React, {useState, useRef, useCallback, useEffect} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Label,
    Input,
    FormGroup,
    Modal,
    ModalBody
} from 'reactstrap'
import InputRange from 'react-input-range';

import {callLinkedinApi} from '../../utils'

import './style.css'

import qs from 'query-string'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import {
    unselectAllProspects,
    clearScoringFilter

} from '../../actions/score'

import Autosuggest from 'react-autosuggest'

import {manualApiCall} from '../../utils'

import ScoringFiltersSliderModule from './ScoringFiltersSliderModule'


const ScoringFilterSection = (props) => {

    
    const {
        Show,
        setShow,

        push,
        search,
        dispatch,
        setSelectAllProspectsFlag,

        activeTab,
        FilterCount,
        setFilterCount,

        ScoringFilters,


        titleFlag,
        skillFlag,
        industryFlag,
        experienceFlag,
        educationFlag

    } = props

    useEffect(() => {

        
    }, [])

    const handleAdvancedFilterClear = () => {
        setFilterCount(0)
        dispatch(clearScoringFilter())
      
        // manualApiCall('/api/auth/user/activity/store', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(
        //         {"actionName": "CLEAR_SCORING_FILTER"}
        //     )
        // })

        const params = qs.parse(search)

        console.log('PARAMS ARE: ', params)

        if (params.tSF) 
          delete params.tSF

        if (params.sSF) 
          delete params.sSF

        if (params.expSF) 
          delete params.expSF

        if (params.edSF) 
          delete params.edSF

        if (params.iSF) 
          delete params.iSF
          

        dispatch(unselectAllProspects())
        setSelectAllProspectsFlag(false)
        push(`/html/score.html?${qs.stringify(params)}`)

    }

    const actionApiAdvFilter = (actionType) => {
    //   manualApiCall('/api/auth/user/activity/store', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({
    //           "actionName": `APPLY_SCORING_FILTER_${actionType}_FILTER`
    //       })
    //     })
    }


    const handleAdvancedFilterCancel = () => {
        setFilterCount(0)
        dispatch(clearScoringFilter())

        // manualApiCall('/api/auth/user/activity/store', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(
        //         {"actionName": `SCORING_FILTER_CANCEL`}
        //     )
        // })

        setShow(false)
    }

    const handleClickApply = () => {
        let params = qs.parse(search)

        if (params.tSF) 
            delete params.tSF

        if (params.sSF) 
            delete params.sSF

        if (params.expSF) 
            delete params.expSF

        if (params.edSF) 
            delete params.edSF

        if (params.iSF) 
            delete params.iSF
            
        
        if (ScoringFilters.titleScore.active) {
            params['tSF'] = `${ScoringFilters.titleScore.value}`
            actionApiAdvFilter('TITLE')
        }   
        
        if (ScoringFilters.skillScore.active) {
            params['sSF'] = `${ScoringFilters.skillScore.value}`
            actionApiAdvFilter('SKILL')
        }   
        
        if (ScoringFilters.experienceScore.active) {
            params['expSF'] = `${ScoringFilters.experienceScore.value}`
            actionApiAdvFilter('EXPERIENCE')
        }   
        
        if (ScoringFilters.educationScore.active) {
            params['edSF'] = `${ScoringFilters.educationScore.value}`
            actionApiAdvFilter('EDUCATION')
        }   
        
        if (ScoringFilters.industryScore.active) {
            params['iSF'] = `${ScoringFilters.industryScore.value}`
            actionApiAdvFilter('INDUSTRY')
        }   
        
        dispatch(unselectAllProspects())
        setSelectAllProspectsFlag(false)
        push(`/html/score.html?${qs.stringify(params)}`)
        
        window.scrollTo({top: 0, behavior: 'smooth'});

        setShow(false)
    }


    return (
        <React.Fragment>
            <Modal className="scoringFilterSection"
                isOpen={Show}>
                <ModalBody>
                <Card>
                <CardHeader className="filterNavHeader"
                        style={
                            {padding: "16px"}
                        }>
                <nav className="filterNav">
                <div className="leftFilterNav">
                <h4 className="filterNavHeading">Scoring Filter</h4>
                </div>
                <span className="rightFilterNav">
                {FilterCount > 0 && 
                    <a className="filterClear"
                        onClick={handleAdvancedFilterClear}>
                        Clear({`${FilterCount}`})
                    </a>}
                <span style={
                    {
                        borderRight: "1px solid #E6E9ED",
                        height: "42px"
                    }
                }/>
                    <Button outline color="primary" className="filterCancelButton"
                        onClick={handleAdvancedFilterCancel}>Cancel</Button>
                    <Button color="primary" className="filterApplyButton"
                        onClick={handleClickApply}>Apply
                    </Button>
                </span>
            </nav>
            </CardHeader>
            <CardBody> 
                <div className="bottomFilterBody">
                    <h3 className="ScoringFiltersSliderModuleHeading">
                        Show prospects that have scoring greater than: 
                    </h3>
                    <ScoringFiltersSliderModule 
                        scoreFlag={titleFlag}
                        dispatch={dispatch}
                        scoreValue={ScoringFilters.titleScore.value}
                        scoreActive={ScoringFilters.titleScore.active} 
                        heading={"Current Title"}
                        FilterCount={FilterCount}
                        setFilterCount={setFilterCount}
                    />
                    <ScoringFiltersSliderModule 
                        scoreFlag={skillFlag}
                        dispatch={dispatch}
                        scoreValue={ScoringFilters.skillScore.value}
                        scoreActive={ScoringFilters.skillScore.active} 
                        heading={"Skills"}
                        FilterCount={FilterCount}
                        setFilterCount={setFilterCount}
                    />
                    <ScoringFiltersSliderModule 
                        scoreFlag={experienceFlag}
                        dispatch={dispatch}
                        scoreValue={ScoringFilters.experienceScore.value}
                        scoreActive={ScoringFilters.experienceScore.active} 
                        heading={"Experience"}
                        FilterCount={FilterCount}
                        setFilterCount={setFilterCount}
                    />
                    <ScoringFiltersSliderModule 
                        scoreFlag={educationFlag} 
                        dispatch={dispatch}
                        scoreValue={ScoringFilters.educationScore.value}
                        scoreActive={ScoringFilters.educationScore.active} 
                        heading={"Education"}
                        FilterCount={FilterCount}
                        setFilterCount={setFilterCount}
                    />
                    <ScoringFiltersSliderModule 
                        scoreFlag={industryFlag}
                        dispatch={dispatch}
                        scoreValue={ScoringFilters.industryScore.value}
                        scoreActive={ScoringFilters.industryScore.active} 
                        heading={"Industry"}
                        FilterCount={FilterCount}
                        setFilterCount={setFilterCount}
                    />
                </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(connect(state => {
    return { 
        // ...state.jobDescription.jobLocation,
        ScoringFilters: state.score.scoringFilters    
    }
})(ScoringFilterSection))

