import React from 'react'
import Section from '../Section'
import DataTable from '../DataTable'
// import {
//   setIndustryScore,
//   addIndustry,
//   deleteIndustry,
//   setIndustryValue
// } from '../../actions'

const IndustrySection = ({
  industries,
  dispatch,
  // suggestions
}) => {

  // const handleClickAction = ({ score, index }) => {
  //   dispatch(setIndustryScore({ score, index }))
  // }

  // const handleClickAdd = () => {
  //   dispatch(addIndustry())
  // }


  // const onDelete = (index) => {
  //   dispatch(deleteIndustry(index))
  // }

  // const handleChangeInput = ({ index, value }) => {
  //   dispatch(setIndustryValue({ index, value }))
  // }


  return (
    <Section title={'Industry Experience'}>
      <p>List out any specific industrial experience and the degree to which that experience is required:</p>
      <DataTable
        title='Industry'
        data={industries}
        autoSuggest={true}
        // actionClickHandler={handleClickAction}
        // onClickAdd={handleClickAdd}
        // onDelete={onDelete}
        // onChangeInput={handleChangeInput}
        // suggestions={suggestions}
      />
    </Section>
  )
}

export default IndustrySection