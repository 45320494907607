import React from 'react'
import { ReactComponent as ReactLogo } from '../../components/full.svg';
import LoginForm from '../../components/LoginForm';

import { Helmet } from 'react-helmet';
const Login = (props) => {
  return (
    <div>
      <Helmet>
      <link id="favicon" rel="icon" href="/new-favicon.ico" type="image/x-icon"/>
        <title>DNNae Admin - Login </title>
      </Helmet>
      <header className="headerr sticky">
        <nav className="header-inner">
          <ReactLogo className="logo" />
        </nav>
      </header>
      <div className="line" />
      <div className="login-form">
        <LoginForm {...props} />

      </div>
    </div>
  );
};
export default Login;