import {
    CALL_API
} from '../middlewares/apiMiddleware'
import { manualApiCall } from '../utils';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const FETCH_USER_TYPES = 'FETCH_USER_TYPES';
export const FETCH_USER_TYPES_SUCCESS = 'FETCH_USER_TYPES_SUCCESS';
export const FETCH_USER_TYPES_FAILURE = 'FETCH_USER_TYPES_FAILURE';

export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_FAILURE = 'ADD_ADMIN_FAILURE';

export const addUser= ({email,firstName,lastName,password,userType}) => {
    
    return {
        [CALL_API]: {
            types: [ADD_USER, ADD_USER_SUCCESS, ADD_USER_FAILURE],
            endpoint: '/api/auth/admin/createUser',
            options: {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    firstName,
                    lastName,
                    password,
                    userType:userType
                }),
            }
        }
    }
}
export const addAdmin = (password,is_org_admin, selectedId, org_id) => async (dispatch) => {
  try {
      dispatch({
          type: ADD_ADMIN,
          payload:selectedId
         
      })
  
      await manualApiCall('/api/auth/admin/addAdmin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
              'Accept': 'application/json'
        },
        body: JSON.stringify({
          password,
          is_org_admin,
          org_id,
          user_id: selectedId
      })
      })
  
      dispatch({type: ADD_ADMIN_SUCCESS,payload:selectedId})
  
  } catch (e){
      dispatch({type: ADD_ADMIN_FAILURE,payload: selectedId})
  }
  }
  
  
// // p, isAdmin, selectedId, organization_id
// export const addAdmin= (password,is_org_admin, selectedId, org_id) => {
//   // (p, admin, selectedId, organization_id
//     console.log({password, is_org_admin, selectedId, org_id})
//   return {
//       [CALL_API]: {
//           types: [ADD_ADMIN, ADD_ADMIN_SUCCESS, ADD_ADMIN_FAILURE],
//           endpoint: '/api/auth/admin/addAdmin',
//           options: {
//               method: 'POST',
//               headers: {
//                   'Content-Type': 'application/json',
//                   'Accept': 'application/json'
//               },
//               body: JSON.stringify({
//                   password,
//                   is_org_admin,
//                   org_id,
//                   user_id: selectedId
//               }),
//           }
//       }
//   }
// }
export const fetchUserTypes = () => {
    return {
      [CALL_API]: {
        types: [FETCH_USER_TYPES, FETCH_USER_TYPES_SUCCESS, FETCH_USER_TYPES_FAILURE],
        endpoint: '/api/auth/admin/userTypes',
        options: {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
                'Accept': 'application/json'
          }
        }
      }
    }
  }