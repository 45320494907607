import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Main from './react/components/Main'
const App = ({ dispatch }) => {

  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  )
}


export default connect()(App)
