import React, { useState, useEffect } from 'react';
import { Button, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux'
import moment from 'moment'
import { requestLogin } from '../../actions/auth'

const LoginForm = (props) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  let To = moment().format("YYYY-MM-DD");
  let From = moment(To).subtract(6, 'days').format('YYYY-MM-DD');

  const emailChange = e => {
    if(localStorage.message)
    localStorage.removeItem('message')
    setEmail(e.target.value);
}
const passwordChange = e => {
  if(localStorage.message)
  localStorage.removeItem('message')
  setPassword(e.target.value);
}
  const handleValidSubmit = (event, values) => {
    setEmail(values.email);
    setPassword(values.password);
    tryLoggingIn(values.email, values.password);
  }
  const tryLoggingIn = (email, password) => {

    const { dispatch } = props

    dispatch(requestLogin({email, password}))

  }
  const handleInvalidSubmit = (event, values) => {
    setEmail(values.email);
    setPassword(values.password);
  }
  // useEffect(() => {
  //   localStorage.removeItem('message')
  // }, [])


  useEffect(() => {
    if(props.auth.isAuthenticated ){
     if(localStorage.getItem('userType')==='CUSTOMER-ADMIN')
      props.history.push(`/html/dashboard.html?userId=${localStorage.userId}&from=${From}&to=${To}`)
      else
      props.history.push('/html/logs.html')

    }
  }, [props.auth.isAuthenticated])

  return (
    <div>
      <div className="log-form">
        <AvForm onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
          <AvField name="email" placeholder="Enter Your Email Address" value={email} type="email" onChange={emailChange} required validate={{
            required: { value: true, errorMessage: 'Please enter your email address' },
            email: { value: true, errorMessage: 'Invalid Email Format' }
          }} />
          <br/>
          <AvField name="password" placeholder="Enter Your Password" value={password}type="password" onChange={passwordChange} required validate={{
            required: { value: true, errorMessage: 'Please enter your password' }
          }} />
          <Label className="invalid-login-error"> {localStorage.message? "Invalid Email/Password": ''}</Label>
          <br/>
          <Button 
          color="primary"
          disabled={props.auth.isLoading}
          >{
            props.auth.isAuthenticated? '...' : 'Login'
          }</Button>
        </AvForm>
      </div>
    </div>
  )
}
export default connect(state => ({ auth: state.auth }))(LoginForm);