import {
    CALL_API
  } from '../middlewares/apiMiddleware'

export const FETCH_GRAPH_DATA = 'FETCH_GRAPH_DATA';
export const FETCH_GRAPH_DATA_SUCCESS = 'FETCH_GRAPH_DATA_SUCCESS';
export const FETCH_GRAPH_DATA_FAILURE = 'FETCH_GRAPH_DATA_FAILURE';

export const fetchGraphData = (params) => {
    return {
      [CALL_API]: {
        types: [FETCH_GRAPH_DATA, FETCH_GRAPH_DATA_SUCCESS, FETCH_GRAPH_DATA_FAILURE],
        endpoint: `/api/auth/customer/user/analytics?userId=${params.userId}${params.from ? `&from=${params.from}`:``} ${params.to ? `&to=${params.to}`:``}`,
        options: {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
                'Accept': 'application/json'
          }
        }
      }
    }
  }
  