import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { Button, Label, Table, Container, Spinner } from 'reactstrap';
// import JobCard from './JobCard';
import { Helmet } from 'react-helmet';
// import { set } from 'lodash';
import { connect } from 'react-redux';
import usePrevious from '../../customHooks/usePrevious'
import { addLead, fetchLeads, deleteLead } from '../../actions/addLead';
import Loader from '../../components/Loader'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css'
// import { set, values } from 'lodash';

const AddCandidate = ({
    dispatch,
    users,
    message,
    location: {
        pathname
    },
    leads,
    isLoadingLeads,
    history: { push },
    isDeleting,
    deleting
}) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [key, setKey] = useState(1)

    const isDeletingPrev = usePrevious(isDeleting)
    toast.configure()
    const emailChange = e => {
        setEmail(e.target.value);
        setError('')
    }

    useEffect(() => {
        if (localStorage.getItem('userType') === 'ADMIN') {
            push('/html/logs.html')
        }
        dispatch(fetchLeads())
    }, [])

    useEffect(() => {
        if (isDeletingPrev && !isDeleting) {
            dispatch(fetchLeads())
        }
    }, [isDeleting])

    const handleValidSubmit = (e, values) => {
        setEmail(values.email);
        dispatch(addLead(values.email))
    }
    useEffect(() => {
        setEmail('')
        setError('')
    }, [leads])

    const handleInvalidSubmit = (event, values) => {
        setEmail(values.email);
    }

    const handleClickButtonDelete = (leadId) => {
        dispatch(deleteLead(leadId))
    }

    useEffect(() => {
        setError('')
        if (message === "Validation error")
            setError('Already Exists!')
        else if (message === "LEAD ADDED SUCCESSFULLY") {
            setKey(key + 1)
            toast.info('Prospect has been added successfully!', { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 5000 })
            dispatch(fetchLeads())
        }
    }, [message])

    return (
        <div >
            <Helmet>
                <title>DNNae Admin - Add Candidate </title>
            </Helmet>
            <div className="add-candidate-container">
                <Header />
                <div className="add-candidate-container-form">

                    <h1>Add Prospect</h1>

                    <AvForm onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit} key={String(key)} >
                        <AvGroup className="form-fields">
                            <Label for="email">Email</Label>
                            <AvField name="email"
                                placeholder="Enter Email Address"
                                value={email}
                                type="email"
                                onChange={emailChange}
                                required

                                validate={{
                                    required: { value: true, errorMessage: 'Please enter email address' },
                                    email: { value: true, errorMessage: 'Invalid Email Format' }
                                }} />
                        </AvGroup>

                        <Label className="invalid-login-error"> {!isLoadingLeads && error && "Already Exists!"}</Label>
                        <br />
                        <Button color="primary" type="submit">Add</Button>
                    </AvForm>
                </div>

                <Container style={{ marginTop: '40px', maxHeight: '450px', maxWidth: '800px', overflow: 'auto' }}>
                    {leads && leads.length > 0 ?
                        <Table striped size="sm">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Email</td>
                                    <td>Delete</td>
                                </tr>
                            </thead>
                            <tbody>
                                {leads.map((item, i) =>
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{item.email}</td>
                                        <td>
                                            <Button
                                                color="danger"
                                                onClick={() => handleClickButtonDelete(item.id)}
                                                style={{ width: '75px' }}
                                            >
                                                {isDeleting && deleting.includes(item.id) ? <Loader /> : 'Delete'}
                                            </Button></td>
                                    </tr>
                                )}

                            </tbody>
                            {isLoadingLeads &&
                                <div className="spinner">
                                    <Spinner style={{ width: '5rem', height: '5rem' }} color="primary" />
                                </div>
                            }
                        </Table>
                        :
                        !isLoadingLeads &&
                        <p className="spinner" style={{ marginTop: '200px' }}>No Prospects Found</p>
                    }
                </Container>
            </div>
        </div>
    );
};

export default connect(state => ({
    message: state.addLead.message,
    users: state.users.users,
    leads: state.addLead.leads,
    isLoadingLeads: state.addLead.isLoadingLeads,
    isDeleting: state.addLead.isDeleting,
    deleting: state.addLead.deleting

}))(AddCandidate)
